import styled, { keyframes } from "styled-components";

function blinkingEffect() {
  return keyframes`
      50% {
        opacity: 0;
      }
    `;
}

export const BlinkingDot = styled.div`
  height: 15px;
  width: 15px;
  background-color: ${({theme}) => theme?.colors?.danger || 'crimson'};
  border-radius: 50%;
  animation: ${blinkingEffect} 1s linear infinite;
`;
