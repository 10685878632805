import React, { useRef, useEffect } from "react";

const useClickTarget = (ref, targetId = false) => {
  const [clickOutside, setClickOutside] = React.useState(null)
  const [clickInside, setClickInside] = React.useState(null)
  const [event, setEvent] = React.useState(null)
 
  useEffect(() => {
    function handleClick(event) {
      setEvent(event);
      if (ref.current && !ref.current.contains(event.target)) {
        setClickOutside(true)
        setClickInside(false)
        return
      }

      setClickOutside(false)
      setClickInside(true)
    }

    if(targetId && document.getElementById(targetId)){
        document.getElementById(targetId).addEventListener("click", handleClick);
        return () => {
          // Unbind the event listener on clean up
          if(document.getElementById(targetId)){
            document.getElementById(targetId).removeEventListener("click", handleClick);
          }
        }
    }else{
        document.addEventListener("click", handleClick);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("click", handleClick);
        }
    }
  }, [ref])
  return [clickInside, clickOutside, event]
}

export default useClickTarget