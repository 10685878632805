import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { FIREBASE_ERRORS } from "../../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { updatePasswordChange } from "../../redux/actions/users";
import { Button } from "../../shared/ui/Buttons";
import { FlexContainer } from "../../shared/ui/Containers";
import { InputForm } from "../../shared/ui/Inputs";
import Loader from "../../shared/ui/Loader";
import { Modal, ModalBody } from "../../shared/ui/Modals";

const PasswordModal = ({ isOpen, toggle }) => {
  const { updateInviteUserPassword } = useAuth();
  const [values, setValues] = useState({});
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.users);
  let { uid, companyId } = info || {};
  const handleChange = (e) => {
    const updatedValues = { ...values, [e.target.name]: e.target.value };
    if (error?.message) {
      if (error?.type === "password" && updatedValues.password?.length >= 6) {
        setError(null);
      } else if (
        error?.type === "confirmPassword" &&
        updatedValues.confirmPassword?.length >= 6
      ) {
        setError(null);
      } else if (
        error?.type === "generic" &&
        updatedValues.password !== updatedValues.confirmPassword
      ) {
        setError(null);
      }
    }
    setValues(updatedValues);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (values.password?.length < 6) {
      setError({
        message: "Minimum of 6 characters required",
        code: "length",
        type: "password",
      });
    } else if (values.confirmPassword?.length < 6) {
      setError({
        message: "Minimum of 6 characters required",
        code: "length",
        type: "confirmPassword",
      });
    } else if (values.password !== values.confirmPassword) {
      setError({
        message: "Password mismatch",
        code: "mismatch",
        type: "generic",
      });
    } else {
      try{
        await updateInviteUserPassword(values.password);
        dispatch(updatePasswordChange(uid, companyId));
      }catch(error){
        let message = error;
        if (error?.code) {
          message = FIREBASE_ERRORS[error.code];
        }
        toast(message, {
          type: "error",
          hideProgressBar: true,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={"Please update your password"}
      showClose={false}
    >
      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handlePasswordChange}>
            {error?.message && error?.type === "generic" && (
              <Alert color="warning">{error.message}</Alert>
            )}
            <InputForm
              required
              min={6}
              name="password"
              type="password"
              onChange={handleChange}
              placeholder={"Password"}
              value={values?.password}
              error={error?.type === "password" && error?.message}
            />
            <InputForm
              required
              min={6}
              name="confirmPassword"
              type="password"
              value={values?.confirmPassword}
              onChange={handleChange}
              placeholder={"Confirm Password"}
              error={error?.type === "confirmPassword" && error?.message}
            />
            <FlexContainer style={{ justifyContent: "flex-end" }}>
              <Button isLoading={isLoading} type="submit">Update</Button>
            </FlexContainer>
          </form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PasswordModal;
