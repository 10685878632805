import RSelect from "react-select";
import styled from "styled-components";
import { BORDER, COLORS, SPACING, TYPOGRAPHY } from "../constants";
export const InputStyles = {
  size: {
    sm: `${SPACING.XS}px ${SPACING.SM}px`,
    lg: `${SPACING.SM}px ${SPACING.MD}px`,
    default: `${SPACING.SM}px ${SPACING.MD}px`,
  },
};

const Select = styled(RSelect)`
  .Select__dropdown-indicator:hover {
    display: flex;
    flex: 1;
    background-color: ${COLORS.azureRadiance};
    height: 100%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 0px 3px 3px 0px;
  }

  .Select__dropdown-indicator {
    display: flex;
    flex: 1;
    background-color: ${COLORS.eastBay};
    height: 100%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 0px 3px 3px 0px;
  }
`;
export const Input = styled.input`
  box-shadow: none;
  line-height: 0.8;
  padding: ${InputStyles.size.default};
  display: block;
  width: 100%;
  background: ${(props) =>
    props.color && COLORS[props.color] ? COLORS[props.color] : COLORS.white};
  border-radius: ${BORDER.radius_sm}px;
  border: 1px solid ${({theme}) => theme?.text?.color ||  BORDER.color};
  color: ${COLORS.black};
  font-size: ${TYPOGRAPHY.standard}px;
  &:active,
  &:focus {
    outline: none;
    border-color: ${({theme}) => theme?.colors?.active || COLORS.white};
  }
`;

export const Textarea = styled.textarea`
  box-shadow: none;
  line-height: 1.3;
  padding: ${InputStyles.size.default};
  display: block;
  width: 100%;
  border: 1px solid ${({theme}) => theme?.text?.color ||  BORDER.color};
  background: ${(props) =>
    props.color && COLORS[props.color] ? COLORS[props.color] : COLORS.white};
  border-radius: ${BORDER.radius_sm}px;
  border: 1px solid ${({theme}) => theme?.border?.color || BORDER.color};
  color: ${COLORS.black};
  font-size: ${TYPOGRAPHY.standard}px;
  &:active,
  &:focus {
    outline: none;
    border-color: ${COLORS.white};
  }
`;

export const Label = styled.label`
  font-size: ${TYPOGRAPHY.standard}px;
  letter-spacing: 0.8px;
`;

export const InputContainer = styled.div`
  margin-bottom: ${(props) => (props.type == "checkbox" ? 0 : SPACING.MD)}px;
  position: relative;
  label {
    color: ${COLORS.white};
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: ${SPACING.SM + 4}px;
    top: 12px;
    transition: 0.2s ease all;
    color: ${BORDER.color};
  }

  input:focus ~ label,
  .input-focus ~ label {
    top: -10px;
    left: ${SPACING.SM}px;
    padding: 2px 8px;
    color: ${COLORS.white};
    background: ${(props) =>
      props.color && COLORS[props.color]
        ? COLORS[props.color]
        : COLORS.softBlack};
    font-size: ${TYPOGRAPHY.tiny}px;
    font-weight: 600;
    border-radius: ${BORDER.radius_sm}px;
  }
  input[type="checkbox"] {
    appearance: none;
    color: ${({theme}) => theme?.text?.color || COLORS.white};
    width: 16px;
    height: 16px;
    border: 1.5px solid ${({theme}) => theme?.text?.color || COLORS.white};
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    display: grid;
  }
  input[type="checkbox"]::before {
    content: "";
    width: 12px;
    height: 12px;
    clip-path: polygon(8% 62%, 45% 92%, 95% 19%, 84% 10%, 41% 71%, 17% 51%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 0.15s transform ease-in-out;
    box-shadow: inset 1em 1em ${({theme}) => theme?.text?.color || COLORS.white};;
    background-color: CanvasText;
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:disabled {
    --form-control-color: ${COLORS.grey1};
    color: ${COLORS.grey1};\
    cursor: not-allowed;
  }

  // input:not(:placeholder-shown):invalid {
  //   border-color: ${COLORS.red};

  // }
  // input:not(:placeholder-shown) ~ label {

  // }
  // input:not(:placeholder-shown):invalid ~ label {
  //   color: ${COLORS.red};
  // }
`;

export const InputForm = (props) => {
  const {
    value,
    onChange,
    placeholder,
    type,
    name,
    required,
    id,
    onBlur,
    disabled,
    min,
    max,
    pattern,
    title,
    error,
    color,
    style,
    checked,
    floatingLabel,
    onKeyDown,
    onKeyPress,
    onKeyUp,
  } = props;
  return (
    <InputContainer id={id || ""} color={color} style={style} type={type}>
      {type === "textarea" ? (
        <Textarea
          pattern={pattern}
          title={title}
          min={min}
          max={max}
          disabled={disabled}
          type={type}
          onBlur={onBlur}
          name={name}
          value={value}
          required={required}
          onChange={onChange}
          checked={checked}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          className={
            "input-" +
            type +
            " " +
            (value?.length > 0 ? "input-focus" : "input-empty") +
            (required ? " input-required" : "")
          }
          {...props}
        />
      ) : (
        <Input
          pattern={pattern}
          title={title}
          min={min}
          max={max}
          disabled={disabled}
          type={type}
          onBlur={onBlur}
          name={name}
          value={value}
          required={required}
          onChange={onChange}
          checked={checked}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          className={
            "input-" +
            type +
            " " +
            (value?.length > 0 ? "input-focus" : "input-empty") +
            (required ? " input-required" : "")
          }
          {...props}
        />
      )}
      {floatingLabel && <Label htmlFor={name}>{placeholder}</Label>}
      {error && (
        <span style={{ color: COLORS.red }} className="h6">
          {error}
        </span>
      )}
    </InputContainer>
  );
};

InputForm.defaultProps = {
  floatingLabel: false,
};

const customStyles = {
  container: (provided, state) => {
    return {
      display: "flex",
      flexDirection: "column",
      height: 48,
    };
  },
  indicatorsContainer: (provided, state) => {
    console.log("state", state);
    return {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 0,
      margin: 0,
      height: 48,
      width: 87,
      borderRadius: "0px 3px 3px 0px",
      background: state?.isFocused ? COLORS.azureRadiance : COLORS.eastBay,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      color: COLORS.white,
    };
  },
  control: (provided, state) => {
    return {
      position: "relative",
      background: "white",
      borderWidth: 0,
      color: COLORS.azureRadiance,
      padding: "6px 14px",
      boxShadow: "none",
      width: "100%",
      borderRadius: 5,
      height: 48,
      outline: "0px",
    };
  },
  menu: () => {
    return {
      backgroundColor: COLORS.white,
      border: `1px solid ${BORDER.color}`,
      color: COLORS.black,
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      color: COLORS.black,
    };
  },
  option: (provided, state) => {
    console.log("state", state);
    return {
      ...provided,
      color:
        state.isOptionSelected || state.isFocused
          ? COLORS.white
          : COLORS.manatee,
      backgroundColor:
        state.isOptionSelected || state.isFocused
          ? COLORS.azureRadiance
          : COLORS.white,
      cursor: "pointer",
    };
  },
  indicatorSeparator: () => {
    return {};
  },
};

const SelectContainer = styled.div`
  .label-focus {
    top: -10px;
    left: ${SPACING.SM}px;
    padding: 2px 8px;
    color: ${COLORS.white};
    background: ${(props) =>
      props.color && COLORS[props.color] ? COLORS[props.color] : COLORS.black};
    font-size: ${TYPOGRAPHY.tiny}px;
    font-weight: 600;
    border-radius: ${BORDER.radius_sm}px;
  }
  margin-bottom: ${SPACING.MD}px;
`;
export const SelectList = (props) => {
  return (
    <SelectContainer>
      <div className="select-list">
        <Select classNamePrefix={"Select"} {...props} styles={customStyles} />
      </div>
      {/* {props.placeholder &&  <Label >{props.placeholder}</Label>} */}
    </SelectContainer>
  );
};
