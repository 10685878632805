/* global window */
import { createStore, applyMiddleware, compose } from 'redux';
import localforage from 'localforage';
import thunk from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';

import reducers from '../reducers';

const config = {
  key: 'root',
  storage: localforage,
  blacklist: ['status', 'server'],
};

const appReducer = persistCombineReducers(config, reducers);

const reducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    //  localStorage.removeItem("inviteState");
     localStorage.clear();
     return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const middleware = [thunk];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export let globalStore = null;

const configureStore = () => {
  const store = createStore(
    reducer,
    composeEnhancer(applyMiddleware(...middleware)),
  );

  const persistor = persistStore(
    store,
    null,
    () => { store.getState(); },
  );

  globalStore = store;

  return { persistor, store };
};

export default configureStore;
