import axios from 'axios';
import { auth } from '../firebase/firebase';
import { globalStore } from '../redux/store';

axios.interceptors.request.use(async request => {
    if(request.url.includes("sfdc")) {
        return request;
    }
    if(request.url.includes("http")) {
        const idToken = await auth?.currentUser?.getIdToken();
        if(idToken) {
            request.headers['authorization'] = `Bearer ${idToken}`;
        }
    }
    return request;
}, error => {
    console.error(error);
});

axios.interceptors.response.use(undefined, function axiosRetryInterceptor (error) {
    if(error?.response?.status === 401) {
        globalStore.dispatch({type: 'USER_TOKEN_ERROR', data: error});
        throw "ERROR USER UNAUTHORIZED"
    }
    throw error
});