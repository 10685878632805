import { UncontrolledTooltip, Tooltip as ControlTooltip } from "reactstrap";
import styled from "styled-components";
import { COLORS } from "../constants";

const backgroundColor = COLORS.azureRadiance;

const directions = {
  top: {
    direction: '',
    position: "right",
    before: "calc(50% - 8px)",
    after: "calc(50% - 5px)",
    bgColor: `${backgroundColor} transparent transparent transparent`,
  },
  bottom: {
    position: "right",
    before: "calc(50% - 8px)",
    after: "calc(50% - 4px)",
    bgColor: `transparent transparent ${backgroundColor} transparent`,
  },
  left: {
    position: "top",
    before: "calc(50% - 8px)",
    after: "calc(50% - 4px)",
    bgColor: `transparent transparent transparent ${backgroundColor}`,
  },
  right: {
    position: "top",
    before: "calc(50% - 8px)",
    after: "calc(50% - 4px)",
    bgColor: `transparent ${backgroundColor} transparent transparent`,
  },
};

export const ControlledTooltip = styled(ControlTooltip)`
// opacity: 1 !important;
pointer-events: none;

.tooltip-inner {
  display: inline-block;
  position: relative;
  text-align: left;
  color: ${COLORS.white};
  background-color: ${backgroundColor};
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
}

.arrow {
  display: none !important;
}

.tooltip-inner::before,
.tooltip-inner::after {
  content: "";
  display: block;
  position: absolute;
  ${(props) =>
    props.placement && directions[props.placement]
      ? props.placement
      : 'bottom'}: 100%;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip-inner::after {
  ${(props) =>
    props.placement && directions[props.placement]
      ? directions[props.placement].position
      : directions["bottom"].position}: ${(props) =>
    props.placement && directions[props.placement]
      ? directions[props.placement].after
      : directions["bottom"].after};
  border-color: ${(props) =>
    props.placement && directions[props.placement]
      ? directions[props.placement].bgColor
      : directions["bottom"].bgColor};
  border-width: 5px;
}

.tooltip-inner::before {
  ${(props) =>
    props.placement && directions[props.placement]
      ? directions[props.placement].position
      : directions["bottom"].position}: ${(props) =>
    props.placement && directions[props.placement]
      ? directions[props.placement].before
      : directions["bottom"].before};
  border-color: transparent;
  border-width: 8px;
}
`;

const ToolTip = styled(UncontrolledTooltip)`
  // opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    position: relative;
    text-align: left;
    color: ${COLORS.white};
    background-color: ${backgroundColor};
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
  }

  .arrow {
    display: none !important;
  }

  .tooltip-inner::before,
  .tooltip-inner::after {
    content: "";
    display: block;
    position: absolute;
    ${(props) =>
      props.placement && directions[props.placement]
        ? props.placement
        : 'bottom'}: 100%;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .tooltip-inner::after {
    ${(props) =>
      props.placement && directions[props.placement]
        ? directions[props.placement].position
        : directions["bottom"].position}: ${(props) =>
      props.placement && directions[props.placement]
        ? directions[props.placement].after
        : directions["bottom"].after};
    border-color: ${(props) =>
      props.placement && directions[props.placement]
        ? directions[props.placement].bgColor
        : directions["bottom"].bgColor};
    border-width: 5px;
  }

  .tooltip-inner::before {
    ${(props) =>
      props.placement && directions[props.placement]
        ? directions[props.placement].position
        : directions["bottom"].position}: ${(props) =>
      props.placement && directions[props.placement]
        ? directions[props.placement].before
        : directions["bottom"].before};
    border-color: transparent;
    border-width: 8px;
  }
`;

export const ToolTipItem = ({
  children,
  label,
  direction = "bottom",
  itemId,
  showToolTip = true,
  style
}) => {
  if (!itemId) {
    console.error("ToolTipItem: Missing itemId prop");
    return <div></div>;
  }
  return (
    <>
      <div style={style} id={itemId}>{children}</div>
      {showToolTip && <ToolTip placement={direction} target={itemId}>
        {label}
      </ToolTip>}
    </>
  );
};
