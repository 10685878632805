import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";

import {
  ONBOARDING_STEP,
  SPACING,
  COLORS,
  ERROR_CODES,
} from "../../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { getAvailableMeetings } from "../../redux/actions/meetings";
import OnboardingModal from "../onboarding/OnboardingModal";
import MeetingList from "../call/MeetingList";
import { Button } from "shared/ui/Buttons";
import { Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { updateUserLoginCount } from "../../redux/actions/users";
import {} from "shared/constants";
import media from "shared/utils/media";
import { ToolTipItem } from "shared/ui/ToolTips";
import ScheduledMeetings from "../call/ScheduledMeetings";
import RecentWins from "./RecentWins";
import Saved from "./Saved";
import History from "./History";
import { Alert } from "reactstrap";
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${media.lessThan("tablet")`
    padding-top:8px;
  `}
`;

const IconButton = styled.div`
  height: 10vw;
  width: 10vw;
  cursor: pointer;
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
  border-radius: 7.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme?.colors?.secondary};
  &.disabled {
    opacity: 0.3;
    cursor: default;
  }

  color: ${({ theme }) => theme?.text?.icon || COLORS.white};
  h5 {
    color: ${({ theme }) => theme?.text?.icon || COLORS.white};
  }
  i {
    cursor: pointer;
    font-size: 30px;
    height: 34px;
    color: ${({ theme }) => theme?.text?.icon || COLORS.white};
    ${media.lessThan("tablet")`
      font-size: 26px ;
      height: 30px;
    `}
  }
  &.library {
    i {
      font-size: 40px;
      height: 34px;
      ${media.lessThan("tablet")`
        font-size: 34px;
      `}
    }
  }
  &:hover , &.active {
    background-color: ${({ theme }) => theme?.button?.hover?.primary};
  }
`;

const Image = styled.img`
  object-fit: contain;
  // height:35%;
  width: 35%;
  margin-bottom: 1vw;
`;

const Text = styled.h5`
  word-wrap: break-word;
  font-weight: bold;
  font-size: 12px;
  ${media.lessThan("tablet")`
    display: none;
  `}
`;

const ScheduledPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  padding: 30px 30px 0;
  height: 100vh;
  border-left: 1px solid ${({ theme }) => theme.border.color};
`;

const Dashboard = ({ logoutCallback, page }) => {
  const { logout, currentUser, getCustomToken } = useAuth();
  const { info, isLoggedIn } = useSelector((state) => state.users);
  const { claims } = useSelector((state) => state.member);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { zoom } = useSelector((state) => state.content);
  const callReasons = zoom?.content?.Triggers || {};
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const companyId = claims?.companyId;
  const uid = info?.uid;
  const shouldAuthorizeZoom = false;
  const {Notifications, notificationRead} = info || {};

  const collectMeetingInfo = async () => {
    const { interactionId } = await getAvailableMeetings();
    if (interactionId) {
      navigate(`/meeting/live/${interactionId}`);
    }
  };

  const handleMeetingSelected = (interactionId, meeting, type = false) => {
    if (meeting?.status === "started" || type === "live") {
      navigate(`/meeting/live/${interactionId}`);
    } else {
      navigate(`/meeting/${interactionId}`);
    }
  };

  const getPageLayout = () => {
    switch (page) {
      case "recent-wins":
        return <RecentWins />;
      case "saved":
        return <Saved />;
      case "history":
        return <History />;
      default:
        return <MeetingList isLive onMeetingSelected={handleMeetingSelected} />;
    }
  };

  useEffect(() => {
    if (info && Object.keys(info)?.length ) {
      if (
        info?.onboarding?.zoom?.onBoardStep &&
        info?.onboarding?.zoom?.onBoardStep !== ONBOARDING_STEP.COMPLETE &&
        info?.onboarding?.zoom?.onBoardStep !== ONBOARDING_STEP.INVITE_TEAM
      ) {
        setShowOnboarding(true);
      } else if (!info?.onboarding?.zoom?.onBoardStep) {
        setShowOnboarding(true);
      } else {
        setShowOnboarding(false);
      }
    }
  }, [info.onboarding]);

  useEffect(() => {
    
    if(Notifications && Object.keys(Notifications)?.length) {
      for (const key of Object.keys(Notifications)) {
        if(Notifications[key].type === "meeting_hours_over" && !notificationRead?.[Notifications[key].type]) {
          toast(ERROR_CODES.TRIAL_RECORD_LIMIT.message, {
            type: "error",
            hideProgressBar: true
          });
          dispatch({
            type: "USER_NOTIFICATION_READ",
            id: Notifications[key].type,
          });
      
          break;
        }
      }
    }
  }, [Notifications])



  useEffect(() => {
    if (companyId && uid && !isLoggedIn) {
      updateUserLoginCount(companyId, uid);
      dispatch({
        type: "USER_LOGIN_STATUS",
        data: true,
      });
    }
  }, [companyId, uid, isLoggedIn]);

  const handleLogout = async () => {
    await logout();
    logoutCallback();
    dispatch({ type: "USER_LOGOUT" });
  };

  const handleOpenApp = async (event, customReturn = "call") => {
    const token = await getCustomToken(currentUser.uid);
    window.open(
      `https://0.0.0.0:3001/loading?customToken=${token}&returnUrl=${customReturn}`
    ); // TODO update url in prod
  };

  useEffect(() => {
    dispatch({
      type: "DISPLAY_CONTENT",
      data: { header: `Hi ${info?.firstName} ${info?.lastName} ` },
    });
  }, [info]);

  let array = [
    {
      icon: ICON_TYPES.plus,
      text: "Prepare",
      link: "/prepare",
      id: 'prepare'
    },
    {
      icon: ICON_TYPES.library,
      iconStyles: {
        transform: "rotate(270deg) scaleX(-1)",
        "--fa-primary-color": "#fff",
        "--fa-secondary-color": COLORS.primary,
        "--fa-secondary-opacity": 0,
      },
      text: "Saved",
      link: "/saved",
      id: 'saved'

    },

    {
      icon: ICON_TYPES.fire,
      text: "Recent Wins",
      link: "/recent-wins",
      id: 'recent-wins'

    },
    {
      text: "History",
      link: "/history",
      icon: ICON_TYPES.recent,
      id: 'history'

    },
  ];

  if (callReasons) {
    array = array.map((reason, index) => ({
      ...reason,
      key: index === 0 ? "add" : Object.keys(callReasons)[index - 1],
    }));
  }

  const handleContentUpdate = async () => {
    const customToken = await getCustomToken(null);
    let pathname = "https://test.upmarket.ai";
    if (window.location.hostname?.includes("localhost")) {
      pathname = "https://localhost:3001";
    }
    window.open(
      `${pathname}/loading?customToken=${customToken}&returnUrl=content?quickAdd=true`
    );
  };
  return (
    <Container>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 450px" }}>
        <div style={{ padding: 30 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              marginBottom: SPACING.MD,
            }}
          >
            {array.map((item, index) => (
              <div key={index} style={{ marginRight: SPACING.MD }}>
                <ToolTipItem
                  label={item?.isDisabled ? "Coming soon" : item.text}
                  direction="bottom"
                  itemId={"dashboard_" + item.icon}
                  showToolTip={true}
                >
                  <IconButton
                    onClick={() => {
                      !item?.isDisabled && navigate(`${item.link}`);
                    }}
                    type={item.icon}
                    className={
                      item.icon + (item?.isDisabled ? " disabled" : "") +
                      (item.id === page ? " active" : "")
                    }
                  >
                    <Icon type={item.icon} style={{ ...item?.iconStyles }} />
                    <Text>{item.text}</Text>
                  </IconButton>
                </ToolTipItem>
              </div>
            ))}
          </div>
          {getPageLayout()}
        </div>
        <ScheduledPanel>
          <ScheduledMeetings />
        </ScheduledPanel>
      </div>
      {/* <Button
        style={{ marginLeft: 8, width: 'calc(100% - 8px)', maxWidth: 750, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={() => {
          navigate("/conversation/types");
        }}
      >
        <Icon
          style={{ marginRight: 14, fontSize: 26 }}
          type={ICON_TYPES.playbook}
        />
        <h3 style={{ margin: 0, fontWeight: 600 }}>{CONTENT_TYPE[TYPE_LIST.CALLFLOWS].controlLabelPlural}</h3>
      </Button> */}
      <br />
      {showOnboarding && (
        <OnboardingModal
          isOpen={true}
          toggle={() => setShowOnboarding(!showOnboarding)}
        />
      )}
    </Container>
  );
};

export default Dashboard;
