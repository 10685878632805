import React, { useEffect, useState } from "react";
import { ListItem, List } from "./library";
import { Input } from "reactstrap";
import { Icon } from "./Icon";
import PropTypes from "prop-types";
import ClickDetector from "./ClickDetector";


const SearchDropDown = (props) => {
  let {
    defaultValue,
    selectedValue,
    className,
    size,
    style,
    hideIcon,
    targetId,
    title,
    disabled,
    type,
    active,
    list,
    onSelect,
    inputStyle
  } = props;

  let selectedVal =
    selectedValue && selectedValue.length > 22
      ? selectedValue.slice(0, 18) + "..."
      : selectedValue;
  let defaultVal =
    defaultValue && defaultValue.length > 22
      ? defaultValue.slice(0, 18) + "..."
      : defaultValue;
  let isSearch = true;

  const [search, setSearch] = useState(selectedValue ? selectedVal : "");
  const [filterList, setFilterList] = useState(list || []);

  const [isOpen, setToggle] = useState(false);
  const handleEditorClick = (clickInside, clickOutside, clickEvent) => {
    if (clickOutside && !clickInside) {
      if (isOpen) {
        if (
          !clickEvent.target.classList.value.includes(
            "list-container_items_item"
          )
        ) {
          if (!disabled) {
            setToggle(!isOpen);
          }
        }
      }
    } else {
      if (clickInside) {
        if (!disabled) {
          if (isSearch) {
            if (!isOpen) {
              setToggle(!isOpen);
            } else {
              if (!clickEvent.target.classList.value.includes("form-control")) {
                setToggle(!isOpen);
              }
            }
          } else {
            setToggle(!isOpen);
          }
        }
      }
    }
  };

  const onHandleChange = (e) => {
    const { value, name } = e.target;
    if (value) {
      let listTemp = list.filter((l) =>
        l?.label?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilterList(listTemp);
      setSearch(value);
    }else{
      setSearch(value);
      setFilterList(list);
    }
  };

  const onListSelect = (item) => {
    onSelect(item);
    setSearch(item.label);
    setToggle();
  };

  useEffect(() => {
    setFilterList(list);
  }, [list]);

  useEffect(() => {
    setSearch(selectedValue || "");
  }, [selectedValue]);

  return (
    <div className="dropdown-container" style={style}>
      {/* Drop Down Title  */}
      {title && (
        <p className="body-small bold" style={{ marginBottom: 8 }}>
          {title}
        </p>
      )}
      <div
        className={
          (className || "") +
          (disabled ? "dropdown-disabled " : "") +
          " dropdown-default dropdown-" +
          (size || "") +
          (isOpen ? " dropdown-open" : "") +
          (isSearch ? " dropdown-search" : "")
        }
      >
        <ClickDetector handleClick={handleEditorClick} targetId={targetId}>
          <div className="dropdown-btn">
            {/* isSearch (Bool) to enable search in drop down  */}
            <div
              className={"dropdown " + (active ? "dropdown-selected " : "")}
              style={{ cursor: disabled ? "default" : "pointer" }}
            >
              <Input
                autoFocus={isOpen ? true : false}
                style={inputStyle || { border: 0 }}
                value={search?.label || search || ""} // fix to search.label - tmq
                placeholder={defaultVal}
                disabled={disabled}
                name={"search"}
                onChange={onHandleChange}
                autoComplete="off"
                type={type}
              />
            </div>
            {/* Caret Icon  */}
            {hideIcon ? null : <Icon type={"dropdownDown"} />}
          </div>
        </ClickDetector>
        {isOpen && (
          <div className={"dropdown-content"}>
            {/* For a list of items, use components List, ListItem -> <List> <ListItem onMouseDown> </ListItem> </List> */}
            <List>
              {filterList.length > 0 &&
                filterList
                .sort((a,b) => (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0 )
                .map((item, i) => {
                  return (
                    <ListItem
                      active={item?.label  == selectedValue || selectedValue?.label == item?.label }
                      key={i + "_" + item?.label}
                      onMouseDown={() => onListSelect(item)}
                    >
                      {item?.label}
                    </ListItem>
                  );
                })}
            </List>
          </div>
        )}
      </div>
    </div>
  );
};

// Dropdown.propTypes = {
//     toggle: PropTypes.func.isRequired, // Toggle function
//     isOpen: PropTypes.bool.isRequired, // Bool value to show dropdown list
//     defaultValue: PropTypes.string.isRequired,
//     selectedValue: PropTypes.string.isRequired,

//     //Input Search feature
//     isSearch: PropTypes.bool, // Enable input search feature
//     handleChange: PropTypes.func, // If isSearch = true, handleChange is required
//     name: PropTypes.string, // set name in input
//     value: PropTypes.string, // set value in input

//     //if drop down is in modal, add the modal id to targetId. The reason for this is to set the click detect container
//     // EX: <Modal id='modal-id'/> -> targetId = 'modal-id
//     targetId: PropTypes.string,

//     //Optional
//     title: PropTypes.string, // Add Drop down Title
//     size: PropTypes.string, //types: sm , lg
//     hideIcon: PropTypes.bool, // hide caret icon

// };

// Dropdown.defaultProps = {
//     isOpen: false,
//     style: {}
// }

export default SearchDropDown;
