import { useNavigate } from "react-router-dom";
import MeetingList from "../call/MeetingList";
import styled from "styled-components";
import media from "shared/utils/media";

import HorizontalAnimationWrapper from "shared/ui/HorizontalAnimationWrapper";
import { useEffect } from "react";
import { getUserMeetings } from "../../redux/actions/meetings";
import { useDispatch, useSelector } from "react-redux";

const Container = styled.div`
  width: 100%;
  height:100%;
  ${media.lessThan('tablet')`
    padding-top:8px;
  `}

`

const History = ({ }) => {
  const { info, isLoggedIn } = useSelector((state) => state.users);
  const { claims } = useSelector((state) => state.member);
  const dispatch = useDispatch()
  const companyId = claims?.companyId;
  const uid = info?.uid;
  let navigate = useNavigate();
  const handleMeetingSelected = (interactionId, meeting) => {
    if (meeting?.status === "started") {
      navigate(`/meeting/live/${interactionId}`);
    } else {
      navigate(`/meeting/${interactionId}`);
    }
  };

  useEffect(() => {
    dispatch(getUserMeetings(uid, companyId))
  }, [])

  return (
    <HorizontalAnimationWrapper>
      <Container>
        <MeetingList onMeetingSelected={handleMeetingSelected} />
      </Container>
    </HorizontalAnimationWrapper>
  );
};

export default History;
