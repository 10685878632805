import React, { useState } from "react";
import styled from "styled-components";
import { Table } from "reactstrap";

import { Modal, ModalBody, ModalFooter } from "../../shared/ui/Modals";
import { COLORS, COLOR_ARR, SPACING, TYPOGRAPHY } from "shared/constants";
import ChartRS from "react-apexcharts";
import { Button } from "shared/ui/Buttons";
import useWindowDimensions from "shared/utils/hooks";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
// import { IndicatorSeparator } from "react-select/dist/declarations/src/components/indicators";

const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const TabIndicator = styled.div`
  flex: 1;
  display: flex;
  background-color: ${(props) =>
    props.active ? COLORS.azureRadiance : "transparent"};
`;

const TH = styled.th`
  border-color: ${COLORS.black};
  color: ${COLORS.black};
  border-width: 1px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const TD = styled.td`
  border-color: ${COLORS.black};
  border-width: 0.5px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const TR = styled.tr`
 &:hover{
  background-color: ${({theme}) => theme.colors.active};
 }
`
const TabBody = styled.div`
  display: flex;
  flex: 1;
  height: 500px;
  // justify-content: center;
  // align-items: center;
  overflow: scroll;
`;

const ChartBody = styled.div`
  display: flex;
  flex: 1;
  height: 500px;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`;

const Chart = styled(ChartRS)`
  opacity: ${(props) => (props.isLoading ? 0.3 : 1)};
  .apexcharts-menu {
    background: ${COLORS.greyDark};
  }
  .apexcharts-menu-item {
    &:hover {
      background: ${COLORS.grey1};
    }
  }
  .apexcharts-theme-light .apexcharts-menu-item:hover {
    &:hover {
      background: ${COLORS.borderColor};
    }
  }
  .apexcharts-text.apexcharts-xaxis-label {
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }
  .apexcharts-text.apexcharts-yaxis-label {
    // font-size: ${TYPOGRAPHY.small}px;
  }
  .apexcharts-legend-text {
    color: ${COLORS.white} !important;
    fill: ${COLORS.white} !important;
  }
`;

function TableChartModal(props) {
  const { toggle, isOpen, data } = props;
  const [activeTab, setActiveTab] = useState("Table");
  const dimensions = useWindowDimensions();
  const { theme } = useAuth();

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const navigate = useNavigate()
  const formatDuration = (duration) => {
    function pad(number) {
      return `${number}`.slice(-2);
    }
    
    let hours = duration / 3600 | 0;
    let minutes = duration % 3600 / 60 | 0;
    let seconds = duration % 60;
  
    // let hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    // let minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    // let seconds = Math.floor((duration % (1000 * 60)) / 1000);
    
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds ;
    let minsSecs = `${pad(minutes)}:${pad(seconds)}`;
  
    return hours > 0 ? `${hours}:${minsSecs}` : minsSecs;
  }
  const renderTable = () => {
    return (
      <TabBody style={{ padding: 20, flexDirection: "column" }}>
        <thead
          style={{
            backgroundColor: COLORS.primary,
            width: "100%",
          }}
        >
          <tr style={{ display: "flex" }}>
            <TH>Date</TH>
            <TH>Outcome</TH>
          </tr>
        </thead>
        <tbody
          style={{ outlineColor: COLORS.black, width: "100%", height: 25 }}
        >
          {data?.interactions?.map((interaction) => {
            return (
              <TR style={{ outlineColor: COLORS.black, display: "flex", cursor: 'pointer' }} onClick={() => navigate(`/meeting/${interaction.id}`)}>
                <TD>{moment(new Date(interaction.dateCompleted)).format("M/D/yyyy")}</TD>
                <TD>{interaction?.dispositionLabel || "N/A"}</TD>
              </TR>
            );
          })}
        </tbody>

        {/* </Table> */}
      </TabBody>
    );
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} showClose={false} size="md">
      <div
        style={{
          width: "100%",
          height: 50,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", flex: 1, width: "100%" }}>
          {/* <Tab
            activeTab={activeTab}
            onClick={() => {
              changeTab("Chart");
            }}
            tab="Chart"
            style={{ borderRightWidth: 0.5 }}
          >
            <h4 style={{ paddingTop: 10 }}>Chart</h4>
          </Tab> */}
          <Tab
            activeTab={activeTab}
            onClick={() => {
              changeTab("Table");
            }}
            tab="Table"
          >
            <h4 style={{ paddingTop: 10 }}>History</h4>
          </Tab>
        </div>
        <div
          style={{
            width: "100%",
            height: 5,
            display: "flex",
            // backgroundColor: "red",
          }}
        >
          <TabIndicator active={activeTab === "Chart"} />
          <TabIndicator active={activeTab === "Table"} />
        </div>
      </div>
      {data ? (
        <>
          {activeTab === "Chart" && (
            <ChartBody>
              <Chart
              style={{marginLeft:data?.interactions?.length>20?data?.interactions?.length*13:0}}
                isLoading={false}
                theme={{ mode: "dark" }}
                options={{
                  chart: {
                    type: "bar",
                    height: 100,
                  },
                  tooltip: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 4,
                      horizontal: false,
                      distributed: true,
                      barHeight: 80,
                    },
                  },
                  colors: [COLORS.primary],
                  grid: {
                    show: false,
                  },
                  yaxis: {
                    show: true,
                    axisBorder: {
                      show: false,
                    },
                    crosshairs: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                    labels: {
                      show: true,
                      align: "left",
                      // minWidth: 160,
                      // maxWidth: 160,
                      style: {
                        colors: [theme?.text?.color || "#F9F9F9"],
                        fontSize: "12px",
                      },
                      // offsetX: -45,
                    },
                    categories: data?.interactions?.map((interaction) =>
                      formatDuration(Math.round(interaction.duration)))
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                  xaxis: {
                    labels: {
                      show: true,
                      style: {
                        colors: [theme?.text?.color || "#F9F9F9"],
                        fontSize: "12px",
                      },
                    },
                    categories: data?.interactions?.map((interaction) =>
                      moment(new Date(interaction.dateCompleted)).format("M/D")
                    ), //date
                  },
                }}
                width={data?.interactions?.length<20?450:data?.interactions?.length *20}
                height={dimensions.height / 2}
                series={[
                  {
                    name: "Series 1",
                    data: data?.interactions?.map((interaction) =>
                      interaction.duration
                    )
                  },
                ]}
                type={"bar"}
              />
            </ChartBody>
          )}
          {activeTab === "Table" && renderTable()}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            height:200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>No data</h3>
        </div>
      )}

      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={toggle}
          style={{ marginRight: 40, marginBottom: 40, alignSelf: "flex-end" }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default TableChartModal;
