import React, { useEffect, useState } from "react";
import { InputForm } from "shared/ui/Inputs";
import { Button, AuthButton } from "shared/ui/Buttons";
import styled from "styled-components";
import cubicles from "assets/cubicles.png";
import { SPACING } from "shared/constants";
import logo from "assets/upmarket.png";
import { useNavigate } from "react-router-dom";
import { findUserCompany, resetPassword } from "../../redux/actions/member"
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Cubicles = styled.img`
  z-index: 0;
  position: absolute;
  height: 80%;
  // aspect-ratio:2/3;
  bottom: 0;
  right: 0;
  resize-ode: contain;
`;

const ForgotPassword =  ({}) => {
  const [email, setEmail] = useState("");
  let navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    try{
      findUserCompany(email).then(async(result)=>{
        console.log(result)
        if(result?.companyId && result?.isUser){
          await resetPassword(email, result.companyId);
          toast("Email sent", {
            hideProgressBar: true,
          });
          navigate("/")
        }else{
          toast("User not found", {
            hideProgressBar: true,
          });
        }
      })
    }catch (e) {
      toast("User not found", {
        hideProgressBar: true,
      });
    }
  }

  return (
    <Container>
      <Cubicles src={cubicles} alt="Upmarket cubicle" />
      <form onSubmit={onSubmit}>
        <img
          onClick={() => navigate("/")}
          style={{
            width: "200px",
            margin: `0 auto ${SPACING.MD}px`,
            display: "block",
            cursor: 'pointer'
          }}
          src={logo}
          alt="Upmarket Logo"
        />
        <h3 style={{ textAlign: "center" }}>Reset Password</h3>
        <p style={{ textAlign: "center" }}>
          An email will be sent if it exists in Upmarket
        </p>
        <InputForm
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <AuthButton
          color="primary"
          type="submit"
          style={{ height: "50px", width: "355px" }}
        >
          Send
        </AuthButton>
      </form>
    </Container>
  );
};

export default ForgotPassword;
