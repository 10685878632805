import React from 'react';

import {Input} from 'reactstrap'
import PropTypes from 'prop-types';
import ClickDetector from './ClickDetector';
import { Icon } from './Icon';


export const Dropdown = (props) => {
    let {
        toggle,
        isOpen,
        defaultValue,
        selectedValue,
        className,
        size,
        style,
        hideIcon,
        isSearch,
        handleChange,
        name,
        value,
        targetId,
        title,
        disabled,
        type,
        active
    } = props;
    let selectedVal = selectedValue && selectedValue.length > 25 ? selectedValue.slice(0,22) +'...': selectedValue;
    let defaultVal = defaultValue && defaultValue.length > 25 ? defaultValue.slice(0,22) + '...': defaultValue;

    const handleEditorClick = (clickInside, clickOutside, clickEvent) => {
        if(clickOutside && !clickInside){
            if(isOpen){
                if(!clickEvent.target.classList.value.includes('list-container_items_item')){
                    if(!disabled){
                        toggle()
                    }
                }
            }
        }else{
            if(clickInside){
                if(!disabled ){
                    if(isSearch  ){
                      if(!isOpen){
                        toggle();
                      }else{
                        if(!clickEvent.target.classList.value.includes('form-control')){
                          toggle();
                        }
                      }
                    }else{
                      toggle();

                    }
                }
            }
        }
    }
    return(
        <div className="dropdown-container" style={style}>
            {/* Drop Down Title  */}
            {title && <p className ='body-small bold' style={{marginBottom: 8}}>{title}</p>}
            <div className={(className || '') + (disabled ? 'dropdown-disabled ' : '') + ' dropdown-default dropdown-'+(size || '') + (isOpen ? ' dropdown-open' : '') + (isSearch ? ' dropdown-search' : '')} >
                <ClickDetector handleClick={handleEditorClick} targetId={targetId}>
                    <div className="dropdown-btn" >
                        {/* isSearch (Bool) to enable search in drop down  */}
                        <div className={"dropdown " + (active ? 'dropdown-selected ' : '')}>
                            {isSearch ?
                                <div >
                                    <Input 
                                        list={props.list || ''} 
                                        autoFocus={isOpen ? true : false} 
                                        style={{border: 0}} 
                                        value={value || ""} 
                                        placeholder={defaultVal} 
                                        disabled={disabled}
                                        name={name} 
                                        autoComplete="off"
                                        onChange={handleChange}
                                        type={type}/>
                                </div>
                            : selectedVal ? selectedVal :  defaultVal}
                        </div>
                        {/* Caret Icon  */}
                        {hideIcon ? null :  <Icon type={'dropdownDown'}/> }
                    </div>
                </ClickDetector>
                {isOpen && 
                    <div className={'dropdown-content'} >
                        {/* For a list of items, use components List, ListItem -> <List> <ListItem onMouseDown> </ListItem> </List> */}
                        {props.children}
                    </div>
                }
            </div>
        </div>
    )
}


// Dropdown.propTypes = {
//     toggle: PropTypes.func.isRequired, // Toggle function
//     isOpen: PropTypes.bool.isRequired, // Bool value to show dropdown list
//     defaultValue: PropTypes.string.isRequired, 
//     selectedValue: PropTypes.string.isRequired,

//     //Input Search feature
//     isSearch: PropTypes.bool, // Enable input search feature
//     handleChange: PropTypes.func, // If isSearch = true, handleChange is required
//     name: PropTypes.string, // set name in input
//     value: PropTypes.string, // set value in input

//     //if drop down is in modal, add the modal id to targetId. The reason for this is to set the click detect container
//     // EX: <Modal id='modal-id'/> -> targetId = 'modal-id
//     targetId: PropTypes.string,

//     //Optional
//     title: PropTypes.string, // Add Drop down Title
//     size: PropTypes.string, //types: sm , lg
//     hideIcon: PropTypes.bool, // hide caret icon

// };



// Dropdown.defaultProps = {
//     isOpen: false,
//     style: {}
// }
