const { default: styled } = require("styled-components");


export const TinyText = styled.span`
  font-size: 14px;
  line-height: 20px;
  ${({theme, link, color}) => `
    color: ${color || theme.colors.grey1};
    ${link ? `
      cursor: pointer;
      &:hover{
        opacity: .7;
      }
    `:``}
  `};

`