import axios from "axios";
import { BASE_URL, ENV, RUNNING_ENV } from "../../shared/constants";
import { isDevRunningEnvironment } from "../../shared/utils/common";

export async function collectAppAccessData(code, appName) {
    let response;
  
    let dev = isDevRunningEnvironment();
    let test = RUNNING_ENV === ENV.TEST;
    let data = {
      code,
      dev,
      test
    }

    switch (appName) {
      case "zoom":
        response =  await axios.post(`${BASE_URL}/get-access-token`, data);
        break;
      default:
        break;
    }
  
    return response.data;
  }

  export const getConnection = async() => {
    const dev = isDevRunningEnvironment();
    let test = RUNNING_ENV === ENV.TEST;
    const {data} = await axios.post(`${BASE_URL}/get-connection`, {dev, test});
    return data;
  }