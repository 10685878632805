import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Label,
  Row,
} from "reactstrap";
import styled from "styled-components";

import {
  ONBOARDING_QUESTIONS,
  ONBOARDING_INPUT_TYPES,
  ONBOARDING_STEP,
  COLOR_ARR,
  COLORS
} from "shared/constants";
import { updateOnboarding } from "../../redux/actions/users";
import TeamInviteForm from "../team/TeamInviteForm";
import { InputForm } from "../../shared/ui/Inputs";
import { BORDER, SPACING, TYPES_WITH_TOOLBAR, TYPE_LIST, TYPOGRAPHY } from "../../shared/constants";

import { Modal, ModalBody, ModalHeader } from "../../shared/ui/Modals";
import {OptionButton} from 'shared/ui'
import { Icon, ICON_TYPES } from "shared/ui/Icon";


const Range = styled.input`
  -webkit-appearance: none;
  width: 30vw;
  margin: 0;
  height:7px;
  border-radius:5px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
 
    height: ${20}px;
    width: ${20}px;
    background: ${COLORS.azureRadiance};
    border-radius:50px;
    outline-width:2px;
    outline-color:${COLORS.white};
    outline-style: solid;
  }
`

const Header = styled.div`
  padding: 20px;
  display: flex;
  flexDirection:row;
  justify-content:space-between;
`;


const OnboardingModal = ({ isOpen, toggle }) => {
  const { info } = useSelector((state) => state.users);
  const [rangeValue, setRangeValue] = useState(0);
  const dispatch = useDispatch();

  let { firstName, lastName, uid, companyId } = info || {};

  let { onBoardStep, onBoardStepNo } = info?.onboarding?.zoom || {};

  const onBoardStepGroup = onBoardStep || ONBOARDING_STEP.GENERAL_INFO;
  const onBoardStepNumber = onBoardStepNo || Object.keys(ONBOARDING_QUESTIONS[onBoardStepGroup])[0];

  const totalQuestions = Object.keys(ONBOARDING_QUESTIONS[onBoardStepGroup])

  const question = ONBOARDING_QUESTIONS[onBoardStepGroup][onBoardStepNumber];

  const questions = []
  Object.keys(ONBOARDING_QUESTIONS).forEach((onboardingQuestionGroupKey) => {
    Object.keys(ONBOARDING_QUESTIONS[onboardingQuestionGroupKey]).forEach((onBoardingStepKey) => {
      questions.push(ONBOARDING_QUESTIONS[onboardingQuestionGroupKey][onBoardingStepKey])
    })
  })

  const questionIndex = questions.findIndex(step => step.label === question.label)

  const onAnswer = (val) => {

    dispatch(
      updateOnboarding({
        answer: val,
        onBoardStep: onBoardStepGroup,
        onBoardStepNo: onBoardStepNumber,
        next: question.next,
        uid,
        companyId,
      })
    );
  };

  useEffect(() => {
    setRangeValue(0);
    if (onBoardStepGroup === ONBOARDING_STEP.INVITE_TEAM) {
      toggle();
    }
  }, [onBoardStepGroup, onBoardStepNumber]);


  const handleCloseClick = () => {
    toggle();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <Header>
        <div>{questionIndex + 1} / {questions.length}</div>
        <Icon type={ICON_TYPES.close}
          style={{ fontSize: TYPOGRAPHY.h3, cursor: 'pointer' }}
          onClick={handleCloseClick} />
      </Header>

      <ModalBody style={{ minHeight: "40vh", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {questionIndex === 0 && (<h3>
          Welcome {firstName} {lastName}!
        </h3>)}
        {question?.label && <p>{question?.label}</p>}
        {/* <Row>
          <Col> */}
        <div style={{

          flexDirection: question?.type === ONBOARDING_INPUT_TYPES.SLIDER ? 'column' : 'row',
          alignItems:'center',
          justifyContent:'center',
          paddingHorizontal:50,
          flexWrap:'wrap',
          display: 'flex'
        }}>
          {question?.type === ONBOARDING_INPUT_TYPES.SINGLE_CHOICE &&
            question?.options?.length
            ? question.options.map((option) => (
              <div
                key={option}
                style={{ margin: "10px" }}
                onClick={() => onAnswer(option)}
              >
                <OptionButton className="ghost-btn sm-btn">{option}</OptionButton>
              </div>
            ))
            : null}
          {question?.type === ONBOARDING_INPUT_TYPES.SLIDER &&
            question?.options ? (
            <>
              {/* <div> */}
              <Range
                type="range"
                max={question?.options?.max || 10}
                min={question?.options?.min || 0}
                value={rangeValue}
                onChange={(e) => setRangeValue(e.target.value)}
              />
              {/* </div> */}
              <div style={{ display: "flex" }}>
                <Label style={{ marginLeft: "auto", marginRight: "auto" }}>
                  {rangeValue}
                </Label>
              </div>
              <div style={{ display: "flex" }}>
                <OptionButton
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  color={COLORS.eastBay}
                  hoverColor={COLORS.azureRadiance}
                  onClick={() => onAnswer(rangeValue)}
                >
                  Select
                </OptionButton>
              </div>
            </>
          ) : null}
          {question?.type === ONBOARDING_INPUT_TYPES.FORM ? (
            <TeamInviteForm />
          ) : null}
        </div>
        {/* </Col>
        </Row> */}
      </ModalBody>
    </Modal>
  );
};

export default OnboardingModal;
