import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, InputGroup } from "reactstrap";

import NumberFormat from "react-number-format";
// import PhoneNumber from 'awesome-phonenumber'
import PhoneInput from "react-phone-number-input/input";
// import { isValidPhoneNumber } from 'react-phone-number-input/core';

import { AsYouType } from "libphonenumber-js";

import { FIELD_TYPES } from "../constants/recordTypes";
import { Dropdown } from "./DropDown";
import { SubFieldForm } from "./SubFieldForm";
import { List, ListItem } from "./library";
import { InputForm } from "./Inputs";

export const FieldType = ({
  field,
  handleChange,
  value,
  name,
  hideLabel,
  isDisplay,
  required,
  className,
  data = false,
  onClick,
  isFilter,
  style
}) => {
  let incomingStyle = style || {};
  let label = !hideLabel && field.label && (
    <p className="field-title" style={{ fontWeight: 600 }}>
      {field.label} {required && <span style={{ color: "#ff4343" }}>*</span>}
    </p>
  );
  const users = useSelector(
    (state) => (state.users && state.users.users) || {}
  );
  let [isDropDownOpen, setDropDownToggle] = useState(false);
  let [dropdownValue, setValue] = useState("");

  let setDropDownItem = (user) => {
    onClick("ownerId", user);
    setValue(user);
    setDropDownToggle(false);
  };

  useEffect(() => {
    if (field.field == "ownerId") {
      setDropDownItem(value?.length ? value.replace(" ", "") : value || false);
    }
  }, []);
  switch (field.type) {
    case "ID":
      if (field.field == "ownerId") {
        return (
          <div className={"field-type " + className}>
            {label}
            <Dropdown
              toggle={() => setDropDownToggle(!isDropDownOpen)}
              isOpen={isDropDownOpen}
              defaultValue={"Add Owner"}
              selectedValue={
                dropdownValue &&
                users[dropdownValue] &&
                users[dropdownValue].firstName +
                  " " +
                  users[dropdownValue].lastName
                  ? users[dropdownValue].firstName +
                    " " +
                    users[dropdownValue].lastName
                  : ""
              }
              className="dropdown-sm"
              targetId={data.modalId}
            >
              <List>
                <ListItem
                  active={value == false}
                  onMouseDown={() => setDropDownItem(false)}
                >
                  {"None"}
                </ListItem>
                {users &&
                  Object.keys(users).length > 0 &&
                  Object.keys(users)
                    .sort((a, b) =>
                      users[a].firstName?.toLowerCase() <
                      users[b].firstName?.toLowerCase()
                        ? -1
                        : users[a].firstName?.toLowerCase() >
                          users[b].firstName?.toLowerCase()
                        ? 1
                        : 0
                    )
                    .map((user, key) => {
                      if (!users[user]?.deleted) {
                        return (
                          <ListItem
                            key={key}
                            active={dropdownValue == user}
                            onMouseDown={() => setDropDownItem(user)}
                          >
                            {users[user].firstName + " " + users[user].lastName}
                          </ListItem>
                        );
                      } else {
                        return;
                      }
                    })}
              </List>
            </Dropdown>
          </div>
        );
      }
      return (
        <>
          {/*  {label}
            <Input
            
            
            />{" "} */}
          <InputForm
            placeholder={field.label}
            onChange={handleChange}
            name={name}
            required={required}
            value={value || ""}
          />
        </>
      );
    case "STRING":
      return (
        <div className={"field-type " + className}>
          {/* {label}
             <Input
              placeholder={field.label}
              onChange={handleChange}
              name={name}
              required={required}
              value={value || ""}
            /> */}
          <InputForm
            style={incomingStyle}
            placeholder={field.label}
            onChange={handleChange}
            name={name}
            required={required}
            value={value || ""}
          />
        </div>
      );
    case "BOOL":
      return (
        <div className={"field-type " + className}>
          {label}
          <div
            style={{ marginTop: 4 }}
            className={"radio-switch " + (value ? "radio-switch-active" : "")}
            onClick={() => onClick(name, !value)}
          >
            <span className="radio-switch_text">{value ? "Yes" : "No"} </span>
            <div className="radio-switch_button"></div>
          </div>
        </div>
      );
    case "DATE":
      return (
        <div className={"field-type " + className}>
          {/* {label}
            <Input
              type={isFilter ? "text" : "date"}
              placeholder={field.label}
              onChange={handleChange}
              name={name}
              required={required}
              value={value || ""}
            /> */}

          <InputForm
            style={style}
            placeholder={field.label}
            onChange={handleChange}
            name={name}
            type={isFilter ? "text" : "date"}
            required={required}
            value={value || ""}
          />
        </div>
      );
    case "PHONE":
      let numberReformat = "";
      const asYouType = new AsYouType();
      let extension = false;
      let phoneValue = typeof value === "string" ? value : value?.phoneNumber;
      if (typeof phoneValue == "number") {
        phoneValue = phoneValue.toString();
      }
      if (phoneValue && phoneValue?.includes("ext.")) {
        extension = phoneValue?.split("ext.")?.[1];
      }

      if (typeof phoneValue == "number") {
        numberReformat = phoneValue.toString() || "";
      } else {
        numberReformat =
          phoneValue && typeof phoneValue === "string"
            ? phoneValue.replace(/[^A-Z0-9]+/gi, "")
            : "";
      }
      let isUS = false;
      if (numberReformat?.length == 10 && numberReformat.charAt(0) !== "1") {
        isUS = true;
      }
      asYouType.input("+" + numberReformat);
      let formatInput = asYouType.formattedOutput.replace(/[0-9]/g, "#");
      let finalNumber = asYouType.formattedOutput.replace(/[^A-Z0-9]+/gi, "");

      if (finalNumber.length == 11 && numberReformat.charAt(0) == "1") {
        formatInput = "# (###) ###-#### #";
      } else if (finalNumber.length == 10) {
        formatInput = "(###) ###-#### #";
      } else {
        formatInput = formatInput + "#";
      }

      return (
        <div className={"field-type " + className}>
          {isDisplay ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {label}
              <div className="flex" style={{ flexDirection: "column" }}>
                <NumberFormat
                  className={
                    (!handleChange || isDisplay
                      ? "input_plain-format"
                      : "form-control") + " number-input"
                  }
                  type={"tel"}
                  disabled={!handleChange || isDisplay}
                  require={required || undefined}
                  value={finalNumber || ""}
                  name={name}
                  format={
                    finalNumber?.length > 9 ? formatInput : "###############"
                  }
                  mask=""
                  onValueChange={(values) => {
                    let { value, formattedValue } = values;
                    handleChange &&
                      handleChange({ target: { name, value, formattedValue } });
                  }}
                />
                {value?.extension && (
                  <span
                    className="body-tiny-grey"
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    Extension: {value.extension}
                  </span>
                )}
              </div>
            </div>
          ) : (
            <>
              <SubFieldForm
                modalId={data.modalId}
                values={value}
                field={field}
                primaryValue={phoneValue}
                primaryField={"phoneNumber"}
                name={name}
                handleChange={handleChange}
                label={label}
                required={required}
                type={FIELD_TYPES.PHONE}
                onClick={onClick}
                style={style}
              />
            </>
          )}
        </div>
      );
    case "NUMBER":
      return (
        <div className={"field-type " + className}>
          {/* {label}
            <Input
              min="0"
              type="number"
              placeholder={field.label}
              onChange={handleChange}
              name={name}
              required={required}
              value={value || ""}
            /> */}

          <InputForm
            style={style}
            min="0"
            type="number"
            placeholder={field.label}
            onChange={handleChange}
            name={name}
            required={required}
            value={value || ""}
          />
        </div>
      );
    case "EMAIL":
      let emailValue = typeof value === "string" ? value : value?.email;
      return (
        <div className={"field-type " + className}>
          <SubFieldForm
            modalId={data.modalId}
            values={value}
            field={field}
            primaryValue={emailValue}
            primaryField={"email"}
            name={name}
            handleChange={handleChange}
            label={label}
            required={required}
            type={FIELD_TYPES.EMAIL}
            onClick={onClick}
            style={style}
          />
        </div>
      );
    case "URL":
      return (
        <div className={"field-type " + className}>
          {label}
          <InputForm
            placeholder={"https://www.website.com"}
            onChange={handleChange}
            name={name}
            // type="url"
            required={required}
            value={value || ""}
          />
        </div>
      );
    case "TEXTAREA":
      return (
        <div className={"field-type " + className}>
          {/* {label}
            <Input
              style={{ padding: 10 }}
              placeholder={field.label}
              onChange={handleChange}
              name={name}
              type="textarea"
              required={required}
              value={value || ""}
            /> */}

          <InputForm
            style={style || { padding: 10 }}
            placeholder={field.label}
            onChange={handleChange}
            name={name}
            type="textarea"
            required={required}
            value={value || ""}
          />
        </div>
      );
    default:
      return "";
  }
};

FieldType.defaultProps = {
  className: "",
  style: {},
};

export default FieldType;
