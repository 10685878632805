import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { generatePushID } from "../../shared/utils/keyGenerator";
import InviteMemberForm from "./InviteMemberForm";
import { useSelector } from "react-redux";
import { inviteTeamMembers } from "../../redux/actions/member";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter } from "../../shared/ui/Modals";
import { Button } from "../../shared/ui/Buttons";
import { FlexContainer, ScrollContainer } from "../../shared/ui/Containers";
import { Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { COLORS, SPACING, TYPOGRAPHY } from "../../shared/constants/index";
import { Form } from "reactstrap";
import styled from "styled-components";

const LinearGradient = styled.div`
  position:absolute;
  bottom:0;
  display:flex;
  width:100%;
  padding-top:60px;
  padding-bottom:60px;
  // backgroundColor:green;
  align-items:center;
  flex-direction:column;
  background: linear-gradient(180deg,rgba(0,0,0,0), rgba(72, 61, 99, 0.0001) -50.01%, #483D63 43.38%, #483D63 100%);
`

const InviteMembersModal = ({ isOpen, toggle }) => {
  const [invitees, setInvitees] = useState({});
  const { info } = useSelector((state) => state.users);
  const users = useSelector((state) => state.users);

  let roles = users?.org?.roles || [];
  let departments = users?.org?.departments || {};
  const init_department = Object.keys(departments).filter(
    (id) => departments[id]?.name === "Default"
  );
  const init_team =
    departments?.[init_department]?.teams && Object.keys(departments?.[init_department]?.teams)
      ? Object.keys(departments?.[init_department]?.teams)?.filter(
        (id) => departments?.[init_department]?.teams[id]?.name === "Default"
      )
      : null;

  let { companyId } = info || {};

  roles = roles.map((val, index) => ({ value: index, label: val.name }));

  const addInvitee = (isNotInit = true) => {
    const pushId = generatePushID();

    setInvitees({
      ...(!isNotInit ? {} : { ...invitees }),
      [pushId]: {
        firstName: "",
        lastName: "",
        email: "",
        role: null,
        team: init_team?.[0] ? ["Default", init_team[0]] : null,
        department: init_department?.[0] ? ["Default", init_department[0]] : null,
      },
    });
  };

  const removeInvitee = (id) => {
    let inviteesTemp = { ...invitees };
    delete inviteesTemp[id];
    setInvitees({
      ...inviteesTemp,
    });
  };

  const handleMemberChange = (e, id) => {
    const invite = invitees[id];
    invite[e.target.name] = e.target.value;
    setInvitees({ ...invitees, [id]: invite });
  };

  const handleInviteClick = (e) => {
    e.preventDefault();
    const members = { ...invitees };
    Object.keys(members).map((key) => {
      members[key].role = [members[key]?.role?.label, members[key].role.value];
    });
    inviteTeamMembers(members).then(() => {
      toast("Your teammates will receive an invite email!", {
        hideProgressBar: true,
      });
    }).catch((error) => {
      toast("Failed to send invite email!", {
        hideProgressBar: true,
      });
    })

    toggle();
  };

  useEffect(() => {
    if (isOpen) {
      addInvitee(false);
    }
    return () => {
      setInvitees({});
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} showClose={false} size="md">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: `${SPACING.SM}px ${SPACING.MD}px 0`,
          // backgroundColor: 'red'
        }}
      >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <h3 style={{ fontWeight: 600, display: 'flex', alignSelf: 'center' }}>
            Invite Team Member
          </h3>
          <Icon
            className="modal_header-close"
            type={ICON_TYPES.close}
            style={{ position: 'absolute', right: 10, fontSize: TYPOGRAPHY.h3 }}
            onClick={toggle ? toggle : undefined}
          />
        </div>
      </div>
      <Form onSubmit={(e) => handleInviteClick(e)}>
        <ModalBody>
          <ScrollContainer height="60vh">
            <div style={{ height: "100%", paddingRight: SPACING.XXS, paddingBottom: 160 }}>
              {Object.keys(invitees).map((key, i) => {
                return (
                  <div key={key} style={Object.keys(invitees).length > 1 && Object.keys(invitees).length - 1 === i ? { paddingBottom: 100 } : {}} >
                    {i > 0 && <hr style={{ opacity: 1 }} />}
                    <FlexContainer
                      alignItems="baseline"
                    >
                      <div>{i + 1}. Team Member</div>
                      {i > 0 && (
                        <span style={{ fontSize: TYPOGRAPHY.tiny, textDecoration: "underline", paddingLeft: 10 }}
                          onClick={() => removeInvitee(key)}
                        >Remove</span>
                      )}
                    </FlexContainer>
                    <InviteMemberForm
                      member={invitees[key]}
                      onChange={(e) => handleMemberChange(e, key)}
                      roles={roles}
                    />
                  </div>
                );
              })}
            </div>
          </ScrollContainer>
        </ModalBody>
        {/* <ModalFooter> */}

        <LinearGradient>
          <Button onClick={addInvitee} style={{ width: 286, marginBottom: 10 }}>
            + Add Another Member
          </Button>
          <Button type="submit" style={{ width: 286 }}>
            Done
          </Button>
        </LinearGradient>
        {/* </ModalFooter> */}
      </Form>
    </Modal>
  );
};

export default InviteMembersModal;
