import users from './users';
import member from './member';
import content from './content';
import meetings from './meetings';
import loaders from './loaders';


const rehydrated = (state = false, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return true;
    default:
      return state;
  }
};

const exports = {
  rehydrated,
  users,
  content,
  loaders,
  member,
  meetings
};

export default exports;