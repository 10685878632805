import React, {useRef, useEffect, useState} from 'react';

const List = ({children, className, id , title, onClick, isOpen, style, onScrollEvent}) => {
    let listContainerRef = useRef(null);
    let listItemRef = useRef(null);
    let listItemsRef = useRef(null)
    let [scroll, isScroll] = useState(false);

    useEffect(() => {
        if(listContainerRef.current.offsetHeight < listItemRef.current.offsetHeight){
            isScroll(true)
        }else{
            isScroll(false)
        }
    
    }, [listContainerRef.current , listItemRef.current, children]);

    const onScrollData = () => {
      if(onScrollEvent && listItemRef.current && listContainerRef.current && listItemsRef.current){
        onScrollEvent({
          inner: listItemsRef.current,
          items: listItemRef.current,
          outer: listContainerRef.current 
        })
      }
    }

    return (
        <div  style={style} ref={listContainerRef} id={id || ""} className={"list-container " + (className || "") + (scroll ? " list-container-scroll" : "")}>
            {title && <h4>{title}</h4>}
            <div  className="list-container_items" onScroll={() => onScrollData()}  ref={listItemsRef} >
                {isOpen && 
                <ul  onClick={onClick || null} ref={listItemRef} >
                    {children}
                </ul>}
                {/* { scroll &&
                    <div className="scroll-indicator" >
                        <i className="fal fa-angle-down"></i>
                    </div>
                } */}
            </div>
        </div>
    )
}

List.defaultProps = {
    children: false,
    className: undefined,
    title: false,
    id: false,
    onClick: false,
    isOpen: true,
    style:{},
    onScroll: false
}

export default List;
