export const initialState = {};

export default function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_LOADER": {
      return {
        ...state,
        [action.label]: action.data
      };
    }

    default:
      return state;
  }
}
