import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { useAuth } from "../../contexts/AuthContext";
import { setAppCompany } from "../../redux/actions/companies";
import AuthContainer from "../../shared/ui/AuthContainer";
import { AuthButton } from "../../shared/ui/Buttons";
import { InputForm } from "../../shared/ui/Inputs";
import { Link } from "../../shared/ui/Links";
import { SPACING } from "../../shared/constants";
import Loader from "../../shared/ui/Loader";


const SocialRegister = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, socialProfile, setSocialProfile } = useAuth();
  const formRef = useRef();

  async function handleSubmit(values) {
    setIsLoading(true);
    try {
      const formData = { ...values, shouldAuthorizeZoom: true };
      await setAppCompany(formData, socialProfile?.uid);
      setSocialProfile(null);
      navigate("/home");
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if(socialProfile && formRef?.current) {
      const [firstName, lastName] = socialProfile?.displayName.split(" ");
      const email = socialProfile?.email;
      formRef.current.setValues({firstName, lastName, email})
    }
  }, [socialProfile])

  useEffect(() => {
    if (currentUser && !socialProfile) {
        navigate("/home");
    }
  }, [currentUser, socialProfile, navigate]);

  return (
    <div style={{paddingTop: SPACING.MD * 3}} >
      <AuthContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <h1 style={{ marginBottom: 25 }}>Create Account</h1>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                companyName: "",
                email: ""
              }}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {({ values, handleSubmit, handleChange, handleBlur }) => {
                return (
                  <form onSubmit={handleSubmit} className="form-signin">
                    <InputForm
                      type="text"
                      value={values.firstName}
                      name="firstName"
                      required
                      onChange={handleChange}
                      placeholder={"First Name"}
                    />
                    <InputForm
                      type="text"
                      value={values.lastName}
                      name="lastName"
                      required
                      onChange={handleChange}
                      placeholder={"Last Name"}
                    />
                    <InputForm
                      type="text"
                      value={values.companyName}
                      name="companyName"
                      required
                      onChange={handleChange}
                      placeholder={"Company Name"}
                    />
                    <InputForm
                      type="email"
                      value={values.email}
                      name="email"
                      required
                      disabled={socialProfile}
                      onChange={handleChange}
                      placeholder={"Email"}
                    />
                    <div className="mt-3">
                      <AuthButton color={'primary'} type="submit">Sign Up</AuthButton>
                    </div>
                    <div className="mt-5">
                      <Link href="/">Already a member? Log in</Link>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}
      </AuthContainer>
    </div>
  );
};

export default SocialRegister;
