import { Modal, ModalBody } from "shared/ui/Modals";
import { Button } from "shared/ui/Buttons";
import { useEffect, useState } from "react";
import { SelectList } from "shared/ui/Inputs";
import { useSelector } from "react-redux";
import { TYPE_LIST } from "shared/constants";
import styled from "styled-components";

import { updateMeeting } from "../../../redux/actions/meetings";

const ModalContainer = styled(Modal)`
  .modal-content{
    overflow: visible;
  }
  .modal_body{
    overflow: visible;
  }
`
const OutcomeModal = ({
  isOpen,
  toggle,
  interactionId,
  companyId,
  interaction,
  onSave,
  onOutcomeLoading
}) => {
  const content = useSelector((state) => state?.content?.company || {});
  const [outcome, setOutcome] = useState(interaction?.dispositionId ? {
    value: {
      dispositionLabel: interaction?.dispositionLabel,
      dispositionId: interaction?.dispositionId,
      complete: interaction.complete,
      sentiment: interaction.sentiment,
    },
    label: interaction?.dispositionLabel
  } : {});
  const callFlowId = interaction?.callFlowId;
  const triggerId = interaction?.triggerId;
  const outcomes =
    callFlowId && triggerId
      ? content?.[TYPE_LIST.CALLFLOWS]?.[callFlowId]?.content?.[
      TYPE_LIST.TRIGGERS
      ]?.[triggerId]?.[TYPE_LIST.DISPOSITIONS]
      : content?.[TYPE_LIST.DISPOSITIONS];
  const activeOutcomes = outcomes ? Object.keys(outcomes).filter((id) => outcomes?.[id]?.active && !outcomes?.[id]?.deleted).map(id => ({
    value: {
      dispositionLabel: outcomes?.[id]?.label,
      complete: outcomes?.[id]?.complete,
      sentiment: outcomes?.[id]?.sentiment,
      dispositionId: id
    },
    label: outcomes?.[id]?.label
  })) : [];
  const [isLoading, setIsLoading] = useState(false);

  const handleOutcome = async () => {
    setIsLoading(true);
    onOutcomeLoading(true)
    try {
      const updatedValue={
        ...outcome.value,
        complete:!!outcome.value.complete, // because the api call fails if its undefined so what happens here is if there is an undefined its converted into false
      }
      await updateMeeting(companyId, interactionId,updatedValue);
      onSave()
    } catch (error) {

    } finally {
      setIsLoading(false)
      onOutcomeLoading(false)
      toggle()
    }
  };

  return (
    <ModalContainer isOpen={isOpen} toggle={toggle} title={"Add Outcome"} style={{ overflow: 'initial' }}>
      <ModalBody>
        <SelectList
          required
          placeholder={outcome?.label || "Add Outcome"}
          onChange={(val) => {
            setOutcome(val);
          }}
          options={activeOutcomes}
        />
        <Button
          onClick={handleOutcome}
          style={{ marginLeft: "auto" }}
          disabled={isLoading}
        >
          Save
        </Button>
      </ModalBody>
    </ModalContainer>
  );
};

export default OutcomeModal;
