import { realtimeDb, functions, auth } from "../../firebase/firebase";

let companySubscriptionRef = null;
let companySubscriptionRefListener = null;

export async function setAppCompany(formData, uid) {
  const data = await functions.httpsCallable("setup-create-app-user-company")({...formData, uid});
  localStorage.setItem("companyId", data.data.companyId);
  await auth.signInWithCustomToken(data.data.token);
  
  return data;
}

export const getSubscriptionInfo = (companyId) => {
  return (dispatch) => {
    companySubscriptionRef = realtimeDb.ref(`Subscriptions/${companyId}`)
    companySubscriptionRefListener =  companySubscriptionRef.on("value", (snapshot) => {
      let data = snapshot.val();
      dispatch({
        type: "USER_COMPANY_SUBSCRIPTION",
        data: { ...data }
      });

    });
  }
};

export const deleteCompanyListeners = () => {
  if(companySubscriptionRef && companySubscriptionRefListener) {
    companySubscriptionRef.off("value", companySubscriptionRefListener);
  }
};