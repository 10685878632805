import React from "react";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { InputForm, SelectList } from "../../shared/ui/Inputs";
import styled from "styled-components";
import { SPACING } from "../../shared/constants";
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isTwo ? "1fr 1fr" : "1fr")};
  grid-column-gap: ${SPACING.SM}px;
`;
const InviteMemberForm = ({ member, onChange, roles }) => {
  return (
    <div style={{ padding: 4, width: "100%", marginBottom: SPACING.LG }}>
      <GridContainer isTwo={true}>
        <InputForm
          value={member.firstName}
          onChange={onChange}
          name="firstName"
          placeholder={"First Name"}
          required
        />
        <InputForm
          value={member.lastName}
          onChange={onChange}
          name="lastName"
          placeholder={"Last Name"}
          required
        />
      </GridContainer>
      <GridContainer>
        <InputForm
          value={member.email}
          onChange={onChange}
          name="email"
          placeholder={"Email"}
          required
        />
      </GridContainer>
      <GridContainer>
        <SelectList
          required
          placeholder="Role"
          onChange={(val) =>
            onChange({
              target: {
                name: "role",
                value: val,
              },
            })
          }
          options={roles}
        />
      </GridContainer>
    </div>
  );
};

export default InviteMemberForm;
