import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Card } from "shared/ui/Cards";
import { APP_LAYOUT, COLORS, SPACING } from "../../shared/constants";
import { Avatar } from "shared/ui/Icon";
import TableChartModal from "./TableChartModal";
import { getUserFirstName } from "../../redux/actions/users";
// import { COLORS } from "shared/constants";
import featuredCardImg from "assets/dashboard/feedCard.png";
import { useNavigate } from "react-router-dom";

const HorizontalScrollView = styled.div`
  overflow: scroll;
  position: relative;
  min-height: 300px;
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.type == APP_LAYOUT.ZOOM ?  COLORS.greyDark :  theme.colors.grey1};
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const Content = styled.div`
  // position: absolute;
  // display: flex;
`;

const  DefaultActivityCard = styled.div`
background-size: contain;
height: 100%;
width: 100%;
background-repeat: no-repeat;
background-position: 0 0;
background-image: url(${featuredCardImg});
`;

const FeedCard = styled(Card)`
  cursor: pointer;
  margin-right: ${(props) => (props.index !== 0 ? 10 : 0)}px;
  // height: 181px;
  width: 100%;
  border-radius: 5px;
  margin-top: 0px;
  background-color: ${COLORS.azureRadiance};

  color: ${({theme}) => theme.type === APP_LAYOUT.ZOOM ?  COLORS.white : theme.text.color};

  ${({type, theme}) =>
  ` background-color: ${theme.type === APP_LAYOUT.ZOOM ? theme.card.background  :  theme.card.background };
    border: ${theme.type === APP_LAYOUT.ZOOM ? 0 :  `1px solid ${theme.border.color}` };
  `};

  display: flex;
  align-items: center;
  margin-bottom: ${SPACING.SM}px;
`;

export default function ActivityFeed(props) {
  const { activityFeedList } = props;

  const [showModal, setShowModal] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { recent, company } = useSelector(state => state?.content || {});
  const { info } = useSelector((state) => state.users);
  let { companyId } = info || {};
  let navigate = useNavigate();

  const toggle = () => {
    setShowModal(!showModal);
  };
  const populateRecentInteractions = async() => {
    const recentInteractions = [];
    for (const type of Object.keys(recent)) {
      const interactionsForType = recent[type];
      if(interactionsForType && Object.keys(interactionsForType).length) {
        for (const interactionId of Object.keys(interactionsForType)) {
          const interaction = interactionsForType[interactionId];
          const name = await getUserFirstName(companyId, interaction.uid);
          const playbook = company["CallFlows"]?.[interaction?.callFlowId]?.label || "";
          const meetingPlan = company["Triggers"]?.[interaction?.triggerId]?.label || "";
          const text = `${name} recently used ${meetingPlan} in the ${playbook} playbook`;
          if(company["Triggers"]?.[interaction?.triggerId]?.label && name ){
            recentInteractions.push({
              ...interaction,
              username: name,
              interactionId,
              label: text
            })
          }
        }
      }
    }
    setLoading(false);
    setActivityList(recentInteractions)
  }

  useEffect(() => {
    if(recent && Object.keys(recent).length){
      setLoading(true);
      populateRecentInteractions();
    }
  }, [recent])
  

  return (
    <SkeletonTheme
      baseColor={COLORS.lightBlue}
      highlightColor={COLORS.grey1}
      borderRadius="0.5rem"
      duration={0.5}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          width: "100%",
          paddingBottom: 80
        }}
      >
        <h3 style={{ fontWeight: "bold", paddingTop: 20 }}>Feed</h3>
        <HorizontalScrollView>
          <Content>
            {activityList.length && !loading
              ? activityList.map((item, index) => {
                  return (
                    <FeedCard key={index} index={index} onClick={() => item?.interactionId && navigate(`/meeting/${item?.interactionId}`) } >
                      <Avatar
                        name={item.username}
                        round
                        size="45"
                        style={{ marginRight: SPACING.SM + 2 }}
                      />
                      <h5
                        style={{
                          textAlign: "left",
                          display: "flex",
                          margin: 0
                          // flex: 1,
                        }}
                      >
                        {item?.label
                          ? item?.label
                          : item.combo
                          ? `${item.combo[0].label} and ${item.combo[1].label}`
                          : ""}
                      </h5>
                    </FeedCard>
                  );
                })
              : null}
            {loading
              ? [{}, {}, {}].map((index, i) => (
                  <FeedCard key={i}>
                    <Skeleton circle count={1} height="45px" width={"45px"} />
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        alignItems: "center",
                        marginLeft: SPACING.SM * 2
                      }}
                    >
                      <Skeleton count={1} height="45px" width={"400px"} />
                    </div>
                  </FeedCard>
                ))
              : null}
            { !loading && !activityList.length ? (
        
               <div style={{height: 150, marginTop: 20}}>
             
              <div
                style={{ margin: 0, height: "100%", paddingTop: 0 }}
              >
                <DefaultActivityCard />
              </div>
              <h5 style={{ textAlign: "left", marginTop: 14 }}>
              Recent meetings will show up here
                </h5>
            </div>
            ) : null}
          </Content>
          <TableChartModal isOpen={showModal} toggle={toggle} />
        </HorizontalScrollView>
      </div>
    </SkeletonTheme>
  );
}
