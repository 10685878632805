import React from 'react';

const ListItem = ({children, className, id, onClick, active, onMouseDown, tabIndex, style}) => {
    return (
        <div tabIndex={tabIndex} className={"list-container_items_item " + (active ? " list-container_items_item-active ": " " ) + (className || "") }
            onClick={onClick || null}
            id={id || ""}
            onMouseDown={onMouseDown || null}
            style={style}
        > 
            {children}
        </div>
    )
}

ListItem.defaultProps = {
    children: false,
    className: null,
    id: null,
    onClick: false,
    active: null,
    onMouseDown: false,
    style:{}
}

export default ListItem;
