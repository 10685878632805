import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";


const StyledActive = styled.span`
  margin: 10px 14px 10px 0;
  cursor: pointer;
  padding: 0;
  padding-bottom: 10px;
   ${props => props.isActive ? `border-bottom: 3px solid ${props?.theme?.border?.color || "#333"}`: ""}
`;

const TabbedView = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if((!activeTab || (tabs && activeTab && !Object.keys(tabs).includes(activeTab))) && tabs) {
      setActiveTab(Object.keys(tabs)[0]);
    }
  }, [tabs])
  

  return (
    <>
      <Nav tabs style={{ border: "none", marginBottom: "3px" }}>
        {tabs &&
          Object.keys(tabs).map((tabKey) => (
            <NavItem key={tabKey}>
              <StyledActive
                isActive={activeTab === tabKey}
                onClick={() => setActiveTab(tabKey)}
              >
                {tabs[tabKey].header}
              </StyledActive>
            </NavItem>
          ))}
      </Nav>
      <TabContent style={{marginTop: "25px", marginBottom: "14px"}} activeTab={activeTab}>
        {tabs &&
          Object.keys(tabs).map((tabKey) => (
            <TabPane key={tabKey} tabId={tabKey} style={{height: "100%"}}>{tabs[tabKey].content}</TabPane>
          ))}
      </TabContent>
    </>
  );
};

export default TabbedView;
