import axios from "axios";
import { BASE_URL, ONBOARDING_STEP } from "../../shared/constants";
import { realtimeDb, functions, firebase } from "../../firebase/firebase";
let userInfoRef = null;
let userInfoRefListener = null;
export const updateUserLoginCount = (companyId, uid) => {
  if(!(companyId && uid)) return
  try{
    realtimeDb.ref(`Users/${companyId}/${uid}/loginCount`)
    .once("value").then((snapshot) => {
      let loginCount = snapshot.val() || 0;
      realtimeDb.ref(`Users/${companyId}/${uid}/`).update({
        loginCount: ++loginCount,
        lastLoggedIn: firebase.database.ServerValue.TIMESTAMP
      })
    })
    .catch(e => console.error("UPDATE COUNT", e))
  }catch(e){
    console.log(e, 'error')
  }
}
export const getUserInfo = (uid, companyId) => {
  return (dispatch) => {
    userInfoRef = realtimeDb.ref(`Users/${companyId}/${uid}`)
    userInfoRefListener =  userInfoRef.on("value", (snapshot) => {
      let data = snapshot.val();
      data.shouldAuthorizeZoom = data.shouldAuthorizeZoom == undefined ? true : data.shouldAuthorizeZoom;
      dispatch({
        type: "USER_PUBLIC_DATA",
        data: { ...data, uid, companyId }
      });

    });
    realtimeDb.ref(`Admin/${companyId}/Org`).once("value").then((resp) => {
      const orgData = resp.val();
      if (orgData) {
        dispatch({
          type: "USER_ORG_DATA",
          data: orgData,
        });
      }
    });
  }
};

export function handleUserAutoSchedule(uid, companyId, autoJoin) {
  return (dispatch) => {
    dispatch({type: "UPDATING_USER_AUTO_JOIN", data: true})
    axios.post(`${BASE_URL}/nylas/toggle-auto-join`, {uid, companyId, autoJoin}).then(() => {
      dispatch(getUserInfo(uid, companyId));
      dispatch({type: "UPDATING_USER_AUTO_JOIN", data: false});
    })
  }
}

export async function getUserFirstName(companyId, uid) {
  if(companyId && uid){
    try{
      const firstName = (await realtimeDb.ref(`Admin/${companyId}/ACL/${uid}/firstName`).once("value")).val();
      return firstName;
    }catch(e){
      return ""
    }
  }
}

export function removeUserInfoListener() {
  if( userInfoRef ){
    try{
      userInfoRef.off("value", userInfoRefListener);
    }catch(e){
      console.log(e)
    }
  }
}


export const updateOnboarding = ({
  onBoardStep,
  onBoardStepNo,
  answer,
  uid,
  companyId,
  next,
}) => {
  return (dispatch) => {
    realtimeDb.ref(`Users/${companyId}/${uid}/onboarding/zoom/answers/${onBoardStep}`).update({ [onBoardStepNo]: answer }).then(() => {
      if (!next) {
        onBoardStep = ONBOARDING_STEP.COMPLETE;
        onBoardStepNo = null;
      } else {
        const [step, stepNo] = next.split(".");
        onBoardStep = step;
        onBoardStepNo = stepNo;
        if(onBoardStep === ONBOARDING_STEP.INVITE_TEAM) {
          console.log("Content", {
            companyId, 
            templateFlowId: "template-flow",
            templateReasonId: "template_demo", 
            date: new Date().toISOString() });
          functions.httpsCallable("setup-create-content")({
            companyId, 
            templateFlowId: "template-flow",
            templateReasonId: "template_demo", 
            date: new Date().toISOString() }).then(() => {
            console.log("setup create content called");
          }).catch(error => console.error(error));
        }
      }
      realtimeDb.ref(`Users/${companyId}/${uid}/onboarding/zoom`).update({
        onBoardStep,
        onBoardStepNo,
      }).then(() => {
        // dispatch(getUserInfo(uid, companyId));
      });
    });
  };
};

export const updateUserZoomAuth = async (appUserId, isInviteUser) => {
  await axios.post(`${BASE_URL}/zoom/auth`, { appUserId, isInviteUser });
};

export const updatePasswordChange = (uid, companyId) => {
  // console.log("EXECUTING PASSWORD CHANGE");
  return (dispatch) => {
    console.log("UPDATING USER SETTINGS");
    axios
      .get(`${BASE_URL}/zoom/update-password-setting`)
      .then(() => {
        console.log("UPDATED USER SETTINGS");
        // dispatch(getUserInfo(uid, companyId));
      });
  };
};

export const getCustomTokenClaim = async(userId) =>{
  return await axios.post(`${BASE_URL}/zoom/custom-token`, {uid: userId});
}

export const updateUserLoginStatus = async(status) =>{
  return (dispatch) => {
    dispatch({
      type: "USER_LOGIN_STATUS",
      data: status
    });
  }
}

export function getUsers(uid, companyId) {
  return (dispatch) =>  {
    if(companyId && uid){
      realtimeDb.ref(`Admin/${companyId}/ACL`).orderByChild("deleted").equalTo(null).once("value").then(snapshot => {
        const users = snapshot.val() || {}
        dispatch({ type: "USERS_DATA", data: users });
      }).catch(e => console.log(e))
    }
  }
}
