import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import { SPACING, COLORS } from "../../shared/constants";
import styled from "styled-components";

import { useAuth } from "../../contexts/AuthContext";
import { setAppCompany } from "../../redux/actions/companies";
import { AuthButton } from "../../shared/ui/Buttons";
import { InputForm } from "../../shared/ui/Inputs";
import { Link } from "../../shared/ui/Links";
import Loader from "../../shared/ui/Loader";
import media from 'shared/utils/media';

import cubicles from "assets/cubicles.png";
import logo from "assets/upmarket.png";



const validationSchema = Yup.object().shape({
  password: Yup.string().required("This field is required"),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both passwords need to be the same"
    ),
  }),
});

const FormWrapper = styled.div`
  display: flex; 
  flex-direction: column; 
  overflow:scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.greyDark};
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

`

const Input = styled(InputForm)`

height: 50px; 
width: 355px; 
margin: 0px 20px 0px 0px;
${media.lessThan('tablet')`
margin: 0px 0px 0px 0px;

  `}
`

const RightInput = styled(InputForm)`
height: 50px; 
width: 355px; 
margin: 00px 0px 0px 0px;
  `

const FieldWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;


  ${media.lessThan('tablet')`
    flex-direction:column;
  `}
`

const Container = styled.div`
  // overflow: scroll; 
  background-size: cover;
  display: flex;
  width: 100vw;
  height: 100vh; 
  flex-direction: column; 
  align-items: center;
  justify-content: center;
`

const Cubicles = styled.img`
  z-index:0;
  position: absolute;
  height: 80%;
  // aspect-ratio:2/3;
  bottom: 0;
  right: 0;
  resize-ode: contain
`;

const Register = () => {
  const { appName } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();

  async function handleSubmit(values) {
    setIsLoading(true);
    try {
      const appUserId = localStorage.getItem("appUserId");
      const url = new URL(document.location);
      let code = url.searchParams.get("code");
      const formData = { ...values, ...(appUserId && { appUserId, appName }), ...(!code && { shouldAuthorizeZoom: true }) };
      await setAppCompany(formData);
      localStorage.removeItem("appUserId");
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (currentUser) {
      const url = new URL(document.location);
      let code = url.searchParams.get("code");
      let state = url.searchParams.get("state");
      if (code && state) {
        navigate({
          pathname: "/home",
          search: `?code=${code}&state=${state}`,
        });
      } else {
        navigate("/home");
      }
    }
  }, [currentUser, navigate]);

  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            companyName: "",
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
            return (
              <form onSubmit={handleSubmit} style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center',
                zIndex: 2,
                //  width:'50%' 
              }}>
                <img
                  style={{
                    width: "200px",
                    margin: `0 auto ${SPACING.MD}px`,
                    display: "block",
                    cursor: 'pointer'
                  }}
                  src={logo}
                  alt="Upmarket Logo"
                  onClick={() => {
                    navigate("/");
                  }}
                />
                <h2 style={{ marginBottom: 25 }}>Create Account</h2>

                <FormWrapper>
                  <FieldWrapper>
                    <Input
                      type="text"
                      value={values.firstName}
                      name="firstName"
                      required
                      onChange={handleChange}
                      placeholder={"First Name"}
                    />
                    <RightInput
                      type="text"
                      value={values.lastName}
                      name="lastName"
                      required
                      onChange={handleChange}
                      placeholder={"Last Name"}
                      // style={{ height: '50px', width: '355px', marginTop: 5 }}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Input
                      type="text"
                      value={values.companyName}
                      name="companyName"
                      required
                      onChange={handleChange}
                      placeholder={"Company Name"}
                      // style={{ height: '50px', width: '355px', marginTop: 5 }}

                    />
                    <RightInput
                      type="email"
                      value={values.email}
                      name="email"
                      required
                      onChange={handleChange}
                      placeholder={"Email"}
                      // style={{ height: '50px', width: '355px', marginTop: 5 }}
                    />
                  </FieldWrapper>
                  <FieldWrapper>

                    <Input
                      type="password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      placeholder={"Password"}
                      error={errors?.password || false}
                      // style={{ height: '50px', width: '355px', marginTop: 5 }}

                      required
                    />
                    <RightInput
                      type="password"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      placeholder={"Confirm Password"}
                      required
                      error={
                        errors.confirmPassword
                          ? `${errors.confirmPassword}.`
                          : false
                      }
                      // style={{ height: '50px', width: '355px', marginTop: 5 }}

                    />
                  </FieldWrapper>

                </FormWrapper>
                <AuthButton color={'primary'} type="submit">Sign Up</AuthButton>
                <div className="mt-5">
                  <Link href="/" >Already a member? Log in</Link>
                </div>
              </form>
            );
          }}
        </Formik>
      )}

      {!isLoading && <Cubicles
        src={cubicles}
        alt="Upmarket cubicle"
      />}
    </Container>
  );
};

export default Register;
