import React, { useEffect, useState } from "react";

import ChartRS from "react-apexcharts";
import styled from "styled-components";
import Multiselect from 'multiselect-react-dropdown';

import {
  COLORS,
  COLOR_ARR,
  SPACING,
  TYPOGRAPHY,
} from "../../shared/constants";
import { CenterContainer, FlexContainer } from "../../shared/ui/Containers";
import { getListItemColor, randomNumber } from "../../shared/ui/Icon";
import { useAuth } from "../../contexts/AuthContext";

const colorRandom = () => COLOR_ARR[randomNumber(0, COLOR_ARR.length - 1)];
// const colorRandom1 = COLOR_ARR[randomNumber(0, COLOR_ARR.length - 1)];

const FlaskIcon = styled.svg`
  width: 220px;
  height: 230px;
  path#glass {
    fill: #fff;
    fill-opacity: 0.25;
  }
  path#edge {
    fill: #fff;
  }
  circle.bubble {
    // fill: #fff;
    // fill: ${COLOR_ARR[randomNumber(0, COLOR_ARR.length - 1)]};
    fill-opacity: .9;
  }
  path#liquid {
    // fill: #fff;
    fill: ${colorRandom()};
    fill-opacity: 0.85;
  }
  .bubble {
    fill:${colorRandom()};
    transition: all;
    transform-origin: 50% 50%;
    animation-name: bubble;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    opacity: 0;
  }
  .bubble-2 {
    fill:${colorRandom()};

    transition: all;
    transform-origin: 50% 50%;
    animation-name: bubble;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 0.3s;
    opacity: 0;
  }
  .bubble-3 {
    fill:${colorRandom()};

    transition: all;
    transform-origin: 50% 50%;
    animation-name: bubble;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-delay: 0.9s;
    opacity: 0;
  }
  .bubble-4 {
    fill:${colorRandom()};

    transition: all;
    transform-origin: 50% 50%;
    animation-name: bubble;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    opacity: 0;
  }
  .bubble-5 {
    fill:${colorRandom()};

    transition: all;
    transform-origin: 50% 50%;
    animation-name: bubble;
    animation-duration: 3.2s;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    opacity: 0;
  }
  .bubble-6 {
    fill:${COLOR_ARR[colorRandom()]};

    transition: all;
    transform-origin: 50% 50%;
    animation-name: bubble;
    animation-duration: 2.2s;
    animation-iteration-count: infinite;
    animation-delay: 0.1s;
    opacity: 0;
  }

  @keyframes bubble {
    50% {
      opacity: .8;
      // fill: ${colorRandom()}
    }
    100% {
      cy: 69;
    }
  }
`;

const FlaskLoader = () => {
  return (
    <FlaskIcon
      version="1.1"
      id="flask"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="700px"
      height="auto"
      viewBox="0 0 700 700"
      enableBackground="new 0 0 700 700"
      xmlSpace="preserve"
    >
      <defs>
        {/* <style type="text/css">
		<![CDATA[
			path#glass { fill: #fff; fill-opacity: 0.25; }
			path#edge { fill: #fff; }
			circle.bubble { fill: #fff; fill-opacity: 0.25; }
			path#liquid { fill: #fff; fill-opacity: 0.25; }
		]]>
		</style> */}
      </defs>
      <path
        id="glass"
        enableBackground="new"
        d="M398.5,361.836v-79.754c0,0,19.477-2.885,19.785-19.022
		c0.219-11.366-3.921-15.56-8.124-18.56H290.322c-4.252,3-8.536,7.193-8.318,18.56c0.31,16.137,19.496,19.022,19.496,19.022v79.754
		c0,0-63.982,145.678-89.516,203.298c-4.981,11.242,4.374,42.21,31.112,45.896c26.739,3.688,106.977,2.056,106.977,2.056
		s80.323,1.632,107.063-2.056c26.737-3.688,36.145-34.654,31.164-45.896C462.766,507.514,398.5,361.836,398.5,361.836z"
      />
      <path
        id="edge"
        d="M505.927,557.201c-9.42-21.259-24.625-55.438-39.33-88.487c-21.288-47.847-43.279-97.276-49.268-110.991
		v-62.136c8.585-4.892,19.919-14.641,20.254-32.084c0.462-24.101-12.608-33.405-18.345-36.316l-4.146-2.237H285.925l-4.361,2.156
		c-5.96,2.965-19.772,12.453-19.309,36.45c0.337,17.662,10.98,27.229,19.121,31.974v62.203
		c-10.776,24.759-64.641,147.964-87.466,199.472c-5.248,11.842-3.168,28.781,5.427,44.208c9.073,16.283,23.75,26.588,41.331,29.013
		c12.503,1.726,36.196,2.6,70.42,2.6c20.344,0,37.508-0.322,39.207-0.355c1.693,0.033,18.82,0.355,39.252,0.355
		c34.176,0,57.847-0.874,70.354-2.6c17.525-2.417,32.191-12.747,41.297-29.087C509.416,586.586,511.272,569.261,505.927,557.201z
		 M243.926,605.338c-12.832-1.771-19.711-10.943-22.67-16.254c-5.19-9.314-5.42-18.251-3.927-21.622
		c25.244-56.968,88.971-201.854,89.611-203.313l0.485-1.105v-80.961l-0.025-4.914l-4.85-0.767c-0.592-0.094-14.5-2.419-14.712-13.438
		c-0.122-6.318,1.253-10.578,4.089-12.69h116.605c2.773,2.111,4.118,6.371,3.996,12.69c-0.211,11.014-14.313,13.343-14.889,13.433
		l-4.907,0.724v85.927l0.488,1.108c0.644,1.456,64.575,146.341,89.818,203.306c1.485,3.354,1.246,12.262-3.938,21.563
		c-2.971,5.332-9.877,14.54-22.742,16.313c-11.203,1.544-34.378,2.36-67.02,2.36c-21.236,0-38.953-0.354-39.129-0.358l-0.116-0.002
		l-0.116,0.002c-0.177,0.004-17.874,0.358-39.086,0.358C278.288,607.698,255.131,606.882,243.926,605.338z"
      />
      <g id="bubbles-in">
        <circle
          className="bubble"
          enableBackground="new"
          cx="336"
          cy="428"
          r="25"
        />
        <circle
          className="bubble"
          enableBackground="new"
          cx="309"
          cy="485"
          r="8.5"
        />
        <circle
          className="bubble"
          enableBackground="new"
          cx="348"
          cy="525"
          r="13"
        />
        <circle
          className="bubble"
          enableBackground="new"
          cx="318"
          cy="554"
          r="8.5"
        />
        <circle
          className="bubble"
          enableBackground="new"
          cx="367"
          cy="383"
          r="10.5"
        />
      </g>
      <path
        id="liquid"
        enableBackground="new"
        d="M403.507,407.007c-21.874-4.141-38.817,17.733-58.061,28.59
		c-16.802,9.479-42.811,11.509-65.755,10.98c-19.308,43.841-41.927,95.158-54.725,124.037c-0.532,1.908-0.013,9.056,4.455,16.063
		c3.931,6.164,9.247,9.747,15.801,10.65c10.843,1.495,33.616,2.286,65.855,2.286c21.128,0,38.752-0.354,38.928-0.356l0.281-0.005
		l0.279,0.005c0.178,0.003,17.823,0.356,38.972,0.356c32.272,0,55.063-0.791,65.906-2.286c6.57-0.905,11.908-4.5,15.861-10.686
		c4.476-6.996,5.013-14.125,4.487-16.034C458.813,532.287,424.441,454.439,403.507,407.007z"
      />
      <g id="bubbles-above">
        <circle
          className="bubble-2"
          enableBackground="new"
          cx="365"
          cy="82"
          r="13"
        />
        <circle
          className="bubble-3"
          enableBackground="new"
          cx="327"
          cy="135"
          r="26"
        />
        <circle
          className="bubble-4"
          enableBackground="new"
          cx="386"
          cy="149"
          r="8.5"
        />
        <circle
          className="bubble-5"
          enableBackground="new"
          cx="365"
          cy="188"
          r="16.5"
        />
      </g>
    </FlaskIcon>
  );
};

const Section = styled.div`
  margin: 0 auto ${SPACING.SM}px;
  width: 100%;
`;

const Chart = styled(ChartRS)`
  opacity: ${(props) => (props.isLoading ? 0.3 : 1)};
  .apexcharts-menu {
    background: ${COLORS.greyDark};
  }
  .apexcharts-menu-item {
    &:hover {
      background: ${({appTheme}) => appTheme.colors.grey1};
    }
  }
  .apexcharts-theme-light .apexcharts-menu-item:hover {
    &:hover {
      background: ${({appTheme}) => appTheme.border.color};
    }
  }
  .apexcharts-text.apexcharts-xaxis-label {
    color: ${({appTheme}) =>appTheme.text.color};
    fill: ${({appTheme}) =>appTheme.text.color};
  }
  .apexcharts-text.apexcharts-yaxis-label {
    // font-size: ${TYPOGRAPHY.small}px;
  }
  .apexcharts-legend-text{
    color: ${({appTheme}) =>appTheme.text.color} !important;
    fill: ${({appTheme}) =>appTheme.text.color} !important;
  }
`;

const Container = styled.div`
  position: relative;
`;

const LoadingOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1;
  // background: rgba(0, 0, 0, 0.6);
  position: absolute;
  // padding: ${SPACING.LG}px;
  &:after {
    content: "";
    background: inherit;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.3);
    filter: blur(10px);
  }
`;

const SelectContainer = styled(Multiselect)`
  .search-wrapper{
    border: 0;
  }
  #search_input{
    padding: 4px 10px;
    background: ${COLORS.eastBay};
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 11px;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 19px;
    color: #fff;
    width: 50px;
    &::placeholder { 
      color: rgba(255,255,255,.75);
    }
    &:focus{
      width: auto;
    }
    ${props => props.hidePlaceholder && `display: none;`}

  }
`
function MeetingStats({ interaction, outerHeight,videoLength }) {

  const [meetingOptions, setMeetingOptions] = useState({});
  const [meetingSpokenTimes, setMeetingSpokenTimes] = useState([]);
  const [meetingPatiences, setMeetingPatiences] = useState([]);
  const [meetingMonologues, setMeetingMonologues] = useState([]);
  const [meetingWordCounts, setMeetingWordCounts] = useState([]);
  const [questionCounts, setQuestionCounts] = useState([]);
  const [wordsPerMinuteCounts, setWordsPerMinuteCounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [comparisons, setComparisons] = useState([])//if length 0 then summary view if not comparison view
  const [participants, setParticipants] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const { theme }= useAuth();

  const chartHeight = comparisons.length * 50;

  useEffect(() => {
    if (interaction && interaction?.statistics) {
      const initialComparisonsList = Object.keys(interaction?.statistics).map((participantId) => {
        const name = interaction.participants[participantId]?.user_name;
        return {
          id: participantId,
          name,
        };
      })

      setComparisons(initialComparisonsList)
      setParticipants(initialComparisonsList);
    }
  }, [])

  useEffect(() => {
    processStatistics(interaction)
  }, [interaction, comparisons]);

  const processStatistics = (interaction) => {
    let spokenTimes = [];
    let averagePatiences = [];
    let averageMonologues = [];
    let wordCounts = [];
    let questionCounts = [];
    let wordsPerMinuteCounts = [];
    let participantsNameList = []

    let colors = []
    if (interaction?.statistics) {
      let qCount = 0;

      Object.keys(interaction.statistics).forEach(
        (participantId,index) => {
          const stat = interaction.statistics[participantId];
          qCount += stat.question_count;

          let name = interaction.participants[participantId]?.user_name;
        
          if (!comparisons.length || comparisons.find(selectedParticipant => selectedParticipant.id === participantId)) {
            spokenTimes.push({
              name,
              data: [stat.spoken_time_s || 0]
            });
            wordCounts.push(stat.word_count || 0);
            averageMonologues.push(stat.average_monologue_s || 0);
            averagePatiences.push(stat.average_patience_s || 0);
            questionCounts.push(stat.question_count || 0);
            wordsPerMinuteCounts.push(stat.words_per_minute || 0);
            colors.push(getListItemColor(index))
            participantsNameList.push(name)
          }
        }
      );
      setQuestionCount(qCount);
      setMeetingOptions({
        chart: {
          type: "bar",
          height: 100,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
            barHeight: 80
          },
        },
        colors,
        grid: {
          show: false,
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            align: "left",
            minWidth: 160,
            maxWidth: 160,
            style: {
              colors: [theme.text.color ],
              fontSize: '12px',
            },
            offsetX: -45,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: participantsNameList,
        },
      });
      setMeetingSpokenTimes(spokenTimes);
      setMeetingMonologues([{ data: averageMonologues }]);
      setMeetingPatiences([{ data: averagePatiences }]);
      setMeetingWordCounts([{ data: wordCounts }]);
      setQuestionCounts([{ data: questionCounts }])
      setWordsPerMinuteCounts([{ data: wordsPerMinuteCounts }])
      setIsLoading(false);
    }
  }

  const showMeetingPatience = !!(meetingPatiences?.length && meetingPatiences[0]?.data?.find(dataElement => !!dataElement))
  const showMeetingMonologue = !!(meetingMonologues?.length && meetingMonologues[0]?.data?.find(dataElement => !!dataElement))
  const showMeetingWordCounts = !!(meetingWordCounts?.length && meetingWordCounts[0]?.data?.find(dataElement => !!dataElement))
  const showQuestionCounts = !!(questionCounts?.length && questionCounts[0]?.data?.find(dataElement => !!dataElement))
  const showWordsPerMinuteCounts = !!(wordsPerMinuteCounts?.length && wordsPerMinuteCounts[0]?.data?.find(dataElement => !!dataElement))

  //Average monologues and patience should be visible if the comparison in only between 2 people
  const renderAveragePatienceAverageMonologues = () => {
    if (comparisons.length === 2) {
      return (
        <>
          {showMeetingPatience && <Section>
            <h4>Average Patience</h4>
            <Chart
              isLoading={isLoading}
              theme={{ mode: "dark" }}
              options={meetingOptions}
              width={"100%"}
              height={chartHeight}
              series={meetingPatiences}
              type={"bar"}
              appTheme={theme}
            />
          </Section>}
          {showMeetingMonologue && <Section>
            <h4>Average Monologues</h4>
            <Chart
              theme={{ mode: "dark" }}
              isLoading={isLoading}
              options={meetingOptions}
              width={"100%"}
              height={chartHeight}
              series={meetingMonologues}
              type={"bar"}
              appTheme={theme}

            />
          </Section>}
        </>
      )
    }
  }

  const renderSpokenTimes = () => {
    const stackBarChartOptions = {
      ...meetingOptions,
      chart: {
        ...meetingOptions.chart,
        stacked: true,
        stackType: '100%'
      },
      xaxis: {
        categories: [''],
        labels: {
          formatter: function (val) {
            return ""
          }
        },
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },

        fill: {
          opacity: 1
        },
      },
      dataLabels: {
        enabled: true
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: false,
          borderRadius: 4,
        },
      },
      legend: {
        show: true,
        style: {
          color: '#FFFF',
          opacity: 1,
          fontSize: 30
        }
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
        axisBorder: {
          show: false
        },
        title: {
          text: undefined
        },
        axisTicks: {
          show: false
        },
      },
    }

    if ((comparisons.length > 1 || comparisons.length === 0) && meetingSpokenTimes) {
      return (
        <Section>
          <h4>Spoken Times</h4>
          <Chart
            options={stackBarChartOptions}
            series={meetingSpokenTimes}
            type="bar"
            theme={{ mode: "dark" }}
            height={150}
            isLoading={isLoading}
            width={"100%"}
            appTheme={theme}

          />
        </Section>
      )
    }
  }

  const onRemove = (selectedList, selectedItem) => {
    setComparisons(selectedList)
  }

  const onSelect = (selectedList, selectedItem) => {
    setComparisons(selectedList)
  }

  return (
    <Container >
      {interaction && interaction?.statistics && (
        <>
          <Section>
            <h4 style={{ marginRight: 20 }}>
              Outcome: Follow-up scheduled
            </h4>
            {/* <p>
              During this meeting, {questionCount} questions were asked
            </p> */}
            <SelectContainer
              options={participants}
              selectedValues={comparisons}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="name"
              placeholder="Add"
              hidePlaceholder={comparisons.length === participants.length}
              style={{
                chips: {
                  background: COLORS.eastBay
                },
                optionContainer: {
                  backgroundColor: COLORS.eastBay
                }
              }}
            />
          </Section>
          <div style={{ padding: SPACING.XS }}>
            {renderSpokenTimes()}
            {renderAveragePatienceAverageMonologues()}
            {showMeetingWordCounts && <Section>
              <h4>Word Count</h4>
              <Chart
                theme={{ mode: "dark" }}
                isLoading={isLoading}
                options={meetingOptions}
                width={"100%"}
                height={chartHeight}
                series={meetingWordCounts}
                type="bar"
                appTheme={theme}
              />
            </Section>}
            {showQuestionCounts && <Section>
              <h4>Question Count</h4>
              <Chart
                theme={{ mode: "dark" }}
                appTheme={theme}
                isLoading={isLoading}
                options={meetingOptions}
                width={"100%"}
                height={chartHeight}
                series={questionCounts}
                type={"bar"}

              />
            </Section>}
            {showWordsPerMinuteCounts && <Section>
              <h4>Words Per Minute Count</h4>
              <Chart
                theme={{ mode: "dark" }}
                isLoading={isLoading}
                options={meetingOptions}
                width={"100%"}
                height={chartHeight}
                series={wordsPerMinuteCounts}
                type={"bar"}
                appTheme={theme}

              />
            </Section>}
          </div>
        </>
      )}
      {!interaction?.statistics && videoLength &&<LoadingOverlay>
        <h2 style={{ textAlign: "center" }}>
          We're still analyzing this call, <br /> check back soon!
        </h2>
        <FlaskLoader />
      </LoadingOverlay>}
    </Container>
  );
}

export default MeetingStats;
