import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { InputForm } from "shared/ui/Inputs";
import { Modal, ModalBody } from "shared/ui/Modals";
import {Button} from "shared/ui/Buttons";
import {createMeetingBot} from "../../../redux/actions/meetings";
import { FlexContainer } from "shared/ui/Containers";
import { ERROR_CODES } from "shared/constants/index";

const AddMeetingBotModal = ({ isOpen, toggle, onJoinMeeting }) => {
  const [meetingBotForm, setMeetingBotForm] = useState({});
  const [error, setError] = useState({});
  const { claims } = useSelector((state) => state.member);
  const { info } = useSelector((state) => state.users);
  const [isLoading, setIsLoading] = useState(false)
  const companyId = claims.companyId;
  const uid = info?.uid;

  const handleOnJoinMeeting = async () => {
    setIsLoading(true)
    if (meetingBotForm.meetingUrl && meetingBotForm.botName) {
      try {
        await createMeetingBot(uid, companyId, meetingBotForm.meetingUrl, meetingBotForm.botName)
        toast("Notetaker added to meeting", {
          type: "success",
          hideProgressBar: true,
        });
      } catch (error) {
        if(error?.code === ERROR_CODES.TRIAL_RECORD_LIMIT.code) {
          toast(ERROR_CODES.TRIAL_RECORD_LIMIT.message, {
            type: "error",
            hideProgressBar: true,
          });
        }
      }
        toggle();
    } else if (!meetingBotForm.botName) {
        setError({botName: "Notetaker name Required!"})
    } else if (!meetingBotForm.meetingUrl) {
        setError({meetingUrl: "Meeting url Required!"})
    }
    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <h2>Join Meeting</h2>
        <div>
          <InputForm
            required
            type="text"
            placeholder="Notetaker name"
            onChange={(e) =>
              setMeetingBotForm({
                ...meetingBotForm,
                botName: e.target.value,
              })
            }
            value={meetingBotForm.botName}
          />
          <div>{error.botName}</div>
        </div>
        <div>
          <InputForm
            required
            type="text"
            placeholder="Meeting Url"
            onChange={(e) =>
              setMeetingBotForm({
                ...meetingBotForm,
                meetingUrl: e.target.value,
              })
            }
            value={meetingBotForm.meetingUrl}
          />
          <div>{error.meetingUrl}</div>
        </div>
        <FlexContainer style={{ justifyContent: "flex-end" }}>
          <Button onClick={handleOnJoinMeeting} isLoading={isLoading}>Join</Button>
        </FlexContainer>
      </ModalBody>
    </Modal>
  );
};

export default AddMeetingBotModal;
