import React, { useCallback, useState } from "react";
import debounce from "lodash.debounce";
import { InputForm } from "shared/ui/Inputs";

const MIN_SEARCH_TEXT_LENGTH = 0;

const SearchInput = ({onSearch}) => {
  const [searchText, setSearchText] = useState("");
  const debouncedChangeHandler = useCallback(
    debounce((text) => {
      if (text?.length >= MIN_SEARCH_TEXT_LENGTH) {
        onSearch(text);
      }
    }, 500),
    []
  );
  const handleTextChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedChangeHandler(text);
  };
  return <InputForm placeholder="Search" onChange={handleTextChange} value={searchText} />;
};

export default SearchInput;
