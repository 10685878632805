import styled from "styled-components";
import media from "shared/utils/media";
import { TYPOGRAPHY } from "../../shared/constants";
import { ToolTipItem } from "../../shared/ui/ToolTips";
import { MeetingDate } from "./MeetingDate";
import EditCallTitleModal from "./EditCallTitleModal";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const MeetingLabel = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${TYPOGRAPHY.standard}px;
`;

const MeetingTitle = ({ interaction, validateInteraction, title, isPrep, }) => {
  let { interactionId, accessCode } = useParams();
  const { info } = useSelector((state) => state.users);
  let { uid, companyId } = info || {};
  const [editCallTitleModalOpen, setEditCallTitleModalOpen] = useState(false);
  const toggleEditCallTitleModal = () => {
    setEditCallTitleModalOpen(!editCallTitleModalOpen);
  };
  let meetingLabel = interaction?.topic || title || "";
  return (
    <div style={{ width: "100%" }}>
      <div
        onClick={() => (!accessCode && toggleEditCallTitleModal())}
        style={{
          cursor: "pointer",
        }}
      >
        <ToolTipItem itemId={"meeting_title"} label={meetingLabel || "Add Topic"}>
          <MeetingLabel>{meetingLabel?.length > 0 ? meetingLabel : "Add topic"}</MeetingLabel>
        </ToolTipItem>
      </div>
      <EditCallTitleModal
        isOpen={editCallTitleModalOpen}
        interactionId={interactionId}
        companyId={companyId}
        toggle={toggleEditCallTitleModal}
        onSuccess={validateInteraction}
        interaction={interaction}
        isPrep={isPrep}
      />
      {interaction && (
        <MeetingDate
          live={!isPrep && interaction.status !== "ended"}
          start_time={interaction?.start_time || interaction?.lastModified}
          end_time={interaction?.end_time}
        />
      )}
    </div>
  );
};

export default MeetingTitle;
