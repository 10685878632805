import styled from "styled-components";
import { APP_LAYOUT, BORDER, COLORS, SPACING, TYPOGRAPHY } from "../constants";
import {Badge as BadgeRS} from 'reactstrap'
import { LoaderIcon } from "shared/ui/Icon";
export const ButtonStyles = (theme, type, value) => {
  const defaultStyles = {

    size : {
      sm: `${SPACING.XS}px ${SPACING.SM}px`,
      lg: `${SPACING.SM}px ${SPACING.MD}px`,
      default: `${SPACING.SM}px ${SPACING.MD}px` 
    },
    bg: {
      default: COLORS.eastBay,
      primary: COLORS.primary,
      light: COLORS.borderColor,
      danger: COLORS.red,
      invert: "transparent"
  
    },
    textColor: {
      default: COLORS.white,
      primary: COLORS.white,
      light: COLORS.white,
      danger: COLORS.white,
      invert: COLORS.white
    },
    textSize: {
      xs: TYPOGRAPHY.small,
      sm: TYPOGRAPHY.tiny,
      lg: TYPOGRAPHY.h3,
      default: TYPOGRAPHY.small
    },
    hover: {
      default:COLORS.azureRadiance,
      primary: COLORS.borderColor,
      light: COLORS.primary,
      danger: COLORS.greyDark,
      invert: COLORS.azureRadiance
  
    },
    hoverText: {
      default: COLORS.white,
      primary: COLORS.white,
      light: COLORS.white,
      danger: COLORS.white,
      invert: COLORS.white
    }
  
  }
  let buttonStyle = defaultStyles?.[type]?.[value] || defaultStyles?.[type]?.default
  if(theme?.type !== APP_LAYOUT.ZOOM){
    if(type && value && theme?.button?.[type]?.[value]){
      buttonStyle = theme?.button?.[type]?.[value]
    }else if( theme?.button?.[type]?.default){
      buttonStyle = theme?.button?.[type]?.default
    }
  }
  return buttonStyle
}



export const ButtonDefault = styled.button`
  padding: ${({theme, size}) => ButtonStyles(theme, "size", size) };
  box-shadow: none;
  line-height: .8;
  display: flex;
  justify-content:center;
  align-items:center;
  color: ${({color, theme}) =>  ButtonStyles(theme, 'textColor', color)};
  outline: 0;
  background: ${({theme, color, background}) => background || ButtonStyles(theme, 'bg', color) };
  ${({theme})  => theme?.button?.isBorder ? `border: 1px solid ${theme?.border?.color};` : `border: 0;`}
  border-radius: ${BORDER.radius_xs}px;
  font-size: ${({theme, size}) =>  ButtonStyles(theme, 'textSize', size)}px;
  transition: .15s all ease-out;
  white-space: normal;
  word-wrap: break-word;
  i{
    cursor: pointer;
    color: ${({color, theme}) =>  ButtonStyles(theme, 'textColor', color)};
  }
  ${({full}) => full && `width: 100%;`}
  &:hover, &:focus, &.active {
    ${({theme, color, hoverColor})  => theme?.button?.isBorder  && `border: 1px solid; border-color: ${ButtonStyles(theme, hoverColor ? "bg" : 'hover', hoverColor || color) };` }
    box-shadow: none;
    background: ${({theme, color, hoverColor}) => ButtonStyles(theme, hoverColor ? "bg" : 'hover', hoverColor || color) };
    outline:none; 
    color: ${({theme, color, hoverColor}) => ButtonStyles(theme, hoverColor ? "textColor" : 'hoverText', hoverColor || color) };
    i{
      cursor: pointer;
      color: ${({theme, color, hoverColor}) => ButtonStyles(theme, hoverColor ? "textColor" : 'hoverText', hoverColor || color) };
    }
  }

  &.active{
    &:hover{
      background-color: ${({theme, hoverColor})=> ButtonStyles(theme, 'hover', hoverColor) };
      ${({theme, hoverColor})  => theme?.button?.isBorder  && `border-color: ${ButtonStyles(theme, 'hover', hoverColor) };` }

    }
  }
  &:focus{
    border-color: ${(color, theme) => ButtonStyles(theme, 'hoverText', color) };
    ${({theme, color})  => theme?.button?.isBorder  && `border-color: ${ButtonStyles(theme, 'hover', color) };` }
  }

  &:disabled{
    opacity: .75;
  }
  ${({isLoading, theme, color}) => 
    isLoading &&
      `pointer-events: none;
      &:disabled{
        opacity: 1;
      }
      &:hover{
        background: ${ ButtonStyles(theme, 'bg', color) };
        ${theme?.button?.isBorder  && `border-color: ${ButtonStyles(theme, 'bg', color) };` }
      }
  `}
`;

export const Button = (props) => {
  return <ButtonDefault {...props} color={props.type === 'submit' ? 'primary' : props.color} disabled={props.isLoading || props.disabled}>
    {props.isLoading? <LoaderIcon /> : props.children}
  </ButtonDefault>
}

export const FullButton = styled(Button)` 
  background-color: ${({color, theme}) => color && theme?.colors?.[color]  ? theme.colors[color] : 'transparent' };
  border-radius: 0;
  align-items: center;
  align-content: center;
  padding: 20px;
  width: 100%;
  border: 0;
  text-align: left;
  justify-content: flex-start;
  font-weight: 400;
  &:hover,
  &.active {
    border: 0;
    background-color: #EAEEF3;
    color: ${COLORS.softBlack};
    i{
      color: ${COLORS.softBlack};
    }
  }
  &.active{
    color: ${COLORS.softBlack};
    i{
      color: ${COLORS.softBlack};
    }
    &:hover{
      border: 0;
      background-color: #EAEEF3;
      color: ${COLORS.softBlack};
      i{
        color: ${COLORS.softBlack};
      }
    }
  }

`

export const AuthButton = styled(Button)`
  padding: ${SPACING.SM}px ${SPACING.LG}px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
`;

export const Badge = styled(BadgeRS)`
  background: ${props => props.color === 'primary' ? COLORS.primary : COLORS.grey1} !important;
  color: ${props => props.color === 'primary' ? COLORS.white : COLORS.softBlack} !important;
`

export const OptionButton = styled.button`
padding: ${({theme}) => ButtonStyles(theme, 'size', null)} ;
box-shadow: none;
line-height: .8;
display: block;
color: ${COLORS.white};
outline: 0;
background: ${COLORS.eastBay};
border: 0;
border-radius: ${BORDER.radius_sm}px;
font-size: ${({size, theme}) => ButtonStyles(theme, 'textSize', size) }px;
transition: .25s all ease-out;
white-space: normal;
word-wrap: break-word;
&:hover, &:focus, &.active{
  box-shadow: none;
  background: ${COLORS.azureRadiance};
  outline:none; 
  color: ${({theme, color}) => ButtonStyles(theme, 'hoverText', color) };
  i{
    cursor: pointer;
  }
}

&.active{
  &:hover{
    background-color: ${({theme, hoverColor}) => ButtonStyles(theme, 'hover', hoverColor) };
  }
}
&:focus{
  border: ${({theme, color}) => ButtonStyles(theme, 'hoverText', color)} ;
}

&:disabled{
  opacity: .3;
}
`

export const RadioButtons = styled.div`
  display: flex;
  button {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: ${BORDER.radius_xs}px;
      border-bottom-left-radius: ${BORDER.radius_xs}px;

    }
    &:not(:first-child) {
      border-left: 0;

    }
    &:last-child {
      border-left: 0;
      border-top-right-radius: ${BORDER.radius_xs}px;
      border-bottom-right-radius: ${BORDER.radius_xs}px;
    }
  }

`