import React, { useState } from "react";
import { Col, Collapse, Row } from "reactstrap";

const MeetingCardCollapsible = ({ children, header }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div style={{width: "100%"}}>
      <div style={{display: 'flex', justifyContent: 'space-between', width: "100%"}}>
        <div style={{flexGrow: 1}} >
          {header}
        </div>
        {children && (
          <span
            className="body-tiny"
            style={{ cursor: "pointer", width: 40, paddingLeft: 8 }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Less" : "More"}
          </span>
        )}
      </div>
    
      <Collapse isOpen={showMore}>{children}</Collapse>
    </div>
  );
};

export default MeetingCardCollapsible;
