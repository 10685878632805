import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "../../shared/ui/Modals";
import Participant from "./Participant";

const ParticipantModal = ({ participants, isOpen, toggle, onParticipantChange }) => {
  return (
    <div>
      {/* <Modal isOpen={isOpen} toggle={() => toggle()} title={`Participants`}>
        <ModalBody> */}
      <h3>Participants</h3>
      <div className="flex" style={{ justifyContent: "flex-end", marginTop: 14 }}>
        {participants &&
          Object.keys(participants).map((val, index) => {
            const participant = participants[val];
            participant["keyId"] = val;
            return (
              <Participant
                key={val}
                toggle={toggle}
                participant={participant}
                onParticipantChange={onParticipantChange}
              />
            );
          })}
      </div>
      {/* </ModalBody>
      </Modal> */}
    </div>
  );
};

export default ParticipantModal;
