export const SCREEN_SIZES = {
  sm : 768,
  md: 1024,
  lg: 1280
}

export const APP_LAYOUT = {
  UPMARKET: "upmarket",
  ZOOM: 'zoom'
}

export const ONBOARDING_INPUT_TYPES = {
    SINGLE_CHOICE: 0,
    MULTIPLE_CHOICE: 1, 
    SLIDER: 2,
    FORM: 3
}

export const ERROR_CODES = {
  NO_RECORDINGS : {
    code: 3301
  },
  STORAGE_FULL: {
    code: 1,
    message: "Insufficient Storage!"
  },
  TRIAL_RECORD_LIMIT: {
    code: 4,
    message: "Trial Meeting Hours Exceeded"
  }
}

export const HOUR_IN_SECONDS = 3600;

export const TRIAL_TIME_LIMIT = 18000;

export const ONBOARDING_QUESTIONS = {
    GENERAL_INFO : {
        "role": {
            label: "What is your role?",
            options: ["Sales", "Founder", "Other"],
            type: ONBOARDING_INPUT_TYPES.SINGLE_CHOICE,
            next: "GENERAL_INFO.teamSize"
        },
        "teamSize": {
            label:  "How big is your team?",
            type: ONBOARDING_INPUT_TYPES.SLIDER,
            options: {
                min: 1,
                max: 10,
                step: 2
            },
            next: "GENERAL_INFO.focus"
        },
        "focus": {
            label: "What wins do you want to focus on?",
            options: ["Closing deals", "Setting meetings"],
            type: ONBOARDING_INPUT_TYPES.SINGLE_CHOICE,
            next: "GENERAL_INFO.crm"
        },
        "crm": {
            label: "What CRM do you use?",
            options: ["Salesforce","Pipedrive","Hubspot","Other"],
            type: ONBOARDING_INPUT_TYPES.SINGLE_CHOICE,
            next: "INVITE_TEAM.1"
        }
    },
    INVITE_TEAM: {
        "1": {
            label: "You’re all set! Next, invite your team so they can start connecting with prospects with you.",
            type: ONBOARDING_INPUT_TYPES.FORM,
            next: null
        }
    }
}

export const ONBOARDING_STEP = {
    GENERAL_INFO: "GENERAL_INFO",
    INVITE_TEAM: "INVITE_TEAM",
    COMPLETE: "COMPLETED"
}

export const TYPE_LIST = {
  CALLFLOWS: 'CallFlows',
  DISCOVERY_QUESTIONS: 'DiscoveryQuestions',
  SOCIALPROOFS: 'SocialProofs',
  TRIGGERS: 'Triggers',
  VOICEMAILS: 'Voicemails',
  VERSIONS: 'Versions',
  OBJECTIONS: 'Objections',
  BATTLESCARDS: 'BattleCards',
  FAQS: 'FAQ',
  DISPOSITIONS: 'Dispositions',
  EMAILS: 'Emails',
  SENTENCE: "sentence",
  STATS: "stats",
  RECORDING_STARTED: "RecordingStarted",
  RECORDING_STOPPED: "RecordingStopped"
}

export const TYPES_WITH_TOOLBAR = [
  "Scripts",
  TYPE_LIST.VOICEMAILS,
  TYPE_LIST.EMAILS,
  TYPE_LIST.TRIGGERS,
  TYPE_LIST.OBJECTIONS,
  TYPE_LIST.BATTLESCARDS,
  TYPE_LIST.SOCIALPROOFS

];

export const TYPES_WITH_VERSIONS = [
  TYPE_LIST.TRIGGERS,
  // TYPE_LIST.VOICEMAILS
]
export const SOCIAL_AUTH = {
  GOOGLE: "google",
  MICROSOFT: "microsoft",
  LINKEDIN: "linkedin"
}

export const TYPOGRAPHY = {
  tiny: 12,
  small: 14,
  standard: 16,
  h1: 36,
  h2: 24,
  h3: 20,
  font: `'Lato', Helvetica, Arial, san-serif`
}


export const COLOR_ARR = [
  "#31CDDE",
  "#31AFDE",
  "#3191DE",
  "#3174DE",
  "#3156DE",
  "#3138DE",
  "#4731DE",
  "#6431DE",
  "#8231DE",
  "#A031DE",
  "#BE31DE",
  "#DB31DE",
  "#DE31C3",
  "#DE31A5",
  "#DE3187",
  "#31DE95",
  "#4631DE",
  "#3174DE",
  "#318BDE",
  "#3170DE",
  "#8231DE",
  "#A031DE",
  "#5631DE",
  "#A23188",
  "#DEAA31",
  "#DE3331",
  "#9BDE31"
]

export const COLORS = {
    // primary: "#215AC2",
    primary: "#A22EF7",
    secondary: "#34B9C2",
    danger: '#C2343D',
    softBlack: "#161616",
    white: "#F8F8FA",
    sidebar: "#1E468F",
    darker: '#1E468F',
    offWhite: '#F4F6FA',
    grey: '#F4F5FD',
    lightBlue: "#5a9bff",
    greyBlue: "#8C9DB9",
    greyDark: "#2D3237",
    grey1: "#c0c1c3",
    red: '#C2343D',
    borderColor: "#5f6369",
    black:'#000000',
    gray:"grey",
    brilliantWhite:"white",
    mulledWine:"#483D63",
    mulledArrack:"#473C62",
    eastBay:'#415683',
    azureRadiance:'#0485F4',
    brilliantAzure:'#349FFD',
    greenBlue:'#1B81B9',
    manatee:'#9D9FA3',
    waikawaGray:'#4D669B',
    transparent:'transparent',
    sanMarino:'#47669F',
    chambray:'#3D5996',
    quickSilver:'#979797',
    lightPurple: "rgb(168, 150, 225)",
    purple: "#5c5cff",

  }
export const APP_THEME = {
  [APP_LAYOUT.ZOOM] : {
    type: APP_LAYOUT.ZOOM,
    colors: {
      primary: "#3D5996 ",
      secondary: "#A22EF7",
      danger: '#C2343D',
      softBlack: "#161616",
      white: "#FFFFFF",
      backgroundColor: "#161616",
      grey1: COLORS.grey1,
      active: COLORS.azureRadiance,
      secondaryBackground: "transparent",
      success: "#64BD76",
      boxShadowColor: 'rgba(255,255,255,.45)'

    },
    dropdown: {
      background: COLORS.chambray,
      color: COLORS.white
    },
    modal: {
      background: COLORS.mulledWine,
      color: COLORS.white
    },
    border: {
      color: COLORS.white
    },
    text: {
      color: COLORS.white,
      icon: COLORS.white,
      grey: COLORS.grey1,
    },
    card: {
      background:  COLORS.waikawaGray, // cards to click
      active: COLORS.azureRadiance,
      color: COLORS.white,
      contentBackground: COLORS.sanMarino, // cards for reading
      secondaryBackground: COLORS.mulledWine // modals
    },
  },
  [APP_LAYOUT.UPMARKET] : {
    type: APP_LAYOUT.UPMARKET,
    colors: {
      primary: "#5CAEFF",
      secondary: "#5c5cff",
      danger: '#C2343D',
      softBlack: "#161616",
      dark: "#051e34",
      white: "#FFFFFF",
      backgroundColor: "#F3F5F9",
      grey1:  "#919699",
      // active: "#6290E4",
      active: "#5CAEFF",
      success: "#64CDAE",
      secondaryBackground: "#fff",
      boxShadowColor: 'rgba(0,0,0,.25)'

    },
    border: {
      color: "rgba(8, 10, 57, 0.13)"
    },
    modal: {
      background: COLORS.white,
      color: COLORS.softBlack
    },
    card: {
      background:  "#fff",
      active: "#6290E4",
      color: COLORS.softBlack,
      contentBackground: "#fff",
      secondaryBackground: "#190933"
    },
    dropdown: {
      background: "#fff",
      color: COLORS.softBlack
    }
    ,
    text: {
      color: COLORS.softBlack,
      icon: COLORS.white,
      grey: "#919699"
    },
    button: {
      isBorder: true,
      bg: {
        default: "transparent",
        secondary:  "#5c5cff",
        // secondary: "#0485F4",
        primary: "#6290E4",
        light: "#e2e2e2",
        // danger: "#fc5130"
        danger:"#C2343D",
        success: "#64CDAE"
      },
      textColor: {
        default: COLORS.softBlack,
        primary: COLORS.white,
        secondary: COLORS.white,
        light: COLORS.softBlack,
        danger: COLORS.white,
        success: COLORS.white
      },
      hover: {
        default:"#051e34",
        primary: "#051E34",
        secondary: "#051E34",
        light: "#F3F5F9",
        danger: COLORS.greyDark,
        success: COLORS.greyDark

      },
      hoverText: {
        default: COLORS.white,
        primary: COLORS.white,
        secondary: COLORS.white,
        light: COLORS.softBlack,
        danger: COLORS.white,
        success: "#F3F5F9",

      }
    }
  }

}

export const SPACING = {
  XXS: 4,
  XS: 8,
  SM: 14,
  MD: 20,
  LG: 25,
  XLG: 35,
}

export const BORDER = {
  radius_xs: 3,
  radius_sm: 5,
  radius_lg: 10,
  // color: '#595959',
  color: COLORS.borderColor
}


export const CONTENT_TYPE = {
    Triggers: {
      icon: <div className="text-icon Triggers-highlight">---</div>,
      content: false,
      controlLabel: 'Meeting Plan',
      controlLabelPlural: 'Meeting Plans',
      var: '${Triggers}',
      placeholder: '<span id="Triggers" class=" Triggers-highlight"> Trigger </span>',
      isUpdated: true,
      max: 1,
      useTmpId: true,
      hasMeta: true,
      hasPermissions: true,
    },
    SocialProofs: {
      icon: <div className="text-icon SocialProofs-highlight" >---</div>,
      content: false,
      controlLabel: 'Customer Story',
      controlLabelPlural: 'Customer Stories',
      placeholder: '<span id="SocialProofs" class="SocialProofs-highlight"> Social Proof </span>',
      var: '${SocialProofs}',
      label: '',
      isUpdated: true,
      max: 1,
      useTmpId: true,
      hasMeta: true,
      hasPermissions: true,
    },
    COMPANYNAME: {
      icon: <div className="text-icon COMPANYNAME-highlight">---</div>,
      controlLabel: 'Company Name',
      isRecordVar: true
    },
    contactName:{
      icon: <div className="text-icon contactName-highlight">---</div>,
      controlLabel: 'Contact Name',
      isRecordVar: true
  
    },
    contactTitle:{
      icon: <div className="text-icon contactName-highlight">---</div>,
      controlLabel: 'Title',
      isRecordVar: true
  
    },
    firstName:{
      icon: <div className="text-icon contactName-highlight">---</div>,
      controlLabel: 'First Name',
      isRecordVar: true
  
    },
    lastName:{
      icon: <div className="text-icon contactName-highlight">---</div>,
      controlLabel: 'Last Name',
      isRecordVar: true
  
    },
    email:{
      icon: <div className="text-icon contactName-highlight">---</div>,
      controlLabel: 'Email',
      isRecordVar: true
    },
    myName:{
      icon: <div className="text-icon myName-highlight">---</div>,
      controlLabel: 'My Name',
    },
    plainText:{
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabel: 'Plain Text',
      hasMeta: false,
      hasPermissions: true,
    },
    Scripts: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      // controlLabel: 'Script',
      hasMeta: true,
      hasPermissions: true,
    },
    CallFlows: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabel: 'Playbook',
      controlLabelPlural: 'Playbooks',
      hasMeta: true,
      hasPermissions: true,
    },
    BattleCards: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabel: 'Battle Card',
      controlLabelPlural: 'Battle Cards',
      hasMeta: true,
      hasPermissions: true,
    },
    //for andros
    FAQ: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabel: 'FAQ',
      controlLabelPlural: 'FAQs',
      hasMeta: true,
      hasPermissions: true,
    },
    //for andros
    Outcomes: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabel: 'Outcome',
      controlLabelPlural: 'Outcomes',
      hasMeta: true,
      hasPermissions: true,
    },
    Dispositions: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabel: 'Outcome',
      controlLabelPlural: 'Outcomes',
      hasMeta: true,
      hasPermissions: true,
    },
    CTAs: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      // controlLabel: 'Call To Action',
      hasMeta: true,
      hasPermissions: true,
    },
    Objections: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabel: 'Objection Response',
      controlLabelPlural: 'Objection Responses',
      hasMeta: true,
      hasPermissions: true,
      colors: [
          '#7dd3b6',
          '#6d33f4',
          '#f86969',
          '#c9f46a',
          '#84d7f1'
      ]
    },
    DiscoveryQuestions: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabel: 'Discovery Question',
      controlLabelPlural: 'Discovery Questions',
      className: 'discoverQuestion',
      editorLabel: "Question",
      hasMeta: true,
      hasPermissions: true,
      optionLabel: "Add Option",
      colors: [
        COLORS.primary,
        COLORS.secondary,
        COLORS.danger,
        COLORS.darker
    ]
    },
    TriggerResponses: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      hasMeta: true,
      hasPermissions: true,
    },
    Resources: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      hasMeta: true,
      hasPermissions: true,
    },
    Voicemails: {
      controlLabel: 'Voicemail',
      controlLabelPlural: 'Voicemails',
      hasMeta: true,
      hasPermissions: true,
    },
    signature: {
      controlLabel: 'Signature',
      hasMeta: true,
      hasPermissions: true,
      isRecordVar: true
  
    },
    hyperlink: {
      controlLabel: 'Link',
      hasMeta: true,
      hasPermissions: true,
    },
    [TYPE_LIST.EMAILS]: {
      icon: <div className="text-icon plainText-highlight">---</div>,
      controlLabelPlural: 'Emails',
      controlLabel: 'Email',
      hasMeta: true,
      hasPermissions: true,
    }
  }

export const BASE_RUNNING_URL = "http://localhost:3000/";

export const FIREBASE_ERRORS = {
  "auth/too-many-requests": "Too many failed attempts: try again later or reset password.",
  "auth/wrong-password": "Incorrect password! please try again",
  "auth/requires-recent-login": "Password update timeout. To reset password: Logout and click forgot password",
  "functions/internal": "Not found",
}


export const FILE_TYPES = {
  AUDIO: "M4A",
  VIDEO: "MP4",
  TIMELINE: "TIMELINE",
  TRANSCRIPT: "TRANSCRIPT"
}

export const CONNECTED_SERVICES = {
  SALESFORCE: "salesforce"
}

export const CRM = {
  SALESFORCE: "salesforce", 
  CORE: "core"
}


export const ENV = {
  DEV: 0,
  TEST: 1,
  PROD: 2,
  TEST_NEW: 3,
  PROD_NEW: 4
}

export const RUNNING_ENV = window.location.hostname === `zoom.new.upmarket.ai` ? ENV.PROD_NEW : ENV.PROD;
//export const RUNNING_ENV = ENV.DEV // for local testing: using localhost:8080
export const TRUSTED_LINKED_REDIRECT = ENV.DEV === RUNNING_ENV ? `http://localhost:8080` : "https://linkedin-service-dot-accountheroes.appspot.com";

const getURL = () => {
  switch (RUNNING_ENV){
    case ENV.DEV:
      return `http://localhost:8080`;
    case ENV.TEST:
      return `https://dev-zoom-service-dot-accountheroes.appspot.com`
    case ENV.PROD:
      return `https://zoom-service-dot-accountheroes.appspot.com`;
    case ENV.TEST_NEW:
      return `https://zoom-new-service-dev-dot-accountheroes.appspot.com`;
    case ENV.PROD_NEW:
      return `https://zoom-new-service-dot-accountheroes.appspot.com`
    default: 
      return `https://zoom-service-dot-accountheroes.appspot.com`;
  }
}
export const BASE_URL = getURL()
  
export const UPMARKET_RUNNING_ENV = window.location.hostname === `app.upmarket.ai` ? ENV.PROD : window.location.hostname === `test.upmarket.ai` ? ENV.TEST : ENV.PROD;

const getMeetingsIframeURL = () => {
  switch (UPMARKET_RUNNING_ENV){
    case ENV.DEV:
      return `http://localhost:3000`;
    case ENV.TEST:
      return `https://test.zoom.upmarket.ai`
    case ENV.PROD:
      return `https://zoom.upmarket.ai`;
    default: 
      return `https://zoom.upmarket.ai`;
      // return `http://localhost:3000`;

  }
}

export const MEETINGS_IFRAME_URL = getMeetingsIframeURL()

// ENV.DEV === RUNNING_ENV ? 
// `http://localhost:8080`: ENV.TEST === RUNNING_ENV ? `https://dev-zoom-service-dot-accountheroes.appspot.com` : `https://zoom-service-dot-accountheroes.appspot.com`;

// "http://localhost:8080"
export const REPORT_SERVICE_BASE_URL =  "https://report-service-dot-accountheroes.appspot.com"