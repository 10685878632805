import { useEffect, useState } from "react";
import { COLORS } from "shared/constants";
import { Button } from "../../shared/ui/Buttons";
import { Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { Spinner } from "reactstrap";
import { useAuth } from "../../contexts/AuthContext";
import styled from "styled-components";

const PlayBtn = styled(Button)`
  height: 80px;
  width: 80px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme?.button?.bg?.secondary || COLORS.quickSilver};
  color: ${({theme}) => theme?.button?.textColor?.secondary || COLORS.white};

  &:hover, &.active {
    background-color: ${({ theme }) => theme?.button?.hover?.primary};
  }
`
const Stopwatch = ({
  onTimeChange,
  running,
  onStart,
  onStop,
  time,
  totalDuration,
  setTime,
  loading,
}) => {
  const { theme } = useAuth();
  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [running]);

  useEffect(() => {
    if (time <= totalDuration) {
      onTimeChange(time);
    } else {
      onStop();
    }
  }, [time]);

  return (
    <div>
      {loading ? (
        <Spinner type="border" color={"primary"} />
      ) : running ? (
        <PlayBtn onClick={onStop}>
          <Icon type={ICON_TYPES.pause} style={{ fontSize: 30 }} />
        </PlayBtn>
      ) : (
        <PlayBtn onClick={onStart} >
          <Icon type={ICON_TYPES.play} style={{ fontSize: 30 }} />
        </PlayBtn>
      )}
    </div>
  );
};

export default Stopwatch;
