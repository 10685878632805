import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
// import { motion } from "framer-motion";

import {
  BORDER,
  COLORS,
  CONTENT_TYPE,
  SPACING,
  TYPE_LIST,
  TYPOGRAPHY,
} from "../../shared/constants";
import { getOrCreateCustomer } from "../../redux/actions/stripe";
import { Badge } from "../../shared/ui/Buttons";
import { FlexContainer } from "../../shared/ui/Containers";
import { Icon, ICON_TYPES, Avatar } from "../../shared/ui/Icon";
import { Input } from "../../shared/ui/Inputs";
import { PopOver } from "../../shared/ui/Modals";
import { ToolTipItem } from "../../shared/ui/ToolTips";
import { getCustomTokenClaim } from "../../redux/actions/users";

const MenuItem = styled.div`
  display: block;
  font-size: ${TYPOGRAPHY.standard}px;
  border-radius: ${BORDER.radius_sm}px;
  margin-bottom: ${SPACING.XS}px;
  cursor: default;
  &:first-child {
    margin-bottom: ${SPACING.SM}px;
    cursor: pointer;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
`;

const MenuLabel = styled.p`
  font-weight: 600;
  margin-bottom: ${SPACING.XXS}px;
`;

const MenuText = styled.div`
  font-size: ${TYPOGRAPHY.small}px;
  padding: ${SPACING.XXS}px ${SPACING.XS}px;
  cursor: pointer;
  border-radius: ${BORDER.radius_xs}px;
  transitions: 0.25s all ease;
  &:hover,
  &:focus {
    background: ${COLORS.transparent};
    outline-width:1;
    outline-style:solid;
    outline-color:${COLORS.white}
  }
`;

const Toggle = styled(DropdownToggle)`
  background: transparent;  
  padding: ${SPACING.XXS}px ${SPACING.XS}px;
  border: 0;
  &:hover,
  &:focus,
  &:active {
    background-color:${COLORS.sanMarino};
    outline: 1; 
    outline-style: solid; 
    box-shadow: none !important;
    border: 0;
  }
`
const FlowDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [flowsArr, setFlowsArr] = useState([]);

  const dispatch = useDispatch();
  const Flows = useSelector(
    (state) => state?.content?.company?.[TYPE_LIST.CALLFLOWS] || {}
  );
  const member = useSelector(
    (state) => state?.member?.claims || false
  );

  const companyId = member?.companyId

  const activeFlowId = useSelector(
    (state) =>
      state?.content.zoom?.id ||
      (Flows["-Initial_" + companyId] ? "-Initial_" + companyId : false)
  );

  const onSetFlow = (id) => {
    dispatch({
      type: "ZOOM_CONTENT",
      data: Flows[id],
    });
    handleChange({ target: { value: "" } })
  };

  useEffect(() => {
    const flowsTemp = Object.keys(Flows).filter(
      (id) =>
        Flows[id]?.active && Flows[id]?.deleted == null && id !== activeFlowId
    );
    setFlowsArr(flowsTemp);

  }, []);

  const handleChange = (e) => {
    setSearch(e.target.value);
    const flowsTemp = Object.keys(Flows).filter(
      (id) =>
        Flows[id]?.active &&
        Flows[id]?.deleted == null &&
        id !== activeFlowId &&
        Flows[id]?.label &&
        Flows[id]?.label?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFlowsArr(flowsTemp);
  };

  const activeLabel = Flows?.[activeFlowId]?.label?.length > 27 ? Flows?.[activeFlowId]?.label?.slice(0, 24) + "..." : Flows?.[activeFlowId]?.label || (`Select ${CONTENT_TYPE[TYPE_LIST.CALLFLOWS].controlLabel}`)

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <ToolTipItem showToolTip={Flows?.[activeFlowId]?.label?.length > 27} direction={"top"} itemId="select_flow" label={Flows?.[activeFlowId]?.label || `Select ${CONTENT_TYPE[TYPE_LIST.CALLFLOWS].controlLabel}`} >
        <Toggle caret>
          <span style={{ fontSize: TYPOGRAPHY.small }}>
            {activeLabel}
          </span>
        </Toggle>
      </ToolTipItem>
      <DropdownMenu>
        <Input
          placeholder="Search"
          value={search}
          onChange={handleChange}
          style={{
            margin: "0 auto 4px",
            padding: "8px 14px",
            width: `calc(100% - 28px)`,
            background: COLORS.grey,
            color: COLORS.softBlack,
            border: 0,
          }}
        />
        <div style={{ maxHeight: 200, overflow: "scroll" }}>
          {flowsArr.map((id) => (
            <DropdownItem key={"flow_menu_" + id}
              onClick={() => onSetFlow(id)}
              style={{ fontSize: TYPOGRAPHY.small }}
            >
              {Flows[id]?.label}
            </DropdownItem>
          ))}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};


const HeaderMenu = (props) => {
  const { info, handleLogout, disable, toggleInviteModal
  } = props;
  const [customerId, setCustomerId] = useState("");

  const member = useSelector(
    (state) => state?.member?.claims || false
  );
  const email = member?.email;


  const getStripeCustomer = async () => {
    if (email) {
      // const customer = await getOrCreateCustomer(email);
      // setCustomerId(customer?.id);
    }
  }

  const handleNavStripePortal = () => {
    const form = document.getElementById("portal-form");
    form.submit();
  }
  const handleSubscription = async () => {
    const customToken = await getCustomTokenClaim(null);
    let pathname = "https://test.upmarket.ai"
    if (window.location.hostname?.includes("localhost")) {
      pathname = "https://localhost:3001"
    }
    window.open(
      `${pathname}/loading?customToken=${customToken}&returnUrl=settings#settings?view=Manage`
    );
  }

  const handleKB = () => {
    window.open(
      `https://upmarket.ai/docs/`
    );
  }

  useEffect(() => {
    getStripeCustomer();
  }, []);



  return (
    <div>
      <div id={disable ? '' : "header_settings"} style={{ cursor: "pointer" }}>
        <Avatar name={`${info?.firstName} ${info?.lastName}`} round size="30" />
      </div>

      <PopOver target="header_settings"
        cardStyle={{
          backgroundColor: COLORS.chambray
        }}>
        <MenuItem>
          <FlexContainer style={{ width: 210, alignItems: "center" }}>
            <Avatar
              name={`${info?.firstName} ${info?.lastName}`}
              round
              size="25"
              style={{ marginRight: SPACING.XS + 2 }}
            />
            <FlexContainer style={{ flexDirection: "column" }}>
              <span>{`${info?.firstName} ${info?.lastName}`}</span>
              <ToolTipItem itemId="user_email" label={info?.email}>
                <span className="body-tiny-grey">{`${info?.email?.length > 19 ? info?.email.slice(0, 16) + "..." : info?.email}`}</span>
              </ToolTipItem>
            </FlexContainer>
            {/* <form
              id="portal-form"
              target="_blank"
              method="POST"
              action={`https://app.upmarket.ai/${info?.companyId}/settings#settings?view=Manage`}
            > */}
              <input type="hidden" name="customerId" value={customerId} />
              <Badge style={{ marginLeft: SPACING.XS }} color="primary" >
                <Icon
                  type={ICON_TYPES.crown}
                  style={{ fontSize: TYPOGRAPHY.standard }}
                  // onClick={handleNavStripePortal}
                />
              </Badge>
            {/* </form> */}
          </FlexContainer>
        </MenuItem>

        <MenuItem>
          <MenuLabel>{CONTENT_TYPE[TYPE_LIST.CALLFLOWS].controlLabel}</MenuLabel>
          <FlowDropdown />
        </MenuItem>
   
        {member?.role === "Admin" && <MenuItem>
          <FlexContainer
            style={{ flexDirection: "column" }}
          >
            <MenuLabel>Settings</MenuLabel>
            <MenuText onClick={toggleInviteModal}>Invite Team </MenuText>
            <MenuText onClick={handleSubscription}>Manage Subscription </MenuText>
          </FlexContainer>
        </MenuItem>}
        <MenuItem>
          <FlexContainer style={{ flexDirection: "column" }}>
            <MenuLabel>Help</MenuLabel>
            <MenuText onClick={handleKB}>Knowledge Base </MenuText>
          </FlexContainer>
        </MenuItem>
        <hr style={{ margin: `${SPACING.XXS}px - ${SPACING.SM}px` }} />
        <MenuItem
          onClick={handleLogout}
          style={{
            marginBottom: -SPACING.XS,
            cursor: "pointer",
            padding: `${SPACING.XXS - 2}px 0`,
          }}
        >
          <MenuLabel style={{ margin: 0 }}>Logout</MenuLabel>
        </MenuItem>
      </PopOver>
      {/* </motion.div> */}
    </div>
  );
};

export default (HeaderMenu);
