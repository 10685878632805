import { useState } from "react";
import styled from "styled-components";
import { FieldType, SearchDropDown } from "./library";
import { SUB_FIELDS_TYPES } from "../constants/recordTypes";
import { Button } from "shared/ui";
import { InputForm } from "./Inputs";
import { BORDER } from "../constants";
import { FlexContainer } from "./Containers";

export const SPACING = {
  XXS: 4,
  XS: 8,
  SM: 14,
  MD: 20,
  LG: 25,
  XLG: 35,
}

export const COLORS = {
  primary: "#215AC2",
  secondary: "#34B9C2",
  danger: '#C2343D',
  softBlack: "#161616",
  white: "#F8F8FA",
  sidebar: "#1E468F",
  darker: '#1E468F',
  offWhite: '#F4F6FA',
  grey: '#F4F5FD',
  lightBlue: "#5a9bff",
  greyBlue: "#8C9DB9"
}



const SubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${SPACING.SM}px;
  .subfield-SubContainer {
    width: calc(50% - ${SPACING.SM}px);
    margin-bottom: ${SPACING.SM}px;
  }
`;

const Container = styled.div`
  transition: .3s;
  box-shadow: ${ (props) => props.isOpen ? `0 1px 15px -7px #9ec5ff` : 'none'};
  border: ${(props) => props.isOpen ?  `solid 1px ${BORDER.color}`: 0};
  border-radius: ${(props) => props.isOpen ? BORDER.radius_lg + "px": 0};

  padding: ${(props) => props.isOpen ? '8px': 0};
`;

export const SubFieldForm = ({
  label,
  values,
  name,
  field,
  handleChange,
  primaryValue,
  required,
  modalId,
  primaryField,
  type,
  onClick,
  style
}) => {
  const [isOpen, toggle] = useState(false);
  return (
    <Container isOpen={isOpen}>
      <FlexContainer className="flex" style={{ alignItems: "center" }}>
        {label}
        <Button 
          size={'sm'}
          style ={{marginLeft: 8, marginBottom: 8}} 
          onClick={() => toggle(!isOpen)} 
        >
            {isOpen ? "Close" :"More" }
        </Button>
      </FlexContainer>
      <InputForm
        style={style}
        placeholder={field?.label}
        name={name + "_" + primaryField}
        value={primaryValue || ""}
        required={required}
        onChange={handleChange}
      />
 
      {isOpen && (
        <SubContainer>
          {Object.keys(SUB_FIELDS_TYPES[type])
            .filter((subField) => subField != primaryField)
            .sort((a,b) => (SUB_FIELDS_TYPES[type][a].label < SUB_FIELDS_TYPES[type][b].label) ? -1 : (SUB_FIELDS_TYPES[type][a].label > SUB_FIELDS_TYPES[type][b].label) ? 1 : 0 )
            .map((subField) => {
              const subFieldData =
                SUB_FIELDS_TYPES[type][subField];
              if (subField == "type") {
                return (
                  <div key={name +"_"+ subField+"_"+type} className="subfield-SubContainer">
                    <p className="body-small">{subFieldData.label}</p>
                    <SearchDropDown
                      style={style}
                      inputStyle={style}
                      defaultValue="Type"
                      targetId={modalId}
                      selectedValue={
                        values?.type
                          ? { label: values?.type, id: values?.type }
                          : false
                      }
                      list={subFieldData.values.map((t) => ({ id: t, label: t }))}
                      onSelect={(g) =>
                        handleChange({
                          target: { name: name + "_type" + "_" + type, value: g.id },
                        })
                      }
                    />
                  </div>
                );
              } else {
                return (
                  <div key={name +"_"+ subField} className="subfield-SubContainer">
                    <p className="body-small">{subFieldData.label}</p>
                    <FieldType
                      style={style} 
                      field={subFieldData} 
                      value={values?.[subField]} 
                      name={name + "_" + subField+"_"+ type} 
                      handleChange={handleChange} 
                      onClick={onClick}
                      data={{modalId}}
                      hideLabel={true}
                      />
                  </div>
                );
              }
            })}
        </SubContainer>
      )}
    </Container>
  );
};

// export default SubFieldForm;
