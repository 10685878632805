import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { Card } from "shared/ui/Cards";
import { Button, Icon } from "shared/ui";
import { ControlledTooltip, ToolTipItem } from "shared/ui/ToolTips";
import {
  APP_LAYOUT,
  BORDER,
  COLORS,
  CONTENT_TYPE,
  SPACING,
  TYPOGRAPHY,
} from "../../shared/constants";
import TableChartModal from "./TableChartModal";
import { hideTeamFeedType } from "../../redux/actions/content";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../redux/actions/meetings";
// import { COLORS } from "shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import DateFilter from "shared/ui/DateFilter";
import { FlexContainer } from "shared/ui/Containers";
import featuredCardImg from "assets/dashboard/featuredCard.png";

const HorizontalScrollView = styled.div`
  overflow: scroll;
  position: relative;
  display: flex;
  // ${({ isFullHeight }) =>
    isFullHeight ? "height: 165px;" : "height: 165px;"}
  height: ${({ isEmpty }) => isEmpty ? 260 : 200}px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.type === APP_LAYOUT.ZOOM ? COLORS.greyDark : theme.colors.grey1};
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const Content = styled.div`
  position: absolute;
  display: flex;
`;

const StatisticCard = styled(Card)`
  ${({ data, theme, bg, isLoading, isDefault }) =>
    `
      ${
       ( !isLoading )
          ? `
        background-image: radial-gradient(#444cf7 1px, #e5e5f7 1px);
        background-size: 12px 12px;
      `
          : ``
      }
     
      ${data ? `cursor: pointer;` : ``}
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      min-height: 165px;
      width: 270px;
      border-radius: 5px;
      margin-top: 0px;
      padding: 0;
      color: ${COLORS.softBlack};
      // background-color: ${bg || theme?.card?.active || COLORS.primary};
      border-radius: ${BORDER.radius_lg}px;
      ${
        theme?.type !== APP_LAYOUT.ZOOM || isDefault
          ? `box-shadow: 0px 1px 7px ${theme?.colors?.boxShadowColor}`
          : ``
      };
      position: relative;
    `}
`;

const StatisticInner = styled.div`
  margin-top: auto;
  padding: ${SPACING.MD}px ${SPACING.SM}px ${SPACING.XS}px;
  border-radius: ${BORDER.radius_lg}px;
  height: 135px;
  width: 100%;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StatIcon = styled.div`
  position: absolute;
  background-color: #333;
  left: ${SPACING.SM}px;
  top: -22px;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 100%;
  background: ${({ theme, color }) => color};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
`;

const IconHide = styled.div`
  width: 20px;
  i {
    color: ${({ theme }) => theme.colors.grey1};
    margin: 0 auto;
    display: block;
  }
  .feature-unhide {
    display: block;
  }
  .feature-hide {
    display: none;
  }
  &:hover {
    .feature-unhide {
      display: none;
    }
    .feature-hide {
      display: block;
    }
  }
`;

const Tag = styled.div`
  ${({ tagTheme }) => `
    padding: 2px 4px;
    margin-right: 4px;
    background: ${tagTheme?.color};
    font-size: ${TYPOGRAPHY.tiny}px;
    color: ${tagTheme?.textColor};
    border-radius: 4px;
    border: 1px solid ${tagTheme?.textColor};
  `}
`;

const DefaultFeatureCard = styled.div`
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url(${featuredCardImg});
`;

const tagDict = {
  Content: {
    color: "rgba(49,86,222,.10)",
    textColor: "rgb(49,86,222)",
    label: "Content",
  },
  Team: {
    color: "rgba(100,189,118,.10)",
    textColor: "rgb(100,189,118)",
    label: "Team",
  },
  MyStats: {
    color: "rgba(49,145,222,.10)",
    textColor: "rgb(49,145,222)",
    label: "My Stats",
  },
};

const iconMap = {
  meeting: {
    iconName: "fire",
    background: "#DE3187",
    tags: [],
  },
  feed_team_successful_user: {
    iconName: "trendUp",
    label: "Most successful user",
    background: "#31DE95",
    tags: ["Team"],
  },
  feed_team_rising: {
    iconName: "trendUp",
    label: "Rising user",
    background: "#31AFDE",
    tags: ["Team"],
  },
  feed_team_most_interactions: {
    iconName: "rocket",
    label: "Most activity",
    background: "#A031DE",
    tags: ["Team"],
  },
  feed_team_breakout: {
    iconName: "filledStar",
  },
  feed_team_successful_content: {
    iconName: "crown",
    label: "Most successful content",
    background: "#31CDDE",
    tags: ["Content"],
  },
  feed_feature_content_combo: {
    iconName: "balloons",
    label: "Best combo",
    background: "#8231DE",
    tags: ["Content"],
  },
  new: {
    iconName: "sparkles",
    label: "New content",
    background: "#3156DE",
    tags: ["Content"],
  },

  feature_not_successful: {
    iconName: "exclamation",
    label: "Unsuccessful content",
    background: "#161616",
    tags: ["Content"],
  },
};

export default function Featured(props) {
  const [featuredList, setFeaturedList] = useState([]);
  const [featuredFeedList, setFeaturedFeedList] = useState([]);
  const [filterFeedList, setFilterFeedList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const { new: newContent } = useSelector((state) => state?.content || {});
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const { theme } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [cardData, setCardData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedFilter, setSelectedFilter] = useState();

  const hiddenTeamFeedTypes = useSelector(
    (state) => state.content.hiddenTeamFeedTypes
  );

  const toggle = (data = null) => {
    setShowModal(!showModal);
    setCardData(data);
  };

  const onSelect = (data) => {
    if (data.interactions) {
      toggle(data);
    }
  };

  const handleDateChange = async (duration) => {
    getDashboardData(
      duration,
      setDashboardLoading,
      (teamFeed, featuredFeed) => {
        setFeaturedFeedList([...teamFeed, ...featuredFeed]);
      },
      (error) => {}
    );
  };

  const onFilter = (tag) => {
    let temp = [...featuredList];
    let filteredList = [];
    if (tag === "clear") {
      filteredList = temp;
      setSelectedFilter();
    } else {
      setSelectedFilter(tag);
      filteredList = temp.filter((t) => {
        if (
          t.filter &&
          iconMap?.[t.filter]?.tags &&
          iconMap?.[t.filter]?.tags.includes(tag)
        ) {
          return true;
        } else {
          return false;
        }
      });
    }
    setFilterFeedList(filteredList);
  };

  useEffect(() => {
    let tempActivityList = [];
    if (featuredFeedList?.length) {
      tempActivityList = [...featuredFeedList];
    }
    if (newContent && Object.keys(newContent)) {
      Object.keys(newContent).map((key) => {
        const contentParent = newContent[key];
        if (contentParent && Object.keys(contentParent).length) {
          Object.keys(contentParent).map((childId) => {
            const childContent = contentParent?.[childId] || {};
            tempActivityList.push({
              ...childContent,
              text: `${CONTENT_TYPE?.[key]?.controlLabel}: ${childContent?.label}`,
              type: key,
              id: childId,
              cardType: "New",
              filter: "new",
            });
          });
        }
      });
    }
    if (tempActivityList?.length) {
      setFeaturedList(tempActivityList);
      setFilterFeedList(
        tempActivityList
        .filter(
          (item) => !hiddenTeamFeedTypes?.[item.filter] || (Array.isArray(hiddenTeamFeedTypes?.[item.filter]) && !hiddenTeamFeedTypes?.[item.filter]?.includes(item.id))
        )
      );
    }
  }, [newContent, featuredFeedList]);

  useEffect(() => {
    setFilterFeedList(
      filterFeedList
      .filter(
        (item) => !hiddenTeamFeedTypes?.[item.filter] || (Array.isArray(hiddenTeamFeedTypes?.[item.filter]) && !hiddenTeamFeedTypes?.[item.filter]?.includes(item.id))
      )
    );
  }, [hiddenTeamFeedTypes]);

  return (
    <SkeletonTheme
      baseColor={COLORS.lightBlue}
      highlightColor={COLORS.grey1}
      borderRadius="0.5rem"
      duration={0.5}
    >
      <div
        style={{
          postion: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {<div style={{ display: "flex", marginBottom: 14 }}>
          <DateFilter
            onDateChange={handleDateChange}
            theme={theme}
            title={"Spotlight"}
          />
         { featuredList.length > 0 && <div>
            <div style={{ display: "flex", marginLeft: 8, marginTop: 7 }}>
              {Object.keys(tagDict).map((tag) => (
                <Button
                  key={tag}
                  size="sm"
                  className={selectedFilter === tag && "active"}
                  style={{
                    marginRight: 8,
                  }}
                  onClick={() => onFilter(tag)}
                >
                  {tagDict[tag].label}
                </Button>
              ))}
              <Button
                size="sm"
                style={{
                  marginRight: 8,
                }}
                onClick={() => onFilter("clear")}
              >
                Clear
              </Button>
            </div>
          </div>}
        </div>}

        <HorizontalScrollView
          isEmpty={!dashboardLoading && filterFeedList.length === 0 }
          isFullHeight={
            (featuredFeedList.length && !dashboardLoading) || dashboardLoading
          }
        >
          <Content>
            {filterFeedList.length && !dashboardLoading
              ? filterFeedList.map((item, index) => {
                  return (
                    <StatisticCard
                      key={index}
                      index={index}
                      data={item?.interactions}
                      onClick={() => onSelect(item)}
                      bg={iconMap[item.filter]?.background || "#3D5996"}
                    >
                      <StatisticInner>
                        <div style={{ flexGrow: 1 }}>
                          {iconMap[item.filter]?.iconName && (
                            <StatIcon color={iconMap[item.filter].background}>
                              <Icon type={iconMap[item.filter]?.iconName} />
                            </StatIcon>
                          )}
                          <FlexContainer
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ marginBottom: 8 }}
                          >
                            <h4
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                margin: 0,
                              }}
                            >
                              {iconMap[item.filter]?.label}
                            </h4>
                            <IconHide>
                              <Icon
                                type="unhide"
                                className={"feature-unhide"}
                              />
                              <Icon
                                className={"feature-hide"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(hideTeamFeedType(item));
                                }}
                                type="hide"
                              />
                            </IconHide>
                          </FlexContainer>
                          <ToolTipItem
                            label={item?.text}
                            direction="top"
                            itemId={"stat_" + index}
                            showToolTip={item.text?.length === 50}
                          >
                            <p style={{ marginBottom: 2 }}>
                              {item.text?.length > 50
                                ? item.text.slice(0, 50) + "..."
                                : item.text}
                            </p>
                          </ToolTipItem>
                        </div>

                        <div style={{ display: "flex" }}>
                          {iconMap[item.filter]?.tags.map((item) => (
                            <Tag tagTheme={tagDict[item]}>
                              {tagDict[item]?.label}
                            </Tag>
                          ))}
                       
                        </div>
                      </StatisticInner>
                    </StatisticCard>
                  );
                })
              : null}
            {dashboardLoading
              ? [{}, {}, {}].map((index, i) => {
                  return (
                    <StatisticCard
                      key={i}
                      index={i}
                      isLoading={dashboardLoading}
                    >
                      <div style={{ padding: SPACING.XS }}>
                        <Skeleton count={1} height="15px" width={"60%"} />
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 5,
                            display: "flex",
                            flex: 1,
                          }}
                        >
                          <Skeleton count={1} height={"35px"} width={"150px"} />
                        </div>
                      </div>
                    </StatisticCard>
                  );
                })
              : null}
            { !dashboardLoading && filterFeedList.length === 0 ? (
              <div style={{height: 180, marginTop: 20}}>
                <div style={{ margin: 0, height: "100%", paddingTop: 0 }}>
                  <DefaultFeatureCard />
                </div>
                <h5 style={{ textAlign: "left", marginTop: 14 }}>
                    Meeting stats and updates about <br /> new sales material will show up here.
                  </h5>
              </div>
            ) : null}
            
          </Content>
          <TableChartModal
            isOpen={showModal}
            toggle={toggle}
            data={cardData || {}}
          />
        </HorizontalScrollView>
      </div>
    </SkeletonTheme>
  );
}
