import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../constants";

export const Link = styled.a`
  cursor: pointer;
  color: ${COLORS.white};
  text-decoration: none;
  border: 0;
  font-size: ${(props) => props.size || TYPOGRAPHY.standard}px;
  &:hover, &:focus{
    color: ${COLORS.borderColor};
  }
`
