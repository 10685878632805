export const isDevRunningEnvironment = () => {
  let dev = false;
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "0.0.0.0"
  ) {
    dev = true;
  }
  return dev;
};


