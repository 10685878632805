import SearchInput from "../ui/SearchInput";
import { CenterContainer } from "../../shared/ui/Containers";
import LoaderDefault from "shared/ui/Loader";
import { ListGroup } from "reactstrap";
import { useState } from "react";
import { Button } from "shared/ui/Buttons";
import { COLORS, APP_LAYOUT } from "shared/constants";
import styled from "styled-components";
import { searchSalesforce } from "../../redux/actions/meetings";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "../../shared/ui/Modals";
import TabbedView from "../ui/TabbedView";
import { SALESFORCE_VIEW } from "./SalesforceForm";
import SalesforceRecordForm from "./SalesforceRecordForm"
import { RECORD_TYPES } from "shared/constants/recordTypes";

const ScrollContainer = styled.div`
  display: flex;
  // flex:1;
  overflow: scroll;
  flex-direction: column;

  width: 100%;
  height: 300px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    padding-left: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.type === APP_LAYOUT.ZOOM ? COLORS.greyDark : theme.colors.grey1};
    border-radius: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

const SalesforceRecordItem = styled.h4`
  cursor: pointer;
  font-weight: 400;
  padding: 4px 8px;
  margin: 0 4px;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme?.colors?.active};
    color: white;
  }
`;

const SalesforceSearchModal = ({
  handleRecordSelect,
  isOpen,
  toggle,
}) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);

  const { info } = useSelector((state) => state.users || {});
  const { uid, companyId } = info || {};
  const onSearch = async (text) => {
    setSearchText(text);
    setIsLoading(true);
    const response = await searchSalesforce(text, uid, companyId);
    if (response) {
      const requestUrl = Object.keys(response)[0];
      const data = response[requestUrl];
      setSearchResults(data.searchRecords);
    }
    setIsLoading(false);
  };

  const tabs = {
    [SALESFORCE_VIEW.CONTACT]: {
      header: "Contact",
      content: (
        <SalesforceRecordForm
          type={RECORD_TYPES.CONTACT}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isAddMode={isAddMode}
          setIsAddMode={setIsAddMode}
          handleTaskRecordSelect={handleRecordSelect}
        />
      ),
    },
    [SALESFORCE_VIEW.ACCOUNT]: {
      header: "Account",
      content: (
        <SalesforceRecordForm
          type={RECORD_TYPES.ACCOUNT}
          isLoading={isLoading}
          isAddMode={isAddMode}
          setIsLoading={setIsLoading}
          setIsAddMode={setIsAddMode}
          handleTaskRecordSelect={handleRecordSelect}
        />
      ),
    },
    [SALESFORCE_VIEW.OPPORTUNITY]: {
      header: "Opportunity",
      content: (
        <SalesforceRecordForm
          type={RECORD_TYPES.OPPORTUNITY}
          isLoading={isLoading}
          isAddMode={isAddMode}
          setIsLoading={setIsLoading}
          setIsAddMode={setIsAddMode}
          handleTaskRecordSelect={handleRecordSelect}
        />
      ),
    },
    [SALESFORCE_VIEW.LEAD]: {
      header: "Lead",
      content: (
        <SalesforceRecordForm
          type={RECORD_TYPES.LEAD}
          isLoading={isLoading}
          isAddMode={isAddMode}
          setIsLoading={setIsLoading}
          setIsAddMode={setIsAddMode}
          handleTaskRecordSelect={handleRecordSelect}
        />
      ),
    },
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} title={isAddMode ? "Create Salesforce record" :"Search Salesforce record"}>
      <ModalBody>
       {isAddMode ? 
        <>
        <TabbedView tabs={tabs}/>
        <Button hoverColor="danger" style={{width: '100%'}} onClick={() => setIsAddMode(false)}>Cancel</Button>
        </>
       : <div style={{ width: "100%", minHeight: "40vh"}}>
          <SearchInput onSearch={onSearch} />
          {isLoading ? (
            <CenterContainer>
              <LoaderDefault height={200} width={200} />
            </CenterContainer>
          ) : (
            <ListGroup>
              <div style={{  paddingTop: 10 }}>
                { searchText.length === 0 && (
                  <div>
                    <p style={{ fontWeight: 400 }}>
                      Would you like to add record?
                    </p>
                    <Button
                      color="primary"
                      style={{
                        width: 173,
                        // borderRadius: 0,
                        marginTop: 14,
                      }}
                      onClick={() => setIsAddMode(true)}
                    >
                      Add Record
                    </Button>
                  </div>
                )}
                {searchResults?.length > 0 && (
                  <div>
                    <h3>
                      {searchResults?.length} results found for "{searchText}
                      ".
                    </h3>
                    <h5 style={{ fontWeight: 400 }}>See Below.</h5>
                    <ScrollContainer>
                      {searchResults?.map((val) => {
                        return (
                          <SalesforceRecordItem
                            onClick={() => {handleRecordSelect(val); toggle()}}
                            key={val["Id"]}
                          >
                            {val?.Name || ""}
                          </SalesforceRecordItem>
                        );
                      })}
                    </ScrollContainer>
                  </div>
                )}
              </div>
            </ListGroup>
          )}
        </div>}
      </ModalBody>
    </Modal>
  );
};

export default SalesforceSearchModal;
