import styled from "styled-components";
import { BORDER, COLORS, SPACING } from "../constants";

export const Card = styled.div`
  position: relative;
  width: 100%;
  color: ${({theme}) => theme?.text?.color};
  padding: ${SPACING.SM}px;
  margin-top: ${SPACING.SM}px;
  width: calc(100% - ${SPACING.XS * 2}px);
  background: ${({theme}) => theme?.card?.contentBackground};
  border-radius: ${BORDER.radius_sm}px;
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.04),
    0 1px 2px rgba(255, 255, 255, 0.24);
`;
