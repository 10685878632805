import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Helmet } from "react-helmet";

import configureStore from "./redux/store";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";

import "./styles/icons/scss/fontawesome.scss";
import "video-react/dist/video-react.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "rc-slider/assets/index.css";
import "react-toastify/dist/ReactToastify.css";

import { AuthProvider } from "./contexts/AuthContext";
import "./interceptors/httpInterceptor.js";
import Logo from './logo.ico'

const { persistor, store } = configureStore();
const rootElement = document.getElementById("root");

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AuthProvider>
        <Helmet>
          <title>Upmarket</title>
          <meta name="image" content={Logo} />
        </Helmet>
        <ErrorBoundary
          fallbackRender={() => <div>There is an Error</div>}
          onError={(error, info) => {
            console.log(error, info);
          }}
          resetKeys={[]}
        >
          <ToastContainer />
          <Router>
            <Routes />
          </Router>
        </ErrorBoundary>
      </AuthProvider>
    </PersistGate>
  </Provider>
);

render(<Root />, rootElement);
