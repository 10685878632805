import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { COLORS, SPACING, TYPES_WITH_TOOLBAR, TYPE_LIST, TYPOGRAPHY } from "../../shared/constants";
import { JSONtoHTML } from "../../shared/utils/reformatScript";
import { Card } from "../../shared/ui/Cards";
import { Avatar, Icon, ICON_TYPES } from "../../shared/ui/Icon";
import MeetingCardCollapsible from "./MeetingCardCollapsible";
import {Badge} from '../../shared/ui/Buttons';
import { ContentCardContainer } from "../../shared/ui/Containers";
// const Container = styled.div`
//   display: flex;
//   align-items: flex-start;
//   justify-content: center;
//   max-width: 750px;
//   width: 100%;
//   margin: 0 auto ${SPACING.XS}px;
// `;
const IconIndicator = styled.div`
  padding-top: ${SPACING.XXS}px;
  width: 40px;
  margin-right: ${SPACING.SM}px;
`;
const TimeIndicator = styled.div`
  padding-left:5px;
  padding-top: ${SPACING.XS}px;
  width: 40px;
  margin-left: auto;
  > div {
    color: ${({theme}) => theme.text.grey }
  }

`;
const Details = styled(Card)`
  width: calc(100% - 80px);
  margin-top: 0;
  padding: ${SPACING.XS}px ${SPACING.SM}px;
`;

const IconCircle = styled.div`
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getFormattedContent = (content, type, info, timelineItem) => {
  let formattedContent = null;
  let contentText = ""
  let script = null
  switch (type) {
    case TYPE_LIST.TRIGGERS:
      if (content?.Versions) {
        const versionId = Object.keys(content.Versions)[0];
        script = JSONtoHTML(
          content.Versions[versionId].content,
          {member: info}
        );
        formattedContent = <div dangerouslySetInnerHTML={{__html: script}}/>
      }
      break;
    case TYPE_LIST.DISCOVERY_QUESTIONS:
      const prevResponseKey = Object.keys(timelineItem).find((val) =>
        val.startsWith("response_")
      );
      formattedContent = (
        <MeetingCardCollapsible
          header={
            <div style={{ marginBottom: 4 }}>
              <h4>{content.label}</h4>
              <Badge color="primary">{timelineItem[prevResponseKey]}</Badge>
            </div>
          }
        >
          <>
            <div>
              {content.options.map((val) => (
                <Badge style={{ marginRight: 4}} key={val}>
                  {val}
                </Badge>
              ))}
            </div>
          </>
        </MeetingCardCollapsible>
      );
      break;
    case TYPE_LIST.OBJECTIONS:
    case TYPE_LIST.SOCIALPROOFS:
      if(TYPES_WITH_TOOLBAR.includes(type)){
        script = JSONtoHTML(
          content.content,
          {member: info}
        );
        contentText = <div dangerouslySetInnerHTML={{__html: script}}/>
        
      }else{
        contentText = content.content
      }
      formattedContent = (
        <>
          <h4>{content?.label}</h4>
          <div>{contentText}</div>
        </>
      );
      break;
    case TYPE_LIST.FAQS:
      formattedContent = (
        <>
          <h4>{content?.label}</h4>
          <div>{content.answer}</div>
          {content?.tags?.length ? (
            <h5 style={{ marginTop: 10 }}>
              <b>Tags: </b>
              {content.tags.join(", ")}
            </h5>
          ) : null}
        </>
      );
      break;
    case TYPE_LIST.BATTLESCARDS:
      if(TYPES_WITH_TOOLBAR.includes(type)){
        script = JSONtoHTML(
          content.content,
          {member: info}
        );
        contentText = <div dangerouslySetInnerHTML={{__html: script}}/>
        
      }else{
        contentText = content.content
      }
      formattedContent = (
        <>
          <h4>{content.label}</h4>
          <div>{contentText}</div>
          <div style={{ marginTop: SPACING.SM }}>
            {/* <div>
              {content.Strengths ? (
                <>
                  <span
                    style={{
                      fontWeight: 600,
                      borderBottom: "2px solid " + COLORS.red,
                    }}
                  >
                    Strengths:
                  </span>{" "}
                  {content.Strengths}
                </>
              ) : null}
            </div> */}
            <div>
              {content.Weaknesses ? (
                <>
                  <span
                    style={{
                      fontWeight: 600,
                      borderBottom: "2px solid " + COLORS.red,
                    }}
                  >
                    Counterpoints:
                  </span>{" "}
                  {content.Weaknesses}
                </>
              ) : null}
            </div>
          </div>
        </>
      );
      break;
    case TYPE_LIST.SENTENCE:
      formattedContent = <div>{content.sentence}</div>;
      break;
    default:
      break;
  }
  return formattedContent;
};

const getIcon = (participant, type, index, avatarColor) => {
  let icon = null;

  switch (type) {
    case TYPE_LIST.TRIGGERS:
    case TYPE_LIST.DISCOVERY_QUESTIONS:
    case TYPE_LIST.OBJECTIONS:
    case TYPE_LIST.SOCIALPROOFS:
    case TYPE_LIST.FAQS:
    case TYPE_LIST.BATTLESCARDS:
      icon = (
        <IconCircle>
          <Icon
            type={ICON_TYPES[type]}
            style={{ fontSize: TYPOGRAPHY.h3 + 2 }}
          />
        </IconCircle>
      );
      break;
    case TYPE_LIST.SENTENCE:

      icon = (
        <Avatar
          avatarColor={avatarColor}
          name={participant?.user_name}
          round
          size="40"
          textSizeRatio={2.5}
          index={index}
        />
      );

      break;
    default:
      break;
  }
  return icon;
};

export const MeetingPlaybackContentCard = ({
  avatarColor,
  type,
  content,
  onCardClick,
  id,
  participant,
  time,
  timelineItem,
  index
}) => {
  const { info } = useSelector((state) => state.users);
  const cardContent = getFormattedContent(content, type, info, timelineItem);
  const icon = getIcon(participant, type, index, avatarColor);

  if (timelineItem && timelineItem.processed) {
    return null
  }

  return (
    <ContentCardContainer>
      <IconIndicator>{icon}</IconIndicator>
      <Details
        onClick={() => onCardClick(id)}
      >
        <div style={{ width: "100%" }}>{cardContent}</div>
      </Details>
      <TimeIndicator sm={2}>
        {time && <div className="body-tiny">{time}</div>}
      </TimeIndicator>
    </ContentCardContainer>
  );
};

export default MeetingPlaybackContentCard;
