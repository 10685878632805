import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { CONTENT_TYPE, TYPE_LIST } from "../constants";



export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const JSONtoHTML = (script, attributes = false, types = {}) => {
  const deltaArr = parseStringifyText(script, attributes, types);
  var converter = new QuillDeltaToHtmlConverter(deltaArr, {
    customCssClasses: (delta) => {
      if (delta?.attributes?.customCssClasses) {
        return (
          (types?.isEdit && !types?.isContact &&
          delta?.attributes?.track?.type &&
          CONTENT_TYPE[delta?.attributes?.track?.type]?.editValue
            ? "script-in-button "
            : "") + delta?.attributes?.customCssClasses
        );
      }
    },
    urlSanitizer: (link) => {
      return link;
    },
    customTagAttributes: (delta) => {
      if (
        delta?.attributes?.track?.type &&
        delta?.attributes?.track?.type !== "undefined" 
      ) {
        return {
          "data-tagtype": delta?.attributes?.track?.type,
        };
      }
    },
  });

  var html = converter.convert();
  return html;
};

// types = isEmail, isEdit, isPlaceholder
export const parseStringifyText = (text, attributes = {}, types = {}) => {
  const contentValues = {
    COMPANYNAME:
      attributes?.account?.name ||
      (types.isEdit || types.isPlaceholder 
        ? "[" + CONTENT_TYPE.COMPANYNAME.controlLabel + "]"
        : "Company"),
    firstName:
      attributes?.contact?.firstName ||
      (types.isEdit || types.isPlaceholder ? "[" + CONTENT_TYPE.firstName.controlLabel + "]" : false),
    lastName:
      attributes?.contact?.lastName ||
      (types.isEdit || types.isPlaceholder ? "[" + CONTENT_TYPE.lastName.controlLabel + "]" : false),
    myName:
      attributes?.member?.firstName ||
      (types.isEdit || types.isPlaceholder ? "[" + CONTENT_TYPE.myName.controlLabel + "]" : false),
    contactName:
      attributes?.contact?.name ||
      (types.isEdit || types.isPlaceholder 
        ? "[" + CONTENT_TYPE.contactName.controlLabel + "]"
        : false),
    email:
      attributes?.contact?.email?.email ||
      (types.isEdit || types.isPlaceholder ? "[" + CONTENT_TYPE.email.controlLabel + "]" : false),
    signature:
      (attributes?.member?.emailSignatures?.active &&
        attributes?.member?.emailSignatures?.list[
          attributes?.member?.emailSignatures?.active
        ].content) ||
      (types.isEdit || types.isPlaceholder ? "[" + CONTENT_TYPE.signature.controlLabel + "]" : false),
    contactTitle:
      attributes?.contact?.title ||
      (types.isEdit || types.isPlaceholder 
        ? "[" + CONTENT_TYPE.contactTitle.controlLabel + "]"
        : false),
    [TYPE_LIST.SOCIALPROOFS]:
      attributes?.[TYPE_LIST.SOCIALPROOFS] ||
      (types.isEdit || types.isPlaceholder 
        ? "[" + CONTENT_TYPE[TYPE_LIST.SOCIALPROOFS].controlLabel + "]"
        : false),
  };
  if (text && isJSON(text)) {
    const textArray = JSON.parse(text);
    let deltaAdd = {};
    let content = textArray.map((item, i) => {
      let track = {};
      let label = item.label;
      if (item.type && item.type !== "plainText") {
        track = {
          bold: item?.attributes?.bold
            ? item?.attributes?.bold
            : types?.isEmail && !types?.isEdit && !item?.attributes?.bold
            ? false
            : true,
          customCssClasses: item.type,
          track: {
            type: item.type,
          },
        };

        label = contentValues?.[item.type]
          ? contentValues?.[item.type]
          : types?.isEmail
          ? ""
          : item.label;
        if (isJSON(contentValues?.[item.type])) {
          let deltaArr = parseStringifyText(
            contentValues?.[item.type],
            attributes,
            types
          );
          let deltaTemp = [];
          for (let i = 0; i < deltaArr.length; i++) {
            deltaArr[i] = {
              ...deltaArr[i],
              attributes: {
                ...deltaArr[i].attributes,
                customCssClasses: "block-type " + item.type,
                track: {
                  type: item.type,
                },
              },
            };
            deltaTemp.push(deltaArr[i]);
          }
          deltaAdd = {
            ...deltaAdd,
            [i]: deltaTemp,
          };
        }
      }

      return {
        insert: label,
        attributes: {
          ...item.attributes,
          ...track,
        },
      };
    });
    let tempcontent = [...content];
    if (Object.keys(deltaAdd).length > 0) {
      Object.keys(deltaAdd).map((pos) => {
        tempcontent.splice(pos, 1, ...deltaAdd[pos]);
      });
    }
    return tempcontent;
  } else {
    return [{ insert: text }];
  }
};
