import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import media from "shared/utils/media";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MeetingDate } from "../call/MeetingDate";

import HorizontalAnimationWrapper from "shared/ui/HorizontalAnimationWrapper";
import { FlexContainer } from "shared/ui/Containers";
import { InputForm } from "shared/ui/Inputs";
import {
  BORDER,
  COLORS,
  SPACING,
  TYPOGRAPHY,
  TYPE_LIST,
  CONTENT_TYPE,
} from "shared/constants";
import { Avatar, Icon, ICON_TYPES } from "shared/ui/Icon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "shared/ui/Cards";
import { getMeetingWins } from "../../redux/actions/meetings";
import {  Loader } from "shared/ui";

const Container = styled.div`
  width: 100%;
  height: 100%;
  ${media.lessThan("tablet")`
    padding-top:8px;
  `}
`;
const Section = styled.div`
  margin-bottom: ${SPACING.MD * 2}px;
  width: 100%;
`;

const MeetingInfo = styled.div`
  width: calc(100% - 45px);
`;
const MeetingItemContainer = styled(Card)`
  background-color: ${COLORS.azureRadiance};
  transition: all 0.25s ease-out;
  cursor: pointer;
  display: flex;
  color: ${({theme}) => theme?.text?.color || COLORS.white};

  background-color: ${({type, theme}) =>
    type === "live" ? theme.card.active : theme.card.background };
  border: 1px solid;
  border-bottom: 5px solid;
  border-color: ${COLORS.transparent};
  margin-top: 0;
  margin-bottom: ${SPACING.SM}px;
  &:hover {
    border-color: ${({ theme}) => theme.card.active };

  }
`;
const MeetingItem = ({ meeting, onMeetingSelected, id, type }) => {
  return (
    <MeetingItemContainer
      onClick={() => onMeetingSelected(id, meeting)}
      type={type}
    >
      <MeetingInfo>
        <h4>{meeting?.topic}</h4>
        <MeetingDate
          start_time={meeting?.start_time}
          end_time={meeting?.end_time}
        />
        {meeting?.participants && (
          <div style={{ marginTop: 14 }}>
            {Object.keys(meeting.participants).map((val) => (
              <Avatar
                key={val}
                name={meeting.participants[val].user_name}
                round
                size="30"
                style={{
                  marginRight: SPACING.XXS,
                }}
              />
            ))}
          </div>
        )}
      </MeetingInfo>
    </MeetingItemContainer>
  );
};
const RecentWins = ({}) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const { info } = useSelector((state) => state.users);
  let { uid, companyId } = info || {};

  const { Dispositions } = useSelector(
    (state) => state?.content?.company || {}
  );
  const [filterMeetings, setFilterMeetings] = useState([]);
  const [allMeetings, setAllMeetings] = useState([]);
  let navigate = useNavigate();
  const onMeetingSelected = (interactionId) => {
    navigate(`/meeting/${interactionId}`);
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value) {
      let searchFilter = allMeetings.filter((meeting) =>
        meeting?.topic?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilterMeetings(searchFilter);
    } else {
      setFilterMeetings(allMeetings);
    }
  };
  const getMeetings = async (outcomeIds) => {
    let meetings = {};
    if (outcomeIds?.length) {
      setLoading(true);
      await Promise.all(
        outcomeIds.map(async (id) => {
          const meetingObj = await getMeetingWins(companyId, id);
          meetings = {
            ...meetings,
            ...meetingObj,
          };
        })
      );
    }
    let allMeetings = Object.keys(meetings).map((id) => ({
      ...meetings[id],
      id,
    }));
    setFilterMeetings(allMeetings);
    setAllMeetings(allMeetings);
    setLoading(false);
  };
  useEffect(() => {
    const outcomes =Dispositions ?  Object.keys(Dispositions).filter(
      (id) =>
        Dispositions[id]?.complete && Dispositions[id]?.sentiment === "positive"
    ) : [];
    getMeetings(outcomes);
    return () => {};
  }, []);

  return (
    <HorizontalAnimationWrapper>
      <Container>
        <Section style={{ flexGrow: 1, paddingBottom: "50px" }}>
          <FlexContainer
            alignItems={"center"}
            justifyContent="space-between"
            style={{ marginBottom: SPACING.SM, }}
          >
            <FlexContainer alignItems={"center"}>
              <span style={{ width: 40, cursor: "pointer" }}>
                <Icon
                  onClick={() => {
                    navigate("/");
                  }}
                  type={"caretLeft"}
                  style={{ fontSize: 40 }}
                />
              </span>
              <h2 style={{ fontWeight: "bold", marginBottom: 0 }}>
                Recent Wins
              </h2>
            </FlexContainer>
            <InputForm
              value={search}
              onChange={handleChange}
              placeholder={"Search"}
              style={{ marginRight: 16, marginBottom: 0 }}
            />
          </FlexContainer>
         {loading ? <Loader /> : <PerfectScrollbar
            component="div"
            style={{
              width: "100%",
              height: "80vh",
            }}
          >
            <div style={{ }}>
              {filterMeetings?.length > 0 ? (
                filterMeetings.map((meeting) => {
                  return (
                    <MeetingItem
                      key={meeting.id}
                      id={meeting.id}
                      meeting={meeting}
                      onMeetingSelected={onMeetingSelected}
                    />
                  );
                })
              ) : (
                <p style={{ margin: "0 8px", color: COLORS.grey1 }}>
                  (No Past Meetings)
                </p>
              )}
            </div>
          </PerfectScrollbar>}
        </Section>
      </Container>
    </HorizontalAnimationWrapper>
  );
};

export default RecentWins;
