import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import media from "shared/utils/media";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MeetingDate } from "../call/MeetingDate";

import HorizontalAnimationWrapper from "shared/ui/HorizontalAnimationWrapper";
import { FlexContainer } from "shared/ui/Containers";
import { InputForm } from "shared/ui/Inputs";
import { BORDER, COLORS, SPACING, TYPOGRAPHY, TYPE_LIST, CONTENT_TYPE } from "shared/constants";
import { Avatar, Icon, ICON_TYPES } from "shared/ui/Icon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "shared/ui/Cards";

const Container = styled.div`
  width: 100%;
  height: 100%;
  ${media.lessThan("tablet")`
    padding-top:8px;
  `}
`;
const Section = styled.div`
  margin-bottom: ${SPACING.MD * 2}px;
  width: 100%;
`;
const SavedContainer = styled(Card)`
  transition: all 0.25s ease-out;
  cursor: pointer;
  // display: flex;
  color: ${({theme}) => theme?.text?.color || COLORS.white};

  background-color: ${({type, theme}) =>
    type === "live" ? theme.card.active : theme.card.background };
  border: 1px solid;
  border-bottom: 5px solid;
  border-color: ${COLORS.transparent};
  margin-top: 0;
  margin-bottom: ${SPACING.SM}px;
  &:hover {
    border-color: ${({ theme}) => theme.card.active };
  }
`;

const SmallTag = styled.div`
  margin-right: ${SPACING.SM}px;
  margin-bottom: ${SPACING.XS}px;
`

const SavedItem = ({ handleSavedSelected, template, id, type, playbook, callReason }) => {
  return (
    <SavedContainer
      onClick={() => handleSavedSelected(id, template)}
      type={type}
    >
      <h4>{template?.topic}</h4>
      <FlexContainer>
        {playbook && <SmallTag className="body-tiny">{CONTENT_TYPE[TYPE_LIST.CALLFLOWS].controlLabel}: {playbook?.label}</SmallTag>}
        {callReason && <SmallTag className="body-tiny">{CONTENT_TYPE[TYPE_LIST.TRIGGERS].controlLabel}: {callReason?.label}</SmallTag>}
      </FlexContainer>
      <MeetingDate start_time={template?.lastModified} />

    </SavedContainer>
  );
};
const Saved = ({}) => {
  const [search, setSearch] = useState("");
  const savedTemplates = useSelector(
    (state) => state.users?.info?.zoom?.saved || {}
  );
  const content = useSelector((state) => state?.content?.company || {});
  const [filterSavedTemplates, setFilterSavedTemplates] = useState([]);
  let navigate = useNavigate();
  const handleSavedSelected = (id) => {
    navigate(`/prepare/${id}`);
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    let filtered = savedTemplates
    ? Object.keys(savedTemplates).map((template) => {
        return {
          ...savedTemplates[template],
          id: template,
        };
      })
    : [];
      console.log(filtered)
    if(value){

      let searchFilter = filtered.filter(template => template?.topic?.toLowerCase()?.includes(value?.toLowerCase()))
      setFilterSavedTemplates(searchFilter)
    }else{
      setFilterSavedTemplates(filtered)
    }
  };
  useEffect(() => {
    let filtered = savedTemplates
      ? Object.keys(savedTemplates).map((template) => {
          return {
            ...savedTemplates[template],
            id: template,
          };
        })
      : [];
    setFilterSavedTemplates(filtered);

    return () => {};
  }, []);

  return (
    <HorizontalAnimationWrapper>
      <Container>
        <Section style={{ flexGrow: 1, paddingBottom: "50px" }}>
          <FlexContainer
            alignItems={"center"}
            justifyContent="space-between"
            style={{ marginBottom: SPACING.SM, }}
          >
            <FlexContainer alignItems={"center"}>
              <span style={{ width: 40, cursor: "pointer" }}>
                <Icon
                  onClick={() => {
                    navigate("/");
                  }}
                  type={"caretLeft"}
                  style={{ fontSize: 40 }}
                />
              </span>
              <h2 style={{ fontWeight: "bold", marginBottom: 0 }}>Saved</h2>
            </FlexContainer>
            <InputForm
              value={search}
              onChange={handleChange}
              placeholder={"Search"}
              style={{ marginRight: 16, marginBottom: 0 }}
            />
          </FlexContainer>
          <PerfectScrollbar
            component="div"
            style={{
              width: "100%",
              height: "80vh",
            }}
          >
            <div style={{ }}>
              {filterSavedTemplates?.length > 0 ? (
                filterSavedTemplates.map((template) => {
                  let playbook = content?.[TYPE_LIST.CALLFLOWS]?.[template?.callFlowId];
                  let callReason = content?.[TYPE_LIST.TRIGGERS]?.[template?.triggerId];

                  return (
                    <SavedItem
                      key={template.id}
                      id={template.id}
                      template={template}
                      playbook={playbook}
                      callReason={callReason}
                      handleSavedSelected={handleSavedSelected}
                    />
                  );
                })
              ) : (
                <p style={{ margin: "0 8px", color: COLORS.grey1 }}>
                  (No Past Meetings)
                </p>
              )}
            </div>
          </PerfectScrollbar>
        </Section>
      </Container>
    </HorizontalAnimationWrapper>
  );
};

export default Saved;
