import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { SPACING } from "../../shared/constants";
import { CenterContainer, FlexContainer } from "../../shared/ui/Containers";
import { Avatar, Icon } from "../../shared/ui/Icon";
import { ToolTipItem } from "../../shared/ui/ToolTips";

const Container = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: ${SPACING.SM}px;
  align-items: center;
  width: 350px;
  cursor: default;
`;

const iconLabel = {
  'userCheck': 'Contact Verified',
  'userCaution': 'Multiple Contacts Found',
  'userPlus': 'Add Contact'
}
const Participant = ({  participant, toggle, onParticipantChange, hideActions }) => {
  const { interactionId } = useParams();
  const [iconType, setIconType] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  let navigate = useNavigate();
  let {  accessCode } = useParams();

  const handleContactNav = () => {
    console.log("participant", participant);
    onParticipantChange({
      type: iconType,
      interactionId,
      userId: participant.keyId
    })
    // navigate(
    //   `/contact?type=${iconType}&interactionId=${interactionId}&userId=${participant.keyId}`
    // );
    // toggle();
  };

  const handleShowIcon = () => {
    if (participant.isContactLinked) {
      setIconType("userCheck");
    } else if (participant.contacts && participant.contacts.length > 1) {
      setIconType("userCaution");
    } else {
      setIconType("userPlus");
    }
  };


  useEffect(async () => {
    handleShowIcon();
  }, [participant]);

  return (
    <Container >
      <CenterContainer>
        <Avatar name={participant.user_name} round size="40" />
        <div style={{marginLeft: 14, display: 'flex', flexDirection: 'column'}}>
          <span className="p">{participant.user_name}</span>
          {/* <span className="body-tiny-grey">{participant.email} </span> */}
        </div>
      </CenterContainer>
      {!accessCode && <ToolTipItem itemId={"participant_" + iconType + "_" + participant.keyId} label={iconLabel[iconType]} direction="left">
        <Icon onClick={handleContactNav} style={{cursor: 'pointer'}} type={iconType} />
      </ToolTipItem>}
    </Container>
  );
};

export default Participant;
