import React, { useEffect, useState } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Button } from "../../shared/ui/Buttons";
import SalesforceSearchModal from "./SalesforceSearchModal";
import {
  createSalesforceTask,
  delSfdc,
  getSfdc,
  removeInteractionSync,
  updateInteractionSync,
  updateSalesforceTask,
} from "../../redux/actions/meetings";
import { useSelector } from "react-redux";
import { RECORD_TYPES } from "shared/constants/recordTypes";
import TabbedView from "../ui/TabbedView";
import { toast } from "react-toastify";
import { Icon, Loader } from "shared/ui";
import { DeleteConfirmationModal } from "shared/ui/Modals";
import { TinyText } from "shared/ui/Headers";

const MIN_SEARCH_TEXT_LENGTH = 3;
const SUBJECT_LIMIT = 255;
const DESCRIPTION_LIMIT = 31800;

export const SALESFORCE_VIEW = {
  SEARCH: "0",
  CONTACT: "1",
  ACCOUNT: "2",
  LEAD: "3",
  OPPORTUNITY: "4",
};

const TaskCreateForm = ({
  selectedRecord,
  salesforce,
  interaction,
  onSync,
  interactionId,
  setIsLoading,
  isLoading,
  setIsSearchOpen,
  isTaskCreated,
  taskData,
  disconnectRecord,
  salesforceVersion,
  salesforcePath,
}) => {
  const [note, setNote] = useState(taskData?.Description || "");
  const [subject, setSubject] = useState(
    taskData?.Subject || interaction?.topic
  );
  const [isUploadTranscript, setIsUploadTranscript] = useState(
    interaction?.sync?.salesforce?.isUploadTranscript
  );

  const onSyncClicked = async () => {
    setIsLoading(true);
    let type = false;
    if (selectedRecord?.attributes?.type) {
      type = selectedRecord?.attributes?.type;
    }
    let salesforceTaskData = {
      Status: "Completed",
      Subject: subject,
      OwnerId: salesforce?.user_id,
      ...((type === RECORD_TYPES.CONTACT || type === RECORD_TYPES.LEAD) && {
        WhoId: selectedRecord?.Id,
      }),
      ...((type === RECORD_TYPES.ACCOUNT ||
        type === RECORD_TYPES.OPPORTUNITY) && { WhatId: selectedRecord?.Id }),
      Description: note,
    };
    try {
      await onSync({
        salesforceTaskData,
        isUploadTranscript,
        isUpdated: isTaskCreated ? true : false,
      });
    } catch (e) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "subject":
        if (value?.length > SUBJECT_LIMIT) {
          setSubject(value.slice(0, SUBJECT_LIMIT));
        } else {
          setSubject(value);
        }
        break;
      case "note":
        if (value?.length > DESCRIPTION_LIMIT) {
          setNote(value.slice(0, DESCRIPTION_LIMIT));
        } else {
          setNote(value);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let subject = taskData?.Subject || interaction?.topic;
    let description = taskData?.Description || "";
    if (subject.length > SUBJECT_LIMIT) {
      subject = subject.slice(0, SUBJECT_LIMIT);
    }

    setNote(description);
    setSubject(subject);
    return () => {
      // setNote("");
    };
  }, [taskData]);

  useEffect(() => {
    if (interaction) {
      setIsUploadTranscript(interaction?.sync?.salesforce?.isUploadTranscript);
      if (!isTaskCreated) {
        let note = interaction?.notes || "";
        if (note.length > DESCRIPTION_LIMIT) {
          note = note.splice(0, DESCRIPTION_LIMIT);
        }
        setNote(note);
      }
    }

    return () => {
      setIsUploadTranscript(false);
    };
  }, []);

  return (
    <div id="task-create-form">
      <div style={{ marginBottom: 20, display: "flex" }}>
        <Button
          color="secondary"
          style={{ marginRight: 14 }}
          onClick={() => setIsSearchOpen(true)}
        >
          {selectedRecord?.Name || "Assign Record"}
        </Button>
        <Button hoverColor="danger" onClick={disconnectRecord}>
          <Icon type="trashcan" />
        </Button>
      </div>
      <Input
        value={subject}
        name="subject"
        onChange={handleChange}
        placeholder="Subject"
      />
      {subject?.length === SUBJECT_LIMIT && <TinyText style={{margin: "8px 0", display: 'block'}}>Subject text limit reached</TinyText>}
      <Input
        type="textarea"
        value={note}
        placeholder="Notes"

        name={"note"}
        style={{ height: 200, marginTop: 14 }}
        onChange={handleChange}
      />
      {note.length === DESCRIPTION_LIMIT && <TinyText style={{margin: "8px 0", display: 'block'}}>Note text limit reached</TinyText>}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Label check style={{ paddingLeft: 0, margin: "20px 0" }}>
          <Input
            type="checkbox"
            value={isUploadTranscript}
            disabled={interaction?.sync?.salesforce?.isUploadTranscript}
            onClick={(e) => setIsUploadTranscript(e.target.checked)}
          />
          {"  "}
          {interaction?.sync?.salesforce?.isUploadTranscript
            ? "Transcript uploaded"
            : "Upload transcript"}
        </Label>
        {salesforcePath && isTaskCreated && salesforceVersion && (
          <a
            href={
              salesforcePath?.replace("{version}", salesforceVersion) +
              "/" +
              isTaskCreated
            }
            target="_blank"
            rel="noreferrer"
          >
            Go to Task
          </a>
        )}
      </div>

      <Button
        full
        isLoading={isLoading}
        onClick={onSyncClicked}
        color="primary"
      >
        {isTaskCreated ? "Update" : "Sync"}
      </Button>
    </div>
  );
};

const SalesforceForm = ({
  interaction,
  uid,
  companyId,
  interactionId,
  reloadInteraction,
}) => {
  const [selectedRecord, setSelectedRecord] = useState(null);
  const member = useSelector((state) => state.member);
  const { salesforce } = member || {};
  const isTaskCreated = interaction?.sync?.salesforce?.taskId;
  const salesforcePath = salesforce?.urls.custom_domain;
  const salesforceVersion = salesforce?.version;
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [taskData, setTaskData] = useState(false);
  const handleRecordSelect = async (val) => {
    setSelectedRecord(val);
    setIsSearchOpen(!isSearchOpen);
  };

  const getSfdcTask = async () => {
    setIsFetching(true);
    let data = await getSfdc(
      `sobjects/${RECORD_TYPES.TASK}/${isTaskCreated}`,
      companyId,
      uid
    );
    let key = Object.keys(data)?.[0];
    console.log(data, "DATA");
    setTaskData({
      Description: data?.[key]?.Description,
      Id: data?.[key]?.Id,
      Subject: data?.[key]?.Subject,
    });
    if (interaction?.sync?.salesforce?.type) {
      let record = await getSfdc(
        `sobjects/${interaction?.sync?.salesforce?.type}/${interaction?.sync?.salesforce?.recordId}`,
        companyId,
        uid
      );
      let recordKey = Object.keys(record)?.[0];
      console.log(record);
      setSelectedRecord({
        attributes: record?.[recordKey]?.attributes,
        Id: record?.[recordKey]?.Id,
        Name: record?.[recordKey]?.Name,
      });
    }
    setIsFetching(false);
  };

  const deleteTask = async (isDelete = false) => {
    setIsLoading(true);
    if (isDelete) {
      await delSfdc(
        `sobjects/${RECORD_TYPES.TASK}/${isTaskCreated}`,
        companyId,
        uid
      );
    }

    await removeInteractionSync(companyId, interactionId);
    setIsLoading(false);
    reloadInteraction();
  };

  const onSync = async (task) => {
    const { salesforceTaskData, isUploadTranscript, isUpdated } = task;
    setIsLoading(true);

    if (isTaskCreated) {
      await updateSalesforceTask(
        uid,
        companyId,
        interactionId,
        salesforceTaskData,
        isTaskCreated
      );
      await updateInteractionSync(
        companyId,
        interactionId,
        isTaskCreated,
        selectedRecord?.Id,
        selectedRecord?.attributes?.type,
        isUploadTranscript,
        salesforceTaskData?.Description,
        isUpdated
      );
    } else {
      const taskResponse = await createSalesforceTask(
        uid,
        companyId,
        interactionId,
        salesforceTaskData
      );
      const taskResponseKey = Object.keys(taskResponse)[0];
      const taskId = taskResponse[taskResponseKey].id;
      console.log(taskResponse, "TASK RESPONSE");
      if (taskResponse) {
        let type = false;
        if (selectedRecord) {
          type = selectedRecord?.attributes?.type;
        }

        await updateInteractionSync(
          companyId,
          interactionId,
          taskId,
          selectedRecord?.Id,
          type,
          isUploadTranscript,
          salesforceTaskData?.Description,
          isUpdated
        );
      }
    }
    setIsLoading(false);
    reloadInteraction();
    toast("Synced to Salesforce", {
      hideProgressBar: true,
    });
  };

  const disconnectRecord = () => {
    console.log("disconnect task -- ");
    setSelectedRecord(null);
  };

  const onDeleteToggle = (type) => {
    setIsDeleteOpen(type);
  };

  const tabs = {
    // ...(!isTaskCreated &&
    [SALESFORCE_VIEW.SEARCH]: {
      header: "Task",
      content: (
        <TaskCreateForm
          taskData={taskData}
          isTaskCreated={isTaskCreated}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onSync={onSync}
          interaction={interaction}
          salesforce={salesforce}
          selectedRecord={selectedRecord}
          interactionId={interactionId}
          setIsSearchOpen={setIsSearchOpen}
          disconnectRecord={disconnectRecord}
          salesforceVersion={salesforceVersion}
          salesforcePath={salesforcePath}
        />
      ),
    },
  };

  useEffect(() => {
    if (isTaskCreated) {
      getSfdcTask();
    }
  }, []);

  return (
    <>
      <div style={{ marginTop: 14, marginBottom: 20 }}>
        <h3 style={{ fontWeight: 400, textAlign: "center" }}>
          {isTaskCreated ? "Update Salesforce Task" : "Create Salesforce Task"}
        </h3>
      </div>

      <SalesforceSearchModal
        isOpen={isSearchOpen}
        toggle={() => setIsSearchOpen(!isSearchOpen)}
        handleRecordSelect={handleRecordSelect}
      />
      <DeleteConfirmationModal
        isLoading={isLoading}
        label={
          (isDeleteOpen === "unlink" ? "Unlink" : "Delete") + " Salesforce task"
        }
        isOpen={isDeleteOpen ? true : false}
        toggle={() => onDeleteToggle()}
        onDelete={() =>
          isDeleteOpen === "unlink" ? deleteTask() : deleteTask(true)
        }
      />
      {isFetching ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <>
          <TabbedView tabs={tabs} />
          {interaction?.sync?.salesforce?.taskId && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 14,
              }}
            >
              <Button
                style={{ width: "48%" }}
                hoverColor="danger"
                isLoading={isLoading}
                onClick={() => onDeleteToggle("unlink")}
              >
                Unlink Task
              </Button>
              <Button
                style={{ width: "48%" }}
                hoverColor="danger"
                isLoading={isLoading}
                onClick={() => onDeleteToggle("delete")}
              >
                Delete Task
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SalesforceForm;
