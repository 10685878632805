import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {Icon} from "./Icon"

import { PopOver } from "./Modals";
import {
  APP_LAYOUT,
  COLORS,
  CONTENT_TYPE,
  SPACING,
  TYPE_LIST,
  TYPOGRAPHY,
} from "../constants";
import { Button } from "./index";

const Container = styled.div`
  display: flex;
`;

const FilterButton = styled(Button)`
  height: 35px;
  border-radius: 2px;
  cursor: pointer;
  width: calc(100% + 28px);
  margin: 0 -14px;
  background-color: transparent;
  color:  ${({theme}) => theme.text.color};
  &:hover, &.active{
    background-color: ${({theme}) => theme.colors.active}
  }
`;

const FilterDropdown = styled.div`
  display:flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  border-radius: 5px;
  transition: .2s;
  &:hover{
    background-color: ${({theme}) => theme?.button?.hover?.light || COLORS.chambray};
    color: ${({theme}) => theme?.button?.hoverText?.light || COLORS.white};
  }

`

const CaretIcon = styled(Icon)`
  &:hover{
    color: ${({theme}) => theme.text.color};
    text-align: center;
  }
`

const filters = [
  {
    name: "Week",
    startDate: moment().startOf("week").toDate(),
    endDate: moment().endOf("week").toDate(),
  },
  {
    name: "Month",
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
  },
  {
    name: "90 Days",
    startDate: moment().startOf("month").toDate(),
    endDate: moment().add(3, 'month').endOf("month").toDate(),
  },

  // {
  //   name: "Year",
  //   startDate: moment().startOf("year").toDate(),
  //   endDate: moment().endOf("year").toDate(),
  // },
  // {
  //   name: "Custom",
  //   startDate: "",
  //   endDate: "",
  // },
];

const DateFilter = (props) => {
  const { onDateChange, selectedDateFilter, theme, title } = props;
  const [selectedFilter, setSelectedFilter] = useState();
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("day").toDate()
  );
  const [customEndDate, setCustomEndDate] = useState(
    moment().endOf("day").toDate()
  );
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    if (selectedFilter) {
      filter();
    }
  }, [selectedFilter]);

  useEffect(() => {
    if(selectedDateFilter && !selectedFilter) {
      if(selectedDateFilter.name === "Custom") {
        setCustomStartDate(moment(selectedDateFilter.startDate).toDate());
        setCustomEndDate(moment(selectedDateFilter.endDate).toDate());
      }
      setSelectedFilter(selectedDateFilter)
    } else if(!selectedFilter) {
      setSelectedFilter(filters[2])
    }
  }, [selectedDateFilter]) 
  

  const filter = () => {
    const start =
      selectedFilter === "Custom" ? customStartDate : selectedFilter.startDate;
    const end =
      selectedFilter === "Custom" ? customEndDate : selectedFilter.endDate;
    onDateChange({
      startDate: start,
      endDate: end,
      filter: selectedFilter
    });
  };

  useEffect(() => {
    if(!selectedFilter) {
      setSelectedFilter(filters[2])
    }
  }, [])

  return (
    <Container>
      <FilterDropdown id="dateTitle" >
        <h3 style={{fontWeight: "bold", margin: 0, marginRight: SPACING.XS}}>{title}</h3>
        <CaretIcon type={"caretDown"} />
      </FilterDropdown> 
      <PopOver 
        target={"dateTitle"} 
        isOpen={popoverOpen}
        trigger="click"
        toggle={() => setPopoverOpen(!popoverOpen)}>
      {filters.map((filter, index) => (
        <FilterButton
          index={index}
          key={index}
          selected={filter.name === selectedFilter?.name}
          id={filter.name}
          className={selectedFilter && filter.name === selectedFilter.name && "active"}
          style={{...(theme.type === APP_LAYOUT.ZOOM && {
            backgroundColor:
              selectedFilter && filter.name === selectedFilter.name
                ? COLORS.azureRadiance
                : COLORS.waikawaGray,
          })}}
          onClick={() => {
            if (filter.name === "Custom") {
              setPopoverOpen(false);
            } else {
              setSelectedFilter(filter);
              setPopoverOpen(false);
            }
          }}
        >
          {filter.name === "Custom" && selectedDateFilter?.name === "Custom"
            ? `${moment(selectedDateFilter.startDate).format(
                "YYYY/MM/DD"
              )}-${moment(selectedDateFilter.endDate).format("YYYY/MM/DD")}`
            : filter.name}
        </FilterButton>
      ))}
      </PopOver>
 

      {/* <PopOver
        cardStyle={{
          padding: 0,
          paddingTop: SPACING.SM,
          paddingBottom: SPACING.SM,
          backgroundColor: COLORS.chambray,
        }}
        target={"Custom"}
        placement="bottom"
        toggle={() => {
          setPopoverOpen(!popoverOpen);
        }}
        isOpen={popoverOpen}
        //   title={CONTENT_TYPE?.[option.type]?.controlLabelPlural}
      >
        <div
          style={{
            display: "flex",
            width: "400px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ padding: 10 }}>Start date</div>
            <div style={{ padding: 10 }}>End date</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ padding: 10 }}>
              <DatePicker
                selected={customStartDate}
                onChange={(date) => setCustomStartDate(date)}
              />
            </div>
            <div style={{ padding: 10 }}>
              <DatePicker
                selected={customEndDate}
                onChange={(date) => setCustomEndDate(date)}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            // color="primary"
            onClick={() => {
              setPopoverOpen(false);
              setSelectedFilter({
                ...filters[3],
                startDate: customStartDate,
                endDate: customEndDate,
              });
            }}
            style={{
              marginLeft: 0,
              height: 35,
              backgroundColor: theme?.button?.default?.hover || COLORS.azureRadiance,
            }}
          >
            Apply
          </Button>
          {selectedFilter?.name === "Custom" && (
            <Button
              onClick={() => {
                setPopoverOpen(false);
                setSelectedFilter(null);
              }}
              style={{ marginLeft: 20, height: 35 }}
            >
              Clear
            </Button>
          )}
        </div>
      </PopOver> */}
    </Container>
  );
};

DateFilter.propTypes = {};

export default DateFilter;
