export const FIELD_TYPES = {
    STRING: "STRING",
    DATE: "DATE",
    ID: "ID",
    NUMBER: "NUMBER",
    EMAIL: "EMAIL",
    PHONE: "PHONE",
    INTEGER: "INTEGER",
    BOOL: "BOOL",
    TEXTAREA: "TEXTAREA",
    URL: "URL",
    PICKLIST: 'PICKLIST'
  }
  
  export const RECORD_TYPES = {
    ACCOUNT: "Account",
    CONTACT: "Contact",
    // CALLS: "Calls",
    LEAD: "Lead",
    NOTE: "Note",
    TASK: "Task",
  };
  
  export const QUERIES = {
    PREVIEW: "preview",
    CALLSTACK_ID: "callStackId",
    TRIGGER_ID: "triggerId",
    SOCIALPROOF_ID: "socialProofId",
    CONTACT_ID: "contactId",
    ACCOUNT_ID:'accountId',
    CALLFLOW_ID: "callFlowId",
    VERSION_ID: "versionId",
    GRID_ID: "gridId",
    GRID_ACTIVITY_ID: "gridActivityId",
    GRID_ACTIVITY_TASK_ID: 'gridActivityTaskId',
    CALL_TO: 'callTo'
  }
  
  export const CONTENT_RECORD = {
    [RECORD_TYPES.ACCOUNT] : {
      contentLabel: 'Accounts',
      icon: 'building',
      hash: 'add',
      interaction_id_label: 'accountId'
    },
    [RECORD_TYPES.CONTACT] : {
      contentLabel: 'Contacts',
      icon: 'phoneBook',
      hash: 'addContact',
      interaction_id_label: 'contactId'
    }
  }
    
  export const CRM_TYPES = {
    SALESFORCE: "salesforce",
    PIPEDRIVE: "pipedrive"
  };
  
  export const EMAIL_AUTOMATION_TYPES = {
    OUTREACH: "outreach"
  };
  
  export const TASKS_TYPES = {
    EMAIL: {
      dataLabel: 'email',
      label: 'Email'
    },
    CALL: {
      dataLabel: 'call',
      label: 'Call'
    },
    LINKEDIN_MESSAGE: {
      dataLabel: 'linkedInMessage',
      label: "LinkedIn Message"
    },
    LINKEDIN_CONNECT: {
      dataLabel: 'linkedInConnect',
      label: 'LinkedIn Connect'
    }
  }
  
  export const TABLE_LABELS = {
    ACTIVE: "Active Contacts",
    CALLS: "Calls",
    COMPLETED: "Completed",
    EMAILS_OPEN: "Emails Opened",
    EMAILS_SENT: "Emails Sent",
    UNSUBSCRIBED: "Unsubscribed",
    OWNER: 'Owner'
  }
  
  export const TABLE_HEADERS = {
    NAME: "Name",
    DETAILS: "Details",
    SUBJECT: "Subject Line",
    ACTIVITY: "Activity",
    SENT: 'Sent',
    DATES: "Dates",
    COMPLETED: 'Completed',
    OUTCOME: 'Outcome',
    LAST_ACTIVITY: 'Last Activity',
    SCHEDULED: "Scheduled",
    CALLED: 'Called'
  }
  
  
  export const DEFAULT_ANALYTICS_DATA = [
    {
      label: TABLE_LABELS.ACTIVE,
      dataLabel: "activeContacts",
      total: 0,
    },
    {
      label: TABLE_LABELS.EMAILS_SENT,
      dataLabel: "emails",
      total: 0,
    },
    {
      label: TABLE_LABELS.EMAILS_OPEN,
      dataLabel: "emailsOpened",
      total: 0,
    },
    {
      label: TABLE_LABELS.CALLS,
      dataLabel: "calls",
      total: 0,
    },
    {
      label: TABLE_LABELS.COMPLETED,
      dataLabel: "completed",
      total: 0,
    },
    {
      label: TABLE_LABELS.UNSUBSCRIBED,
      dataLabel: "unsubscribed",
      total: 0,
    }
  ];
  
  
  export const PHONE_TYPES = ['Mobile', 'Work', 'Home', 'Other']
  export const EMAIL_TYPES = ['Personal','Work', 'Other']
  
  
  export const SUB_FIELDS_TYPES = {
    [FIELD_TYPES.PHONE] :{
      phoneNumber: {
        type: FIELD_TYPES.PHONE,
        label: 'Phone Number',
        field: 'phoneNumber',
        isDefault: true
      },
      extension: {
        type: FIELD_TYPES.STRING,
        label: 'Extension',
        field: 'extension',
      },
      countryCode: {
        type: FIELD_TYPES.STRING,
        label: 'Country Code',
        field: 'countryCode',
      },
      areaCode: {
        type: FIELD_TYPES.STRING,
        label: 'Area Code',
        field: 'areaCode',
  
      },
      country: {
        type: FIELD_TYPES.STRING,
        label: 'Country',
        field: 'country',
      },
      type: {
        type: FIELD_TYPES.STRING,
        values: PHONE_TYPES,
        label: 'Type',
        field: 'type',
      },
      primary : {
        type: FIELD_TYPES.BOOL,
        label: 'Is Primary?',
        field: 'primary',
      }
    },
    [FIELD_TYPES.EMAIL] :{
      email: {
        type: FIELD_TYPES.EMAIL,
        label: 'Email',
        isDefault: true,
        field: 'email',
      },
      type: {
        type: FIELD_TYPES.STRING,
        values: PHONE_TYPES,
        label: 'Type',
        field: 'type',
      },
      primary : {
        type: FIELD_TYPES.BOOL,
        label: 'Is Primary?',
        field: 'primary',
      }
    }
  }
  