import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import {
  addRecord,
  getContactLayout,
  getCoreFields,
  getRecord,
  getRecords,
  updateRecord,
} from "../../redux/actions/content";
import {
  deleteContactGrids,
  updateMeetingRecords,
  updateParticipantRecords,
} from "../../redux/actions/meetings";
import { getCustomTokenClaim } from "../../redux/actions/users";
import { Icon, ICON_TYPES } from "../../shared/ui/Icon";
import { FieldType } from "../../shared/ui/FieldType";
import LoaderDefault from "../../shared/ui/Loader";
import { Modal, ModalBody, ModalFooter } from "../../shared/ui/Modals";
import { Button } from "../../shared/ui/Buttons";
import { FlexContainer, ScrollContainer } from "../../shared/ui/Containers";
import { InputForm } from "../../shared/ui/Inputs";
import { SPACING, TYPOGRAPHY } from "../../shared/constants";
import styled from "styled-components";
import { Card } from "../../shared/ui/Cards";
import UpmarketLogo from "../../media/logos/Upmarket_White_Logo.png";
import { ToolTipItem } from "../../shared/ui/ToolTips";

const Container = styled.div`
  height: 600px;
`;

const Loader = styled(LoaderDefault)`
 margin-top: -100px;
`

const Contact = ({
  onMeetingSelected,
  interactionId,
  userId,
  type,
  modalToggle,
}) => {
  const { currentUser, getCustomToken } = useAuth();
  const [params, setParams] = useState(false);
  const [isCreateContact, setIsCreateContact] = useState(false);
  const [isEditView, setIsEditView] = useState(false);
  const [contactInfo, setContactInfo] = useState(false);
  const [searchContact, setSearchContact] = useState("");
  const [contacts, setContacts] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [participant, setParticipant] = useState(false);
  // const [contacts, setContacts] = useState(false);
  const [coreFields, setCoreFields] = useState(false);
  const [layoutFields, setLayoutFields] = useState(false);
  const meetings = useSelector((state) => state.meetings.meetings);
  const { claims } = useSelector((state) => state.member);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const companyId = claims.companyId;

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0); // to rerender after localUpdate

  const handleContactCreate = () => {
    setIsCreateContact(!isCreateContact);
  };

  const handleSearchText = async () => {
    console.log("handleSearchText called");
    setIsLoading(true);
    let contacts = await getRecords(companyId, "Contact", searchContact);
    console.log("data-->", contacts);
    if (contacts?.data) {
      console.log("data-->", contacts?.data);
      setContacts(contacts.data);
      forceUpdate();
    }
    setIsLoading(false);
  };

  const handleSearch = (event) => {
    console.log("handleSearch");
    let searchText = event.target.value.trim();
    setSearchContact(searchText);
    // getRecords(null,companyId,searchText, 'Contact',searchText).then(data=>{
    //     console.log('data', data);
    // })
  };

  const handleCancelCreate = () => {
    setIsCreateContact(!isCreateContact);
  };

  const handleCreate = async () => {
    setIsLoading(true);
    const { data } = await addRecord(companyId, "Contact", contactInfo);

    setContactInfo({ ...contactInfo, id: data });
    handleAssignContact({ ...contactInfo, id: data });
    setIsLoading(false);
  };

  const handleConfirmDelete = () => {
    console.log("confirm delete");
    setIsDelete(true);
  };

  const handleDelete = async () => {
    setIsDelete(false);
    setIsLoading(true);
    if (contactInfo) {
      contactInfo.deleted = true;
    }
    await updateRecord(companyId, "Contact", contactInfo.id, { deleted: true });
    if (contactInfo.Grids) {
      Object.keys(contactInfo.Grids).map((grid) => {
        let gridItem = contactInfo.Grids[grid];
        deleteContactGrids(
          companyId,
          grid,
          gridItem.activityId,
          gridItem.taskId
        );
      });
    }
    handleUnlinkContact();
    setIsLoading(false);
  };

  const handleSave = async () => {
    await updateRecord(companyId, "Contact", contactInfo.id, contactInfo);
    handleAssignContact(contactInfo);
  };

  const handleChange = (event, type) => {
    setContactInfo({ ...contactInfo, [type]: event.target.value });
  };

  const getCoreRecordFields = async () => {
    setIsLoading(true);
    const coreFields = await getCoreFields(companyId, "Contact");
    const layoutFields = await getContactLayout(companyId, "Contact");
    if (coreFields && layoutFields) {
      console.log("getContactLayout-->", coreFields);
      setCoreFields(coreFields);
      setLayoutFields(layoutFields);
      forceUpdate();
    }
    setIsLoading(false);
  };

  const handleAssignContact = (contact) => {
    if (!isLoading) setIsLoading(true);
    console.log("assignContact", contact);
    let contactName = contact.first_name
      ? contact.first_name + " " + contact.last_name
      : contact.firstName + " " + contact.lastName;
    let values = {
      contactId: contact.id,
      contactName: contactName,
      account_id: contact.account_id ? contact.account_id : contact.accountId,
      accountName: false,
      isContactLinked: true,
    };
    console.log("values", values);
    console.log(params.interactionId);
    updateParticipantRecords(
      companyId,
      params.interactionId,
      params.userId,
      values
    )
      .then(() => {
        console.log("done");
        setIsLoading(false);
        window.location.reload();
        if (modalToggle) {
          modalToggle();
        } else {
          navigate(-1);

        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const toggleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleUnlinkContact = () => {
    let values = {
      contactId: false,
      contactName: false,
      account_id: false,
      accountName: false,
      isContactLinked: false,
    };

    updateParticipantRecords(
      companyId,
      params.interactionId,
      params.userId,
      values
    ).then(() => {
      console.log("done");
      if (modalToggle) {
        modalToggle();
      } else {
        // navigate(-1);
      }
      window.location.reload();

    });
  };

  const handleNavApp = async () => {
    console.log("handleNavApp");
    const token = await getCustomToken(currentUser.uid);
    window.open(
      `https://app.upmarket.ai/loading?customToken=${token}&returnUrl=Contact/${contactInfo.id}?preview=true`
    );
  };

  const handleFromNav = async () => {
    let urlParams = new URLSearchParams(window.location.search);
    console.log(
      "u",
      userId,
      " i",
      interactionId,
      " t",
      type,
      "SEARCH ",
      window.location.search
    );
    let contactType = type || urlParams.get("type");
    let userId_ = userId || urlParams.get("userId");
    console.log("userId", userId);
    let interactionId_ = interactionId || urlParams.get("interactionId");
    setParams({ contactType, userId: userId_, interactionId });
    switch (contactType) {
      case "userCaution":
        let meetingParticipant = meetings[interactionId_].participants[userId_];
        // setParticipant(meetingParticipant);
        console.log("value-->", meetingParticipant);
        setContacts(meetingParticipant.contacts);
        break;
      case "userCheck":
        console.log("meetings", meetings);
        let contactId =
          meetings[interactionId_].participants[userId_]?.contactId;
        console.log(contactId, "ID -");
        let contactDetails = await getRecord(companyId, "Contact", contactId);
        setContactInfo(contactDetails ? { ...contactDetails } : false);
        setIsEditView(true);

        console.log("contacts-->", contactDetails);
        break;
      case "userPlus":
        setIsEditView(false);
        break;
      default:
        break;
    }
  };

  const setFieldValue = (field, value) => {
    if (field.includes("_")) {
      const [fieldVar, subField, type] = field.split("_");
      setContactInfo({
        ...contactInfo,
        [fieldVar]: {
          ...(contactInfo?.[fieldVar] || {}),
          [subField]: value,
        },
      });
      //   this.setState(, () => {
      if (subField == "primary" && value) {
        let fields = coreFields["Contact"] || [];
        let findPrimary = Object.keys(fields).filter(
          (field) => fields[field].type == type && contactInfo[field]?.primary
        );
        if (findPrimary.length) {
          let primaryFields = {};
          findPrimary.map((f) => {
            if (f !== fieldVar) {
              primaryFields[f] = {
                ...contactInfo[f],
                primary: false,
              };
            }
          });
          setContactInfo({
            ...contactInfo,
            ...primaryFields,
          });
        }
      }
      //   } )
    } else {
      setContactInfo({
        ...contactInfo,
        [field]: value,
      });
    }
  };

  const handleFieldChange = (e) => {
    console.log("handleFieldChange?");
    const { name, value } = e.target;
    let valueTemp;
    switch (value) {
      case "true":
        valueTemp = true;
        break;
      case "false":
        valueTemp = false;
        break;
      default:
        valueTemp = value;
        break;
    }
    console.log("valueTemp", valueTemp, name, contactInfo);
    if (name.includes("_")) {
      const [field, subField, fieldType] = name.split("_");
      console.log(field, subField, fieldType);
      setContactInfo({
        ...contactInfo,
        [field]: {
          ...(contactInfo[field] || {}),
          [subField]: valueTemp,
        },
      });
    } else {
      setContactInfo({
        ...contactInfo,
        [name]: valueTemp,
      });
      // let contactDetail = {...contactInfo,[name]:valueTemp}
      // setContactInfo({ ...contactDetail});
    }
  };

  useEffect(async () => {
    getCoreRecordFields();
    handleFromNav();
  }, []);

  return (
    <Container style={{ height: 600 }}>
      {/* {isLoading ? <Loader /> : null} */}
      {!isLoading && isDelete && (
        <Modal
          title={`Remove Contact`}
          id={"removeField"}
          toggle={toggleDelete}
          isOpen={isDelete}
        >
          <ModalBody>Delete this contact?</ModalBody>
          <ModalFooter>
            <FlexContainer style={{ justifyContent: "flex_end" }}>
              <Button color="danger" onClick={handleDelete}>
                Delete
              </Button>
            </FlexContainer>
          </ModalFooter>
        </Modal>
      )}
      {!isCreateContact && !isEditView ? (
        <div>
          <FlexContainer
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <FlexContainer style={{ alignItems: "center" }}>
              <h3 style={{ margin: 0, marginRight: 14 }}>Find Contact</h3>
              <Button
                onClick={handleContactCreate}
                size={"sm"}
                className="ghost-btn sm-btn"
              >
                Create
              </Button>
            </FlexContainer>
            <Icon
              className="modal_header-close"
              type={ICON_TYPES.close}
              style={{ fontSize: TYPOGRAPHY.h3 }}
              onClick={modalToggle}
            />
          </FlexContainer>
          <br />
          <FlexContainer
            style={{ alignItems: "center", marginBottom: SPACING.MD }}
          >
            <InputForm
              type="text"
              name="searchContact"
              placeholder="Search name"
              value={searchContact}
              onChange={(e) => handleSearch(e)}
              onBlur={handleSearchText}
              style={{ margin: 0, marginRight: SPACING.SM, width: 400 }}
            />
            <Button
              onClick={(e) => handleSearchText(e)}
              className="ghost-btn sm-btn"
            >
              Search
            </Button>
          </FlexContainer>
          <div>
            <div className="p">Result</div>
            {params.contactType == "userCaution" ? (
              <h5 className="body-small">
                Multiple contacts were found for this name, select one or select
                an option below
              </h5>
            ) : null}
            <ScrollContainer height={450}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {contacts &&
                    contacts.length > 0 &&
                    contacts.map((item, index) => {
                      return (
                        <Card key={index}>
                          <FlexContainer
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {item?.first_name
                                ? item.first_name + " " + item?.last_name
                                : item?.firstName + " " + item?.lastName}
                              <br />
                              {item?.city}
                            </div>
                            <div>
                              <Button
                                color="light"
                                onClick={() => handleAssignContact(item)}
                              >
                                Assign
                              </Button>
                            </div>
                          </FlexContainer>
                        </Card>
                      );
                    })}
                </>
              )}
            </ScrollContainer>
          </div>
        </div>
      ) : (
        <div>
          {isEditView ? (
            <div>
              <FlexContainer
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FlexContainer style={{ alignItems: "center" }}>
                  <h3 style={{ margin: 0, marginRight: 14 }}>
                    Thomas Morris
                  </h3>
                  <span
                    style={{
                      marginRight: 14,
                      marginTop: -4,
                    }}
                  >
                    <ToolTipItem
                      itemId={"upmarket_contact_redirect"}
                      label="See in Upmarket"
                    >
                      <img
                        onClick={handleNavApp}
                        src={UpmarketLogo}
                        alt="Upmarket Logo"
                        style={{ width: 30, height: "auto", cursor: "pointer" }}
                      />
                    </ToolTipItem>
                  </span>
                  <span>
                    <ToolTipItem
                      itemId={"contact_unlink"}
                      label="Unlink Contact"
                    >
                      <Icon
                        style={{ color: "#fff", cursor: "pointer" }}
                        onClick={handleUnlinkContact}
                        type={"unLink"}
                      />
                    </ToolTipItem>
                  </span>
                </FlexContainer>
                <span>
                  <Icon
                    className="modal_header-close"
                    type={ICON_TYPES.close}
                    style={{ fontSize: TYPOGRAPHY.h3 }}
                    onClick={modalToggle}
                  />
                </span>
              </FlexContainer>
              <br />
              <ScrollContainer height={500}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <FlexContainer
                      style={{
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        paddingTop: 4,
                      }}
                    >
                      {Object.keys(layoutFields) &&
                        Object.keys(layoutFields).length > 0 &&
                        Object.keys(layoutFields).map((f, i) => {
                          const field = layoutFields[f].field;
                          const fieldData = coreFields[field];
                          return (
                            <div
                              style={{
                                width:
                                  fieldData.type == "PHONE" ||
                                  fieldData.type == "EMAIL"
                                    ? `100%`
                                    : `calc(50% - ${SPACING.SM / 2}px)`,
                              }}
                            >
                              <FieldType
                                onClick={setFieldValue}
                                key={i}
                                field={fieldData}
                                required={fieldData.required}
                                handleChange={handleFieldChange}
                                name={`${field}`}
                                value={contactInfo[`${field}`]}
                              />
                            </div>
                          );
                        })}
                    </FlexContainer>
                  </>
                )}
              </ScrollContainer>
              <FlexContainer
                style={{
                  justifyContent: "space-between",
                  padding: `${SPACING.SM}px 0 0`,
                }}
              >
                <Button
                  disabled={isLoading}
                  onClick={handleConfirmDelete}
                  style={{ marginRight: 14 }}
                >
                  Delete
                </Button>
                <Button
                  disabled={isLoading}
                  onClick={handleSave}
                  className="active"
                >
                  Save
                </Button>
              </FlexContainer>
            </div>
          ) : (
            <div>
              <FlexContainer style={{ justifyContent: "space-between" }}>
                <h3 style={{ color: "#fff" }}>Add Contact</h3>
                <Icon
                  className="modal_header-close"
                  type={ICON_TYPES.close}
                  style={{ fontSize: TYPOGRAPHY.h3 }}
                  onClick={modalToggle}
                />
              </FlexContainer>
              <br />
              <ScrollContainer height={490}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <FlexContainer
                      style={{
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        paddingTop: 4,
                      }}
                    >
                      {Object.keys(layoutFields) &&
                        Object.keys(layoutFields).length > 0 &&
                        Object.keys(layoutFields).map((f, i) => {
                          const field = layoutFields[f].field;
                          const fieldData = coreFields[field];

                          return (
                            <div
                              style={{
                                width:
                                  fieldData.type == "PHONE" ||
                                  fieldData.type == "EMAIL"
                                    ? `100%`
                                    : `calc(50% - ${SPACING.SM / 2}px)`,
                              }}
                            >
                              <FieldType
                                onClick={setFieldValue}
                                key={i}
                                field={fieldData}
                                required={fieldData.required}
                                handleChange={handleFieldChange}
                                name={`${field}`}
                                value={contactInfo[`${field}`]}
                              />
                            </div>
                          );
                        })}
                    </FlexContainer>
                  </>
                )}
              </ScrollContainer>

              <FlexContainer
                style={{
                  justifyContent: "flex-end",
                  padding: `${SPACING.SM}px 0 0`,
                }}
              >
                <Button
                  onClick={handleCreate}
                  className="active"
                  style={{ marginRight: 14 }}
                  disabled={isLoading}
                >
                  Create
                </Button>
                <Button
                  onClick={handleCancelCreate}
                  disabled={isLoading}
                  className="ghost-btn sm-btn"
                >
                  Back
                </Button>
              </FlexContainer>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default Contact;
