import styled from "styled-components";
import { BORDER, COLORS, SPACING } from "../constants";

const AuthContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: ${SPACING.MD * 2}px ${SPACING.MD *2}px;
  text-align: left;
  max-width: 450px;
  width: 100%;
  background: ${COLORS.softBlack};
  border-radius: ${BORDER.radius_lg}px;
  border: 1px solid ${BORDER.color};
`;

export default AuthContainer;
