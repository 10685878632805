import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import useClickTarget from './clickTarget.hook';



const ClickWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ClickDetector = ({children, handleClick, targetId = false}) => {
  const wrapperRef = useRef(null);
  const [clickInside, clickOutside, event] = useClickTarget(wrapperRef, targetId);
  useEffect(() => {
    if( clickInside == null || clickOutside == null || event == null ) return
    handleClick(clickInside, clickOutside, event)
  }, [clickInside, clickOutside, event])
  return <ClickWrapper ref={wrapperRef} targetId={targetId} >{children}</ClickWrapper>;
}

export default ClickDetector;
