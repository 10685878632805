const config = {
  apiKey: "AIzaSyDCKZ8V9JMO9rw5tl_a0XgqUCh9p0--Hf4",
    // authDomain: "accountheroes.firebaseapp.com",
  authDomain: "app.upmarket.ai",
  databaseURL: "https://accountheroes.firebaseio.com",
  projectId: "accountheroes",
  storageBucket: "accountheroes.appspot.com",
  messagingSenderId: "609765755230",
  appId: "1:609765755230:web:4fa419c9e1d02b128da7cf",
  measurementId: "G-NZDG7RQ0YS"
};

export default config;