import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FIREBASE_ERRORS } from "../../shared/constants";
import { updateMeetingLabel } from "../../redux/actions/meetings";
import { Button } from "../../shared/ui/Buttons";
import { FlexContainer } from "../../shared/ui/Containers";
import { InputForm } from "../../shared/ui/Inputs";
import { Modal, ModalBody } from "../../shared/ui/Modals";
import {  ENV, RUNNING_ENV } from "../../shared/constants";

const EditCallTitleModal = ({
  isOpen,
  toggle,
  interactionId,
  onSuccess,
  interaction,
  isPrep = false,
}) => {
  const [values, setValues] = useState({
    recordingTopic: true,
    meetingTopic: true,
    topic: interaction?.topic || "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const { info } = useSelector((state) => state.users);
  let test = RUNNING_ENV === ENV.TEST;
  const dev = RUNNING_ENV === ENV.DEV;
  let { companyId, uid } = info || {};

  const handleChange = (e) => {
    const { name, type, value } = e.target;
    let updatedValues = { ...values };
    if (type === "checkbox") {
      updatedValues = { ...values, [name]: !values[name] };
    } else {
      updatedValues = { ...values, [name]: value };
    }
    setValues(updatedValues);
  };

  const handleTopicChange = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!isPrep) {
        await updateMeetingLabel(
          companyId,
          interactionId,
          values.topic,
          dev,
          test,
          uid,
          interaction?.uuid,
          values.meetingTopic,
          values.recordingTopic
        );
      }
      toggle();
      onSuccess({ topic: values.topic });
    } catch (error) {
      let message = error;
      if (error?.code) {
        message = FIREBASE_ERRORS[error.code];
      }
      toast(message, {
        type: "error",
        hideProgressBar: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      topic: interaction?.topic || "",
    });
  }, [interaction]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={"Update topic"}
      showClose={false}
    >
      <ModalBody>
        <form onSubmit={handleTopicChange}>
          <InputForm
            required
            name="topic"
            type="text"
            value={values?.topic}
            onChange={handleChange}
            placeholder={"Add meeting topic"}
          />

          <FlexContainer style={{ justifyContent: "flex-end" }}>
            <Button type="submit" isLoading={isLoading}>
              Update
            </Button>
          </FlexContainer>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default EditCallTitleModal;
