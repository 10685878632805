export const initialState = {
  claims: {},
};

export default function memberReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_USER_CLAIMS": {
      return {
        ...state,
        claims: {
          ...action.data,
        },
      };
    }
    case "UPDATE_USER_SALESFORCE_INFO": {
      return {
        ...state,
        salesforce: {
          ...action.data,
        },
      };
    }

    default:
      return state;
  }
}
