import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListGroup, ListGroupItem } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";

import { FIELD_TYPES } from "shared/constants/recordTypes";

import {
  getSfdc,
  patchSfdc,
  postSfdc,
  searchSalesforce,
  updateRecordFieldOrder,
} from "../../redux/actions/meetings";
import moment from "moment";
import { Button } from "../../shared/ui/Buttons";
import SearchInput from "../ui/SearchInput";
import { FieldType } from "../../shared/ui/library";
import { ScrollContainer } from "../../shared/ui/Containers";


const SalesforceRecordForm = ({ type, isAddMode, setIsAddMode, handleTaskRecordSelect }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const users = useSelector((state) => state.users);
  const content = useSelector((state) => state.content);
  const dispatch = useDispatch();
  let fields = content?.salesforce?.fields?.[type] || [];
  fields = fields?.filter(
    (val) =>
      val.updateable && !(val.type === "reference" || val.type === "picklist")
  );

  let { uid, companyId } = users?.info || {};
  const layouts = users?.info?.layouts?.zoom?.salesforce?.[type];

  const handleRecordSelect = async (val) => {
    let recordResponse = null;

    if(val) {
      recordResponse = await getSfdc(`sobjects/${type}/${val.Id}`, companyId, uid);
      const responseUrl = Object.keys(recordResponse)[0];
      recordResponse = recordResponse[responseUrl];
    }
    const tempFields = [];

    fields.map((field) => {
      let type = "";

      switch (field.type) {
        case "string":
          type = FIELD_TYPES.STRING;
          break;
        case "boolean":
          type = FIELD_TYPES.BOOL;
          break;
        case "double":
          type = FIELD_TYPES.NUMBER;
          break;
        case "textarea":
          type = FIELD_TYPES.TEXTAREA;
          break;
        case "email":
          type = FIELD_TYPES.EMAIL;
          break;
        case "phone":
          type = FIELD_TYPES.PHONE;
          break;
        case "date":
          type = FIELD_TYPES.DATE;
          break;
        case "datetime":
          type = FIELD_TYPES.DATETIME;
          break;
        default:
          console.log(field.name, field.type);
          break;
      }
      tempFields.push({
        value: recordResponse ? recordResponse[field.name]: null,
        name: field.name,
        label: field.label,
        type: type,
        nillable: field.nillable,
      });
    });

    if(layouts?.length) {
      tempFields.sort((a, b) => {  
        return layouts.indexOf(a.name) - layouts.indexOf(b.name);
      });
    }


    // console.log(tempFields);
    setFormFields(tempFields);
    setSelectedRecord(recordResponse);
    setSearchResults([]);
  };

  const handleOnRecordChange = (e) => {
    const name = e.target.name;
    let tempFields = [...formFields];
    let index = tempFields.findIndex((val) => val.name === name);
    let field = tempFields[index];

    field = {
      ...field,
      value: e.target.value,
      updated: true,
    };
    tempFields.splice(index, 1, field);
    setFormFields(tempFields);
  };

  const onSearch = async (text) => {
    const response = await searchSalesforce(text, uid, companyId, type);
    if (response) {
      const requestUrl = Object.keys(response)[0];
      const data = response[requestUrl];
      setSearchResults(data.searchRecords);
    }
  };

  const onDragEnd = async(result) => {
    const newItems = [...formFields];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setFormFields(newItems);
    dispatch(updateRecordFieldOrder(companyId, uid, type, newItems.map(val => val.name)));
  };

  const handleFormSubmit = async () => {
    let formValues = {};
    formFields
      .filter((val) => val.updated)
      .map((val) => {
        if(val.type === FIELD_TYPES.DATETIME || val.type === FIELD_TYPES.DATE) {
          formValues[val.name] =  moment(val.value).utc().format("YYYY-MM-DDThh:mm:ss.sssZ");
        } else {
          formValues[val.name] = val.value;
        }
      });

    try {
      if(!isAddMode) {
        await patchSfdc(`sobjects/${type}/${selectedRecord.Id}`, formValues, companyId, uid);
        toast("Successfully Updated", {
          hideProgressBar: true,
        });
      } else {
        let createResponse = await postSfdc(`sobjects/${type}/`, formValues, companyId, uid);
        if (createResponse) {
          let createRequestUrl = Object.keys(createResponse)[0];
          const data = createResponse[createRequestUrl];

          createResponse = await getSfdc(`sobjects/${type}/${data.id}`, companyId, uid);
          createRequestUrl = Object.keys(createResponse)[0];
          createResponse = createResponse[createRequestUrl];
          const {Name, Id} = createResponse;

          setIsAddMode(false);

          handleTaskRecordSelect({
            Name, 
            Id, 
            attributes: {
              type
            }
          });
          toast("Successfully Created", {
            hideProgressBar: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast("Error updating record", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if(isAddMode) {
      handleRecordSelect(null);
    }
  
  }, [isAddMode])
  

  const updated = formFields?.map((val) => val.updated)?.length;

  return (
    <>
      {!isAddMode && (
        <div>
          <SearchInput onSearch={onSearch} />
        </div>
      )}
      <div>
        <ListGroup>
          {searchResults?.map((val) => {
            return (
              <ListGroupItem
                onClick={() => handleRecordSelect(val)}
                key={val["Id"]}
                style={{ cursor: "pointer" }}
              >
                {val?.Name || ""}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div>
      <div>
      <ScrollContainer>
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}
                style={{ height: "45vh", margin: "-5px", paddingTop: "10px" }}>
                  {formFields?.length > 0 &&
                    formFields.map((val, index) => {
                      return (
                        <Draggable
                          key={val.name}
                          draggableId={val.name}
                          index={index}
                          style={{padding: 0}}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{height: "auto", padding:10}}
                            >
                              <FieldType
                                moreDisabled={
                                  val.type === FIELD_TYPES.PHONE ||
                                  val.type === FIELD_TYPES.EMAIL
                                }
                                style={{width: "100%",  marginBottom: 0}}
                                value={val.value}
                                onClick={(name, value) =>
                                  handleOnRecordChange({
                                    target: { name, value },
                                  })
                                }
                                handleChange={handleOnRecordChange}
                                key={val.name}
                                name={val.name}
                                required={val.nillable}
                                preserverFieldName
                                field={{ label: val.label, type: val.type }}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </ScrollContainer>
      </div>
      <div >
        <Button  
          onClick={handleFormSubmit} 
          disabled={!updated} 
          color="primary"
          full
          style={{marginTop: 14}}
        >
          {isAddMode ? "Create": "Update"}
        </Button>
      </div>
    </>
  );
};

export default SalesforceRecordForm;
