import { Modal, ModalBody } from "shared/ui/Modals";
import { InputForm } from "shared/ui/Inputs";
import { Button } from "shared/ui/Buttons";
import { useEffect, useState } from "react";
import { shareMeetingEmail } from "../../../redux/actions/meetings";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Form } from "reactstrap";
import { useSelector } from "react-redux";
import { LoaderIcon } from "shared/ui/Icon";

const ShareMeetingModal = ({ isOpen, toggle, meetingLabel }) => {
  const { info } = useSelector(state => state.users || {});
  const name = (info?.firstName || "" ) + " " + (info?.lastName || "")
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let { accessCode, interactionId } = useParams();
  const shareMeeting = async (e) => {
    e.preventDefault();
    if (!interactionId)
      return toast("Failed to share, try again", { hideProgressBar: true });
    setIsLoading(true);
    try {
      await shareMeetingEmail({interactionId, email, name, meetingLabel, userEmail: info?.email});
      toggle();
      toast("Meeting shared", { hideProgressBar: true });
    } catch (e) {
      console.log(e, 'ERROR')
      toast("Failed to share, try again", { hideProgressBar: true });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setEmail("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} title={"Share Meeting"}>
      {accessCode ? (
        <ModalBody>
          <p>This meeting has been shared</p>
        </ModalBody>
      ) : (
        <ModalBody>
          <Form onSubmit={(e) => isLoading ? false : shareMeeting(e)}>
            <InputForm
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Button
              type="submit"
              style={{ marginLeft: "auto" }}
              isLoading={isLoading}
            >
              Send
            </Button>
          </Form>
     
        </ModalBody>
      )}
    </Modal>
  );
};

export default ShareMeetingModal;
