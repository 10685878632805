import React, { useEffect, useState } from "react";
import { Col, Input, Row } from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { generatePushID } from "../../shared/utils/keyGenerator";
import { Button } from "../../shared/ui/Buttons";
import { InputForm, SelectList } from "../../shared/ui/Inputs";
import { FlexContainer, ScrollContainer } from "../../shared/ui/Containers";
import { BORDER, SPACING } from "../../shared/constants";

const TeamInviteForm = () => {
  const [inviteList, setInviteList] = useState([]);

  const user = useSelector((state) => state.users);
  const org = user?.org;
  const roles = org?.roles?.length
    ? org.roles.map((val, index) => ({ value: index, label: val.name }))
    : [];

  const departments = org?.departments
    ? Object.keys(org.departments).map((key) => ({
        label: org.departments[key]?.name,
        value: key,
        teams: org.departments[key]?.teams,
      }))
    : [];

  const getTeamOptions = (team) => {
    if (team) {
      return Object.keys(team).map((key) => ({
        label: team[key]?.name,
        value: key,
      }));
    }
    return [];
  };

  const handleChange = (e, id, index) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log("CAME IN", e, id, index);
    let record = inviteList.find((val) => val.id === id);
    if (record) {
      record[name] = value;
      if (value.teams) {
        record.teams = getTeamOptions(value.teams);
        record.team = record.teams[0];
      }
      console.log("RECORD", record);
      let tempRecords = [...inviteList];
      tempRecords.splice(index, 1, record);

      console.log(tempRecords);
      setInviteList(tempRecords);
    }
  };

  const getFieldRow = () => {
    const defaultDepartment = departments?.length ? departments[0] : null;
    const defaultTeams =
      departments?.length && departments[0]?.teams
        ? getTeamOptions(departments[0].teams)
        : [];
    const defaultTeam = defaultTeams?.length ? defaultTeams[0] : null;
    const defaultRole = roles?.length ? roles[0] : null;

    return {
      id: generatePushID(),
      firstName: "",
      lastName: "",
      email: "",
      department: defaultDepartment,
      teams: defaultTeams,
      team: defaultTeam,
      role: defaultRole,
    };
  };

  const handleAddRow = () => {
    const row = getFieldRow();
    setInviteList([...inviteList, row]);
  };

  useEffect(() => {
    handleAddRow();
  }, []);

  return (
    <>
      <Row style={{ margin: "10px" }}>
        <FlexContainer style={{justifyContent: 'flex-end'}}>
          <Button onClick={handleAddRow} className="ghost-btn sm-btn">
            Add More
          </Button>
        </FlexContainer>
      </Row>
      <ScrollContainer height={700}>
      {inviteList?.length
        ? inviteList.map((val, index) => {
            return (
              <div key={val.id} style={{ margin: `10px 10px ${SPACING.LG}px`, borderBottom: `1px solid ${BORDER.color}`}}>
                <p>{index + 1}. Team Member </p>
                <FlexContainer style={{ justifyContent: 'space-between'}}>
                  <InputForm
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={val.firstName}
                    onChange={(e) => handleChange(e, val.id, index)}
                    style={{
                      width: `calc(50% - ${SPACING.MD/2}px)`,
                    }}
                  />
                   <InputForm
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={val.lastName}
                    onChange={(e) => handleChange(e, val.id, index)}
                    style={{
                      width: `calc(50% - ${SPACING.MD/2}px)`,


                    }}
                  />
                </FlexContainer>
           
              
                  <InputForm
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={val.email}
                    onChange={(e) => handleChange(e, val.id, index)}
                  />
                
                  <SelectList
                    name="department"
                    placeholder="Department"
                    options={departments}
                    value={val.department}
                    onChange={(option) => {
                      handleChange(
                        { target: { name: "department", value: option } },
                        val.id,
                        index
                      );
                    }}
                  />
                  <SelectList
                    name="team"
                    placeholder="Team"
                    options={val.teams}
                    value={val.team}
                    onChange={(option) => {
                      handleChange(
                        { target: { name: "team", value: option } },
                        val.id,
                        index
                      );
                    }}
                  />
                  <SelectList
                    placeholder="Role"
                    name="role"
                    options={roles}
                    value={val.role}
                    onChange={(option) => {
                      handleChange(
                        { target: { name: "role", value: option } },
                        val.id,
                        index
                      );
                    }}
                  />
              </div>
            );
          })
        : null}
      </ScrollContainer>
     
    </>
  );
};

export default TeamInviteForm;
