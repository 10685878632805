import styled from "styled-components";
import sphere from '../assets/images/sphere.gif';
import Loader from 'react-loader-spinner';

const LoaderStyled = styled.div`
.body-center {
  // margin-top: 250px;
  // background-color: #240F52; 
  // background-image: linear-gradient(0deg, #1B1B1B, #1B1B1B);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  height: 250px;
}

.outer-ring {
  position: absolute;
  left: calc(50% - 85px);
  height: 160px;
  width: 160px;
  background-image: linear-gradient(120deg, #1B1B1B 10%, #1B1B1B 20%, #1B1B1B 30%, #1B1B1B 40%, #1B1B1B 50%, #1B1B1B 60%, #1B1B1B 70%, #FFFFFF 100%);
  border-radius: 50%;
  
  /*  Rotate  */
  animation-duration: 2s;
  animation-name: rotate;
  animation-iteration-count: infinite;
}

.middle-ring {
  position: absolute;
  left: calc(50% - 80px);
  height: 150px;
  width: 150px;
  background-color: #1B1B1B;
  border-radius: 50%;
}

.inner-ring {
  position: absolute;
  left: calc(50% - 75px);
  height: 140px;
  width: 140px;
  background-color: #1B1B1B;
  border-radius: 50%;
  overflow: hidden;
  .videoContainer{
    float: left;
    margin: -56px 0px 0px -97px;
    .sphere-image {
      height: 250px;
    }
  }
}

@keyframes rotate {
    0% {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
}
`;

// const Loader = () => {
//   return <LoaderStyled>
//     <div className="body-center">
//       <div className="outer-ring"></div>
//       <div className="middle-ring"></div>
//       <div className="inner-ring">
//         <div className="videoContainer">
//           <img className="sphere-image" src={sphere} alt="Loading"/>
//           {/* <video className='video' autoPlay loop muted>
//             <source src={sphere} type='video/mp4' />
//           </video> */}
//         </div>
//       </div>
//     </div>
//   </LoaderStyled>;
// }


const LoaderDots = (props) => {
  return ( 
      <Loader
        className="flex_center"
        type="ThreeDots"
        color="#051E34"
        height={100}
        width={100}
        timeout={100000} //100 secs
      />
  );
}

export default LoaderDots;