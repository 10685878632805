import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';

import firebaseConfig from "../shared/constants/firebase";

const {
  apiKey,
  authDomain,
  databaseURL,
  storageBucket,
  messagingSenderId,
  projectId,
} = firebaseConfig;

let app = null;

if (
  apiKey !== "null" &&
  authDomain !== "null" &&
  databaseURL !== "null" &&
  storageBucket !== "null" &&
  messagingSenderId !== "null" &&
  projectId !== "null"
) {
  firebase.initializeApp({
    apiKey,
    authDomain,
    databaseURL,
    storageBucket,
    messagingSenderId,
    projectId,
  });
  // app = initializeApp({
  //   apiKey,
  //   authDomain,
  //   databaseURL,
  //   storageBucket,
  //   messagingSenderId,
  //   projectId,
  // });
}
const auth = firebase.auth();
const realtimeDb = firebase.database();
const functions = firebase.functions();

export { 
  auth, 
  realtimeDb, 
  functions, 
  firebase
 };
